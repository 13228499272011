import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import { queryClient } from "../../../api/reactQueryClient";
import {
  ShiftHistoryForVetAPIResponse,
  SubmitHospitalRatingByVetAPIResponse,
} from "../../../api/shiftHistory";
import { CACHE } from "../../../api/utility/constants";
import * as errorMessage from "../../../constants/errorMessage";
import { UNSET_LOADER } from "../../../constants/loader";
import * as actionTypes from "../../../constants/vetConstants";
import { Event, EventTracker } from "../../../tracking/service/EventTracker";
import {
  FetchVetShiftHistoryDetailsAction,
  // eslint-disable-next-line sort-imports
  SubmitRateHospitalByVetAction,
  showReferralModal,
} from "../actions/vetShiftHistoryActions";

function* fetchVetShiftHistoryAsync(action: FetchVetShiftHistoryDetailsAction) {
  try {
    const { vetId, startDate, endDate, isFetchReferralBonus, shiftGroupId, userId } =
      action.payload;

    let urlParams = `?vetId=${vetId}&userId=${userId}&startDate=${startDate}&endDate=${endDate}&isFetchReferralBonus=${isFetchReferralBonus}&isFromWeb=${true}`;

    if (vetId && shiftGroupId) {
      // this will fetch only 1 shift
      urlParams = `?vetId=${vetId}&userId=${userId}&isFetchReferralBonus=${isFetchReferralBonus}&shiftGroupId=${shiftGroupId}&isFromWeb=${true}`;
    }

    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/vet/shift/getShiftsHistory" + urlParams
    );

    const data: ShiftHistoryForVetAPIResponse = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      if (data.data.data === undefined) {
        yield put({
          type: actionTypes.VET_SHIFT_HISTORY_SUCCESS,
          payload: {
            status: data.status_code,
            shiftHistory: [],
            totalCount: 0,
            totalShiftEarning: 0,
            totalReferralBonus: 0,
            totalBonus: 0,
            yearToDateEarning: 0,
            bonusLog: [],
            referralLog: [],
            shiftBonusLog: [],
            promoLog: [],
            totalOvertime: 0,
            totalEarnings: 0,
          },
        });
      } else {
        yield put({
          type: actionTypes.VET_SHIFT_HISTORY_SUCCESS,
          payload: {
            status: data.status_code,
            shiftHistory: data.data.data,
            totalCount: data.data.totalCount,
            totalShiftEarning: data.data.totalShiftEarning,
            totalReferralBonus: data.data.totalReferralBonus,
            totalBonus: data.data.totalBonus,
            yearToDateEarning: data.data.yearToDateEarning,
            isOvertime: shiftGroupId && data.data.data && data.data.data.length == 1,
            bonusLog: data.data.bonusLog,
            referralLog: data.data.referralLog,
            shiftBonusLog: data.data.shiftBonusLog,
            promoLog: data.data.promoLog,
            totalOvertime: data.data.totalOvertime,
            totalEarnings: data.data.totalEarnings,
          },
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.VET_SHIFT_HISTORY_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting vet shift list: " } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.VET_SHIFT_HISTORY_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* submitRateHospitalAsync(action: SubmitRateHospitalByVetAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/vet/updateRating",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data: SubmitHospitalRatingByVetAPIResponse = yield response.json();
    if (data.status_code === 200) {
      EventTracker.send(
        Event.Name.VET_REVIEW_MODAL,
        Event.Type.CLICK,
        Event.Entity.VET,
        action.payload.vetId,
        action.payload
      );
      yield put({
        type: actionTypes.SUBMIT_RATE_HOSPITAL_AS_VET_SUCCESS,
        payload: { status: data.status_code },
      });
      yield put({
        type: actionTypes.UPDATE_CURRENT_HOSPITAL_RATING_BY_VET_INDEX,
      });
      yield put({
        type: actionTypes.GET_PENDING_VET_RATINGS_REQUEST,
        payload: { isGetAllReviews: 1 },
      });
      yield put({ type: UNSET_LOADER });
      queryClient.invalidateQueries([CACHE.USER_PROMOS]);
      if (action.payload.blockHospital == 1) {
        // increase counter as hospital is blocked
        yield put({
          type: actionTypes.UPDATE_BLOCKED_HOSPITAL_COUNT,
          payload: {},
        });
      }
      if (data.data.isFirstShiftRated) {
        yield put(showReferralModal());
      }
      if (data.data.newBadge) {
        yield put({
          type: actionTypes.SHOW_VET_BADGE_CONGRATS_MODAL,
          payload: data.data.newBadge,
        });
        yield put({
          type: actionTypes.GET_VET_BADGES,
          payload: action.payload.vetId,
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.SUBMIT_RATE_HOSPITAL_AS_VET_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while submitting vet rating: " } });
    yield put({
      type: actionTypes.SUBMIT_RATE_HOSPITAL_AS_VET_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootVetShiftHistorySaga() {
  yield takeEvery(actionTypes.VET_SHIFT_HISTORY_REQUEST, fetchVetShiftHistoryAsync);
  yield takeEvery(actionTypes.SUBMIT_RATE_HOSPITAL_AS_VET, submitRateHospitalAsync);
}
