import React from "react";
import { InputProps, InputRef } from "antd";

import { ErrorText } from "../Text/ErrorText";
import { FormLabel } from "../FormLabel";
import { Icon } from "../Icon";
import { grayLight400 } from "../../styles/constants";

import { CustomInput, InputContainer } from "./AntInputStyles";

interface ExtendedInputProps extends InputProps {
  error?: string;
  isHideErrorText?: boolean;
  label?: string;
  disabled?: boolean;
  handleClearSearch?: (e?: any) => void;
}

export type AntInputProps = ExtendedInputProps & React.RefAttributes<InputRef>;

export const Input: React.ForwardRefExoticComponent<AntInputProps> = React.forwardRef(
  function InputComponent(props, ref) {
    const { isHideErrorText, name, id, error, label, disabled, allowClear, handleClearSearch } =
      props;

    const htmlFor = id ?? `input-${name}`;

    return (
      <InputContainer>
        <FormLabel htmlFor={htmlFor} disabled={disabled} error={!!error}>
          {label}
        </FormLabel>
        <CustomInput
          {...props}
          ref={ref}
          id={htmlFor}
          onWheel={(e) => e.currentTarget.blur()}
          status={error ? "error" : ""}
          allowClear={
            allowClear && {
              clearIcon: (
                <Icon
                  name="Close"
                  color={grayLight400}
                  onClick={(e) => handleClearSearch && handleClearSearch(e)}
                />
              ),
            }
          }
        />
        {error && !isHideErrorText && <ErrorText>{error}</ErrorText>}
      </InputContainer>
    );
  }
);
