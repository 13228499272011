import _ from "lodash";
import React, { Context, FC, createContext, useContext, useEffect, useMemo, useState } from "react";
import { CustomToastProps, Toast } from "@RooUI";

import { UnsavedChangesModal } from "../../Admin/Users/components/UnsavedChanges/UnsavedChangesModal";

import { DEFAULT_ERROR_MESSAGE, SUCCESS_MESSAGE } from "./constants";

interface FormContext<T> {
  form?: T;
  onChange?: <K extends keyof T>(name: K, value: T[K]) => void;
  onSave?: () => void;
  onCancel?: () => void;
  onValidate?: () => boolean;
  loading?: boolean;
  errors?: Partial<Record<keyof T, string>>;
}

interface FromProviderPropTypes<T> {
  children: React.ReactNode;
  defaultForm: T;
  save: (
    form: T,
    options: { setErrors?: React.Dispatch<React.SetStateAction<FormContext<T>["errors"]>> }
  ) => Promise<any>;
  validate: (form: T) => Partial<Record<keyof T, string>> | undefined;
  cancel?: () => void;
  unsavedCheck?: boolean;
}

export type FormSaveFn<T> = FromProviderPropTypes<T>["save"];

export function createFormContext<T>(
  initial?: FormContext<T>
): Context<FormContext<T> | undefined> {
  return createContext<FormContext<T> | undefined>(initial);
}

export function createForm<T>(): {
  useFormContext: () => FormContext<T>;
  FormProvider: React.FC<FromProviderPropTypes<T>>;
} {
  const FormContext = createFormContext<T>();

  const FormProvider: FC<FromProviderPropTypes<T>> = ({
    children,
    defaultForm,
    save,
    validate,
    cancel,
    unsavedCheck,
  }) => {
    const [form, setForm] = useState<T>(null);
    const [message, setMessage] = useState<CustomToastProps | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [errors, setErrors] = useState<FormContext<T>["errors"]>({});
    useEffect(() => {
      setForm(defaultForm);
    }, [defaultForm]);
    const onChange: FormContext<T>["onChange"] = (name, value) => {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));

      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });

      setIsDirty(true);
    };

    const getErrorMessage = (error: any) => {
      if (error?.message) {
        return {
          ...DEFAULT_ERROR_MESSAGE,
          description: error.message,
        };
      }
      return DEFAULT_ERROR_MESSAGE;
    };

    const onSave = () => {
      setLoading(true);
      if (!onValidate()) {
        setLoading(false);
        return;
      }
      save(form, { setErrors })
        .then(() => {
          setMessage(SUCCESS_MESSAGE);
          setIsDirty(false);
          setLoading(false);
        })
        .catch((error) => {
          setMessage(getErrorMessage(error));
          setLoading(false);
        });
    };

    const onValidate = () => {
      const errors = validate(form);
      if (errors && !_.isEmpty(errors)) {
        setErrors(errors);
        return false;
      }
      return true;
    };

    const onCancel = () => {
      setForm(defaultForm);
      setErrors({});
      if (cancel) {
        cancel();
      }
    };

    const value = useMemo(
      () => ({
        form,
        errors,
        onChange,
        onSave,
        onCancel,
        onValidate,
        loading,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [form, errors, loading]
    );
    return (
      <FormContext.Provider value={value}>
        {form && children}
        {message && <Toast {...message} onHide={() => setMessage(null)} />}
        {unsavedCheck && <UnsavedChangesModal when={isDirty} onSaveAllChanges={onSave} />}
      </FormContext.Provider>
    );
  };

  const useFormContext = () => useContext(FormContext);

  return { useFormContext, FormProvider };
}
