import styled from "styled-components";

import {
  ButtonText,
  blueBase,
  blueDark600Shadow,
  buttonBorderRadius,
  grayWhite,
  purpleBase,
  purpleDark600,
  purpleDark700,
  purpleLighter,
  redBase,
  redDark600,
  redDark700,
  redLighter,
} from "../../styles/constants";

import { ButtonProps } from ".";

const getButtonPadding = (size: string | undefined) => {
  let padding;
  if (size === "small") padding = "2px 32px";
  else padding = "8px 32px";
  return padding;
};

const getButtonHeight = (size: string | undefined) => {
  let height;
  if (size === "small") height = "29px";
  else height = "39px;";
  return height;
};

const getColors = (buttonType?: string) => {
  switch (buttonType) {
    case "secondary":
      return {
        baseBgColor: grayWhite,
      };
    case "danger":
      return {
        baseBgColor: redBase,
        hoverBgColor: redDark600,
        activeBgColor: redDark700,
        disabledBgColor: redLighter,
      };
    default:
      return {
        baseBgColor: purpleBase,
        hoverBgColor: purpleDark600,
        activeBgColor: purpleDark700,
        disabledBgColor: purpleLighter,
      };
  }
};

export const Button = styled.button<ButtonProps>`
  ${ButtonText}
  box-shadow: 0px 2px 4px ${blueDark600Shadow};
  border-radius: ${buttonBorderRadius};
  gap: 8px;
  line-height: 24px;
  text-transform: capitalize;
  z-index: 1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${(props) => `${getButtonHeight(props.size)}`};
  padding: ${(props) => `${getButtonPadding(props.size)}`};

  background: ${({ buttonType }) => getColors(buttonType).baseBgColor};
  border-color: ${({ buttonType }) => `${buttonType === "danger" ? redBase : purpleBase}`};
  border-style: solid;
  border-width: ${(props) => `${props.buttonType === "secondary" ? "2px" : "0px"}`};
  color: ${(props) => `${props.buttonType === "secondary" ? purpleBase : grayWhite}`};
  width: max-content;

  &:hover {
    background: ${({ buttonType }) => getColors(buttonType).hoverBgColor || ""};
    border-color: ${({ buttonType }) => `${buttonType === "danger" ? redDark600 : purpleDark600}`};
    box-shadow: none;
    color: ${(props) => `${props.buttonType === "secondary" ? purpleDark600 : ""}`};
  }
  &:active {
    background: ${({ buttonType }) => getColors(buttonType).activeBgColor || ""};
    border-color: ${({ buttonType }) => `${buttonType === "danger" ? redDark700 : purpleDark700}`};
    box-shadow: none;
    color: ${(props) => `${props.buttonType === "secondary" ? purpleDark700 : ""}`};
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    background: ${({ buttonType }) => getColors(buttonType).baseBgColor};
    border-color: ${(props) => `${props.buttonType === "danger" ? redBase : blueBase}`};
    border-width: 4px;
    color: ${(props) => `${props.buttonType === "secondary" ? purpleBase : ""}`};
    outline: none;
  }
  &:disabled {
    background: ${({ buttonType }) => getColors(buttonType).disabledBgColor || ""};
    border-color: ${(props) => `${props.buttonType === "danger" ? redLighter : purpleLighter}`};
    box-shadow: none;
    color: ${(props) => `${props.buttonType === "secondary" ? purpleLighter : ""}`};
    cursor: not-allowed;
    mix-blend-mode: normal;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
