import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { showToast } from "../../Common/Toasts/action/ToastActions";
import { rooFetch } from "../../api/common/rooFetch";
import * as adminUserActionTypes from "../../constants/adminUsersList";
import * as actionTypes from "../../constants/enterpriseConstants";
import * as errorMessage from "../../constants/errorMessage";
import * as loaderActions from "../../constants/loader";

function* getMyHospitalGroupsAsync(action) {
  try {
    yield put({ type: loaderActions.SET_LOADER });
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/getMyHospitalGroups`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    const { hospitalGroups } = data.data;

    if (data.status_code === 200) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: actionTypes.GET_MY_HOSPITAL_GROUPS_LIST_SUCCESS,
        payload: { hospitalGroups },
      });
    } else if (data.status_code === 400) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: actionTypes.GET_MY_HOSPITAL_GROUPS_LIST_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting my hospital groups" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.GET_MY_HOSPITAL_GROUPS_LIST_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getGroupManageUsersAsync(action) {
  try {
    const { isSearchClicked } = action.payload;
    delete action.payload.isSearchClicked;

    yield put({ type: loaderActions.SET_LOADER });
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/groupManageUsers",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    const { groupAdmins, groupHospitalUsers } = data.data;

    if (data.status_code === 200) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: actionTypes.GET_GROUP_MANAGE_USERS_SUCCESS,
        payload: {
          groupAdmins,
          groupHospitalUsers,
          isSearchClicked,
          ...action.payload,
        },
      });
    } else if (data.status_code === 400) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: actionTypes.GET_GROUP_MANAGE_USERS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting group manage users" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.GET_GROUP_MANAGE_USERS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* saveEnterpriseHospitalsAsync(action) {
  try {
    const { isSelfIdentifiedEnterprise } = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/users/saveEnterpriseHospital",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      const { errorMessage } = data.data;
      if (errorMessage) {
        yield put({
          type: actionTypes.SAVE_ENTERPRISE_HOSPITAL_FAIL,
          payload: errorMessage,
        });
      } else {
        if (!isSelfIdentifiedEnterprise) {
          yield put({
            type: actionTypes.GET_ENTERPRISE_DETAILS,
            payload: { selectedEnterpriseId: action.payload.enterpriseId },
          });
        }
        yield put({
          type: actionTypes.SAVE_ENTERPRISE_HOSPITAL_SUCCESS,
        });
      }
    } else {
      yield put({
        type: actionTypes.SAVE_ENTERPRISE_HOSPITAL_FAIL,
        payload: "Failed to saveEnterpriseHospital.",
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while saving enterprise hospital" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.SAVE_ENTERPRISE_HOSPITAL_FAIL,
      payload: "Failed to saveEnterpriseHospital.",
    });
  }
}

export function* saveHospitalGroupAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/users/saveHospitalGroup",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });

    if (data.status_code === 200) {
      const { errorMessage } = data.data;
      if (errorMessage) {
        yield put({
          type: actionTypes.SAVE_HOSPITAL_GROUP_FAIL,
          payload: errorMessage,
        });
      } else {
        yield put({
          type: actionTypes.GET_ENTERPRISE_DETAILS,
          payload: { selectedEnterpriseId: action.payload.enterpriseId },
        });
        yield put({
          type: actionTypes.SAVE_HOSPITAL_GROUP_SUCCESS,
        });
      }
    } else {
      yield put({
        type: actionTypes.SAVE_HOSPITAL_GROUP_FAIL,
        payload: { errorMessage: "Failed to saveHospitalGroup." },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while saving hospital group" } });
    yield put({
      type: actionTypes.SAVE_HOSPITAL_GROUP_FAIL,
      payload: { errorMessage: "Failed to saveHospitalGroup." },
    });
  }
}

export function* saveGroupHospitalsAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/users/saveGroupHospitals",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    const data = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      const { errorMessage } = data.data;
      if (errorMessage) {
        yield put({
          type: actionTypes.SAVE_GROUP_HOSPITALS_FAIL,
          payload: errorMessage,
        });
      } else {
        yield put({ type: adminUserActionTypes.GET_HOSPITAL_LIST });
        yield put({
          type: actionTypes.GET_ENTERPRISE_DETAILS,
          payload: { selectedEnterpriseId: action.payload.enterpriseId },
        });
        yield put({
          type: actionTypes.SAVE_GROUP_HOSPITALS_SUCCESS,
          payload: action.payload,
        });
      }
    } else {
      yield put({
        type: actionTypes.SAVE_GROUP_HOSPITALS_FAIL,
        payload: "Failed to saveGroupHospitals.",
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while saving group hospitals" } });
    yield put({
      type: actionTypes.SAVE_GROUP_HOSPITALS_FAIL,
      payload: "Failed to saveGroupHospitals.",
    });
  }
}

export function* saveGroupAdminAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/saveGroupAdmin",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      const { errorMessage } = data.data;
      if (errorMessage) {
        yield put({
          type: actionTypes.SAVE_GROUP_ADMIN_FAIL,
          payload: errorMessage,
        });
      } else {
        yield put({
          type: actionTypes.GET_ENTERPRISE_DETAILS,
          payload: { selectedEnterpriseId: action.payload.enterpriseId },
        });
        yield put({
          type: actionTypes.SAVE_GROUP_ADMIN_SUCCESS,
          payload: action.payload,
        });
      }
    } else {
      yield put({
        type: actionTypes.SAVE_GROUP_ADMIN_FAIL,
        payload: "Failed to saveGroupAdmin.",
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while saving group admin" } });
    yield put({
      type: actionTypes.SAVE_GROUP_ADMIN_FAIL,
      payload: "Failed to saveGroupAdmin.",
    });
  }
}

function* updateHospitalInvoicedStatusAsync(action) {
  try {
    yield put({ type: loaderActions.SET_LOADER });
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/updateHospitalInvoicedStatus`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      // yield put({ type: loaderActions.UNSET_LOADER }); // don't unset, keep loader while content is being fetched again
      yield put({ type: adminUserActionTypes.GET_HOSPITAL_LIST });
      yield put({
        type: actionTypes.GET_ENTERPRISE_DETAILS,
        payload: { selectedEnterpriseId: action.payload.enterpriseId },
      });
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_ENTERPRISE_STATUS_SUCCESS,
      });
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_INVOICED_STATUS_SUCCESS,
      });
    } else if (data.status_code === 400) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_INVOICED_STATUS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating hospital invoiced status" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.UPDATE_HOSPITAL_INVOICED_STATUS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateHospitalEnterpriseStatusAsync(action) {
  try {
    yield put({ type: loaderActions.SET_LOADER });
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/updateHospitalEnterpriseStatus`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      // yield put({ type: loaderActions.UNSET_LOADER }); // don't unset, keep loader while content is being fetched again
      yield put({ type: adminUserActionTypes.GET_HOSPITAL_LIST });
      yield put({
        type: actionTypes.GET_ENTERPRISE_DETAILS,
        payload: { selectedEnterpriseId: action.payload.enterpriseId },
      });
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_ENTERPRISE_STATUS_SUCCESS,
      });
    } else if (data.status_code === 400) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_ENTERPRISE_STATUS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating hospital enterprise status" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.UPDATE_HOSPITAL_ENTERPRISE_STATUS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* deleteHospitalGroupAsync(action) {
  try {
    yield put({ type: loaderActions.SET_LOADER });
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/deleteHospitalGroup`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      // yield put({ type: loaderActions.UNSET_LOADER }); // don't unset, keep loader while content is being fetched again
      yield put({
        type: actionTypes.GET_ENTERPRISE_DETAILS,
        payload: { selectedEnterpriseId: action.payload.enterpriseId },
      });
      yield put({
        type: actionTypes.DELETE_HOSPITAL_GROUP_SUCCESS,
      });
    } else if (data.status_code === 400) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: actionTypes.DELETE_HOSPITAL_GROUP_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting hospital group" } });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.DELETE_HOSPITAL_GROUP_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateHospitalGroupName(action) {
  try {
    yield put({ type: loaderActions.SET_LOADER });
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/updateHospitalGroupName`,
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: actionTypes.GET_ENTERPRISE_DETAILS,
        payload: { selectedEnterpriseId: action.payload.enterpriseId },
      });
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_GROUP_NAME_SUCCESS,
      });
    } else if (data.status_code === 400) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_GROUP_NAME_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating hospital group name" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.UPDATE_HOSPITAL_GROUP_NAME_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* deleteGroupHospitalAsync(action) {
  try {
    yield put({ type: loaderActions.SET_LOADER });
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/deleteGroupHospital`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      // yield put({ type: loaderActions.UNSET_LOADER }); // don't unset, keep loader while content is being fetched again
      yield put({
        type: actionTypes.GET_ENTERPRISE_DETAILS,
        payload: { selectedEnterpriseId: action.payload.enterpriseId },
      });
      yield put({
        type: actionTypes.DELETE_GROUP_HOSPITAL_SUCCESS,
      });
    } else if (data.status_code === 400) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: actionTypes.DELETE_GROUP_HOSPITAL_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting group hospital" } });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.DELETE_GROUP_HOSPITAL_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* deleteGroupAdminAsync(action) {
  try {
    yield put({ type: loaderActions.SET_LOADER });
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/deleteGroupAdmin`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      // yield put({ type: loaderActions.UNSET_LOADER }); // don't unset, keep loader while content is being fetched again
      yield put({
        type: actionTypes.GET_ENTERPRISE_DETAILS,
        payload: { selectedEnterpriseId: action.payload.enterpriseId },
      });
      yield put({
        type: actionTypes.DELETE_GROUP_ADMIN_SUCCESS,
      });
    } else if (data.status_code === 400) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: actionTypes.DELETE_GROUP_ADMIN_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting group admin" } });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.DELETE_GROUP_ADMIN_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* deleteEnterpriseHospitalAsync(action) {
  try {
    yield put({ type: loaderActions.SET_LOADER });
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/deleteEnterpriseHospital`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      // yield put({ type: loaderActions.UNSET_LOADER }); // don't unset, keep loader while content is being fetched again
      yield put({
        type: actionTypes.GET_ENTERPRISE_DETAILS,
        payload: { selectedEnterpriseId: action.payload.enterpriseId },
      });
      yield put({
        type: actionTypes.DELETE_ENTERPRISE_HOSPITAL_SUCCESS,
      });
    } else if (data.status_code === 400) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: actionTypes.DELETE_ENTERPRISE_HOSPITAL_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while deleting enterprise hospital" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.DELETE_ENTERPRISE_HOSPITAL_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getVetAnalyticsDataAsync(action) {
  try {
    const { startDate, endDate, hospitalIds } = action.payload;
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/getVetAnalytics`,
      {
        method: "POST",
        body: JSON.stringify({ startDate, endDate, hospitalIds }),
      }
    );

    const responseData = yield response.json();

    yield put({ type: loaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.GET_VET_ANALYTICS_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_VET_ANALYTICS_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting vet analytics data" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.GET_VET_ANALYTICS_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

function* updateEnterpriseInvoicedCustomerAsync(action) {
  try {
    const { enterpriseId, enterpriseName } = action.payload;
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/updateEnterpriseInvoicedCustomer`,
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    yield put({ type: loaderActions.UNSET_LOADER });

    if (response.ok) {
      yield put({
        type: actionTypes.UPDATE_ENTERPRISE_NAME,
        payload: { enterpriseId, enterpriseName },
      });
      yield put({
        type: actionTypes.UPDATE_ENTERPRISE_INVOICED_CUSTOMER_SUCCESS,
      });
    } else {
      const responseData = yield response.json();
      yield put({
        type: actionTypes.UPDATE_ENTERPRISE_INVOICED_CUSTOMER_FAIL,
        payload: { status: response.status, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating enterprise invoiced customer" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.UPDATE_ENTERPRISE_INVOICED_CUSTOMER_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

function* getEnterpriseInvoicedCustomerAsync(action) {
  try {
    const { enterpriseId, invoicedCustomerId } = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/enterpriseInvoicedCustomer/${invoicedCustomerId}`
    );

    const responseData = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.GET_ENTERPRISE_INVOICED_CUSTOMER_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_ENTERPRISE_INVOICED_CUSTOMER_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting enterprise invoiced customer" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.GET_ENTERPRISE_INVOICED_CUSTOMER_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

function* calculateHospitalRatings(action) {
  try {
    const { startDate, endDate, hospitalIds } = action.payload;
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/calculateHospitalRatings`,
      {
        method: "POST",
        body: JSON.stringify({ startDate, endDate, hospitalIds }),
      }
    );

    const responseData = yield response.json();

    yield put({ type: loaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.CALCULATE_HOSPITAL_RATINGS_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.CALCULATE_HOSPITAL_RATINGS_FAIL,
        payload: { status: responseData.status_code, message: errorMessage.EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while calculating hospital ratings" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.CALCULATE_HOSPITAL_RATINGS_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

function* getBlockedContractorsEnterpriseAsync(action) {
  try {
    const enterpriseId = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/blockedContractors/${enterpriseId}`
    );

    const responseData = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.GET_BLOCKED_CONTRACTORS_ENTERPRISE_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_BLOCKED_CONTRACTORS_ENTERPRISE_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting enterprise blocked contractors" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.GET_BLOCKED_CONTRACTORS_ENTERPRISE_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

function* unblockContractorEnterpriseAsync(action) {
  try {
    const { blockedContractorId, enterpriseId } = action.payload;
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/unblockContractor`,
      {
        method: "POST",
        body: JSON.stringify({ blockedContractorId }),
      }
    );

    const responseData = yield response.json();

    yield put({ type: loaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.UNBLOCK_CONTRACTOR_ENTERPRISE_SUCCESS,
        payload: responseData.data,
      });
      yield put(
        showToast({
          variant: "success",
          message: "Success!",
          description: "Unblocked Contractor successfully.",
        })
      );
      yield put({
        type: actionTypes.GET_BLOCKED_CONTRACTORS_ENTERPRISE,
        payload: enterpriseId,
      });
    } else {
      yield put({
        type: actionTypes.UNBLOCK_CONTRACTOR_ENTERPRISE_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
      yield put(
        showToast({
          variant: "error",
          message: "Error!",
          description: "Unblocked Contractor failed.",
        })
      );
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while unblocking contractor for enterprise" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.UNBLOCK_CONTRACTOR_ENTERPRISE_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
    yield put(
      showToast({
        variant: "error",
        message: "Error!",
        description: error.message,
      })
    );
  }
}

function* blockContractorEnterpriseAsync(action) {
  try {
    const { enterpriseId, vetId, techId, blockReason, hospitalGroupId, userId } = action.payload;
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/blockContractor`,
      {
        method: "POST",
        body: JSON.stringify({ enterpriseId, vetId, techId, blockReason, hospitalGroupId, userId }),
      }
    );

    const responseData = yield response.json();

    yield put({ type: loaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.BLOCK_CONTRACTOR_ENTERPRISE_SUCCESS,
        payload: responseData.data,
      });
      yield put(
        showToast({
          variant: "success",
          message: "Success!",
          description: "Blocked Contractor successfully.",
        })
      );
      yield put({
        type: actionTypes.GET_BLOCKED_CONTRACTORS_ENTERPRISE,
        payload: enterpriseId,
      });
    } else {
      yield put({
        type: actionTypes.BLOCK_CONTRACTOR_ENTERPRISE_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
      yield put(
        showToast({
          variant: "error",
          message: "Error!",
          description: "Blocked Contractor failed.",
        })
      );
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while blocking contractor for enterprise" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: actionTypes.BLOCK_CONTRACTOR_ENTERPRISE_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
    yield put(
      showToast({
        variant: "error",
        message: "Error!",
        description: error.message,
      })
    );
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootEnterpriseSaga() {
  yield takeEvery(actionTypes.GET_MY_HOSPITAL_GROUPS_LIST, getMyHospitalGroupsAsync);
  yield takeEvery(actionTypes.GET_GROUP_MANAGE_USERS, getGroupManageUsersAsync);
  yield takeEvery(
    actionTypes.UPDATE_HOSPITAL_ENTERPRISE_STATUS,
    updateHospitalEnterpriseStatusAsync
  );
  yield takeEvery(actionTypes.UPDATE_HOSPITAL_INVOICED_STATUS, updateHospitalInvoicedStatusAsync);

  yield takeEvery(actionTypes.SAVE_ENTERPRISE_HOSPITAL, saveEnterpriseHospitalsAsync);
  yield takeEvery(actionTypes.SAVE_HOSPITAL_GROUP, saveHospitalGroupAsync);
  yield takeEvery(actionTypes.SAVE_GROUP_HOSPITALS, saveGroupHospitalsAsync);
  yield takeEvery(actionTypes.SAVE_GROUP_ADMIN, saveGroupAdminAsync);

  yield takeEvery(actionTypes.DELETE_HOSPITAL_GROUP, deleteHospitalGroupAsync);
  yield takeEvery(actionTypes.UPDATE_HOSPITAL_GROUP_NAME, updateHospitalGroupName);
  yield takeEvery(actionTypes.DELETE_GROUP_HOSPITAL, deleteGroupHospitalAsync);
  yield takeEvery(actionTypes.DELETE_GROUP_ADMIN, deleteGroupAdminAsync);
  yield takeEvery(actionTypes.DELETE_ENTERPRISE_HOSPITAL, deleteEnterpriseHospitalAsync);
  yield takeEvery(actionTypes.GET_VET_ANALYTICS, getVetAnalyticsDataAsync);
  yield takeEvery(actionTypes.CALCULATE_HOSPITAL_RATINGS, calculateHospitalRatings);
  yield takeEvery(actionTypes.GET_ENTERPRISE_INVOICED_CUSTOMER, getEnterpriseInvoicedCustomerAsync);
  yield takeEvery(
    actionTypes.UPDATE_ENTERPRISE_INVOICED_CUSTOMER,
    updateEnterpriseInvoicedCustomerAsync
  );
  yield takeEvery(
    actionTypes.GET_BLOCKED_CONTRACTORS_ENTERPRISE,
    getBlockedContractorsEnterpriseAsync
  );
  yield takeEvery(actionTypes.UNBLOCK_CONTRACTOR_ENTERPRISE, unblockContractorEnterpriseAsync);
  yield takeEvery(actionTypes.BLOCK_CONTRACTOR_ENTERPRISE, blockContractorEnterpriseAsync);
}
