import style from "styled-components";

import { SmallBodyText, grayBase } from "../../styles/constants";

interface ChipDivProps {
  borderColor?: string;
  backgroundColor?: string;
  textColor?: string;
}

export const ChipStyle = style.div<ChipDivProps>`
  display: inline-flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${(props) => `${props.backgroundColor ? props.backgroundColor : "unset"}`};
  border: ${(props) =>
    `1px solid ${props.borderColor ? props.borderColor : props.backgroundColor}`};
  span {
    ${SmallBodyText};
    text-align: center;
    color: ${(props) => {
      if (props.textColor) {
        return props.textColor;
      }
      if (props.borderColor) {
        return props.borderColor;
      }
      return grayBase;
    }};
  }
`;
