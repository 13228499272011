import React, { useState } from "react";

import { TabPanel } from "../TabPanel";

import { ButtonTabContainer, Overlay, TabAndPanelContainer } from "./ButtonTabsContainerStyles";
import { TabButton } from "./TabButton";

export interface ButtonTabContainerProps {
  tabNameArr: any;
  defaultSelectedTabIndex: number;
  overrideSelectedTabIndex?: number;
  onClickTab?: (e: any, selectedTabIndex: number) => void;
  children?: React.ReactNode;
}

interface TabNameObj {
  index: number;
  name: string;
  panelContent?: React.ReactNode;
  testId?: string;
}

export const ButtonTabs: React.FC<ButtonTabContainerProps> = (props) => {
  const { tabNameArr, defaultSelectedTabIndex, onClickTab, overrideSelectedTabIndex } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultSelectedTabIndex);
  const tabIndex = overrideSelectedTabIndex ?? selectedTabIndex;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isMobileViewport = window.innerWidth < 768;

  return (
    <TabAndPanelContainer>
      {isMobileMenuOpen && <Overlay />}
      <ButtonTabContainer {...props}>
        <>
          <TabButton
            isSelected={true}
            onClick={(e: any) => {
              setIsMobileMenuOpen(!isMobileMenuOpen);
              if (onClickTab) onClickTab(e, tabIndex);
            }}
            index={tabIndex}
            isMobileHeaderTab
            isMobileMenuOpen={isMobileMenuOpen}
          >
            {tabNameArr[tabIndex].name}
          </TabButton>
          {tabNameArr?.map((tabNameObj: TabNameObj) => {
            const { index, name, testId } = tabNameObj;
            return (
              <TabButton
                key={index}
                isSelected={index === tabIndex}
                onClick={(e: any) => {
                  setSelectedTabIndex(index);
                  if (isMobileViewport) setIsMobileMenuOpen(!isMobileMenuOpen);
                  if (onClickTab) onClickTab(e, index);
                }}
                index={index}
                isMobileMenuOpen={isMobileMenuOpen}
                data-testid={testId}
              >
                {name}
              </TabButton>
            );
          })}
        </>
      </ButtonTabContainer>
      {props.children}
      {tabNameArr?.map((tabNameObj: TabNameObj) => {
        const { index, panelContent } = tabNameObj;
        return (
          <TabPanel key={index} isSelected={index === tabIndex} isMobileMenuOpen={isMobileMenuOpen}>
            {panelContent}
          </TabPanel>
        );
      })}
    </TabAndPanelContainer>
  );
};
