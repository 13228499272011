export const GET_SHIFT_LIST = "GET_SHIFT_LIST";
export const GET_SHIFT_LIST_SUCCESS = "GET_SHIFT_LIST_SUCCESS";
export const GET_SHIFT_LIST_FAIL = "GET_SHIFT_LIST_FAIL";

export const GET_SHIFT_LOGS = "GET_SHIFT_LOGS";
export const GET_SHIFT_LOGS_SUCCESS = "GET_SHIFT_LOGS_SUCCESS";
export const GET_SHIFT_LOGS_FAIL = "GET_SHIFT_LOGS_FAIL";

export const GET_CALLOUT_LIST = "GET_CALLOUT_LIST";
export const GET_CALLOUT_LIST_SUCCESS = "GET_CALLOUT_LIST_SUCCESS";
export const GET_CALLOUT_LIST_FAIL = "GET_CALLOUT_LIST_FAIL";

export const GET_ADMIN_RATINGS = "GET_ADMIN_RATINGS";
export const GET_ADMIN_RATINGS_SUCCESS = "GET_ADMIN_RATINGS_SUCCESS";
export const GET_ADMIN_RATINGS_FAIL = "GET_ADMIN_RATINGS_FAIL";

export const INIT_ADMIN_RATINGS = "INIT_ADMIN_RATINGS";

export const ADMIN_UPDATE_RATINGS = "ADMIN_UPDATE_RATINGS";
export const ADMIN_UPDATE_RATINGS_SUCCESS = "ADMIN_UPDATE_RATINGS_SUCCESS";
export const ADMIN_UPDATE_RATINGS_FAIL = "ADMIN_UPDATE_RATINGS_FAIL";

export const ADMIN_EDIT_SHIFT = "ADMIN_EDIT_SHIFT";
export const ADMIN_EDIT_SHIFT_FAIL = "ADMIN_EDIT_SHIFT_FAIL";
export const ADMIN_EDIT_SHIFT_SUCCESS = "ADMIN_EDIT_SHIFT_SUCCESS";

export const ADMIN_ADD_SHIFT = "ADMIN_ADD_SHIFT";
export const ADMIN_ADD_SHIFT_FAIL = "ADMIN_ADD_SHIFT_FAIL";
export const ADMIN_ADD_SHIFT_SUCCESS = "ADMIN_ADD_SHIFT_SUCCESS";

export const ADMIN_EDIT_SHIFT_PRICE = "ADMIN_EDIT_SHIFT_PRICE";
export const ADMIN_EDIT_SHIFT_PRICE_FAIL = "ADMIN_EDIT_SHIFT_PRICE_FAIL";
export const ADMIN_EDIT_SHIFT_PRICE_SUCCESS = "ADMIN_EDIT_SHIFT_PRICE_SUCCESS";

export const ADMIN_GET_EXPORT_DATA_REQUEST = "ADMIN_GET_EXPORT_DATA_REQUEST";
export const ADMIN_GET_EXPORT_DATA_REQUEST_FAIL = "ADMIN_GET_EXPORT_DATA_REQUEST_FAIL";
export const ADMIN_GET_EXPORT_DATA_REQUEST_SUCCESS = "ADMIN_GET_EXPORT_DATA_REQUEST_SUCCESS";

export const PAY_TO_CONTRACTOR_REQUEST = "PAY_TO_CONTRACTOR_REQUEST";
export const PAY_TO_VET_FAIL = "PAY_TO_VET_FAIL";
export const PAY_TO_VET_SUCCESS = "PAY_TO_VET_SUCCESS";

export const ADMIN_TIMEZONE = "CST";

export const CANCEL_PAYMENT_REQUEST = "CANCEL_PAYMENT_REQUEST";
export const CANCEL_PAYMENT_FAIL = "CANCEL_PAYMENT_FAIL";
export const CANCEL_PAYMENT_SUCCESS = "CANCEL_PAYMENT_SUCCESS";

export const ADMIN_REMOVE_SHIFT_REQUEST = "ADMIN_REMOVE_SHIFT_REQUEST";
export const ADMIN_REMOVE_SHIFT_REQUEST_FAIL = "ADMIN_REMOVE_SHIFT_REQUEST_FAIL";
export const ADMIN_REMOVE_SHIFT_REQUEST_SUCCESS = "ADMIN_REMOVE_SHIFT_REQUEST_SUCCESS";

export const ADMIN_CONFIRM_SHIFT_REQUEST = "ADMIN_CONFIRM_SHIFT_REQUEST";
export const ADMIN_CONFIRM_SHIFT_REQUEST_FAIL = "ADMIN_CONFIRM_SHIFT_REQUEST_FAIL";
export const ADMIN_CONFIRM_SHIFT_REQUEST_SUCCESS = "ADMIN_CONFIRM_SHIFT_REQUEST_SUCCESS";

export const ADMIN_GET_ALL_VETS_REQUEST = "ADMIN_GET_ALL_VETS_REQUEST";
export const ADMIN_GET_ALL_VETS_FAIL = "ADMIN_GET_ALL_VETS_FAIL";
export const ADMIN_GET_ALL_VETS_SUCCESS = "ADMIN_GET_ALL_VETS_SUCCESS";

export const SET_LAST_SHIFT_ACTION_DATA = "SET_LAST_SHIFT_ACTION_DATA";
export const RESET_LAST_SHIFT_ACTION_DATA = "RESET_LAST_SHIFT_ACTION_DATA";

export const ADMIN_REQUEST_SHIFT_REQUEST = "ADMIN_REQUEST_SHIFT_REQUEST";
export const ADMIN_REQUEST_SHIFT_FAIL = "ADMIN_REQUEST_SHIFT_FAIL";
export const ADMIN_REQUEST_SHIFT_SUCCESS = "ADMIN_REQUEST_SHIFT_SUCCESS";

export const ADMIN_GET_ALL_TECHS_REQUEST = "ADMIN_GET_ALL_TECHS_REQUEST";
export const ADMIN_GET_ALL_TECHS_FAIL = "ADMIN_GET_ALL_TECHS_FAIL";
export const ADMIN_GET_ALL_TECHS_SUCCESS = "ADMIN_GET_ALL_TECHS_SUCCESS";

export const ADMIN_REQUEST_TECH_SHIFT_REQUEST = "ADMIN_REQUEST_TECH_SHIFT_REQUEST";
export const ADMIN_REQUEST_TECH_SHIFT_FAIL = "ADMIN_REQUEST_TECH_SHIFT_FAIL";
export const ADMIN_REQUEST_TECH_SHIFT_SUCCESS = "ADMIN_REQUEST_TECH_SHIFT_SUCCESS";

export const ADMIN_REQUEST_TECH_SHIFT_OVERLAPS = "ADMIN_REQUEST_TECH_SHIFT_OVERLAPS";

export const ADMIN_VET_ADDITIONAL_PAYOUT_REQUEST = "ADMIN_VET_ADDITIONAL_PAYOUT_REQUEST ";
export const ADMIN_VET_ADDITIONAL_PAYOUT_SUCCESS = "ADMIN_VET_ADDITIONAL_PAYOUT_SUCCESS";
export const ADMIN_VET_ADDITIONAL_PAYOUT_FAIL = "ADMIN_VET_ADDITIONAL_PAYOUT_FAIL";

export const ADMIN_TECH_ADDITIONAL_PAYOUT_REQUEST = "ADMIN_TECH_ADDITIONAL_PAYOUT_REQUEST ";
export const ADMIN_TECH_ADDITIONAL_PAYOUT_SUCCESS = "ADMIN_TECH_ADDITIONAL_PAYOUT_SUCCESS";
export const ADMIN_TECH_ADDITIONAL_PAYOUT_FAIL = "ADMIN_TECH_ADDITIONAL_PAYOUT_FAIL";

export const ADMIN_REQUEST_AND_CONFIRM_SHIFT_REQUEST = "ADMIN_REQUEST_AND_CONFIRM_SHIFT_REQUEST";
export const ADMIN_REQUEST_AND_CONFIRM_SHIFT_FAIL = "ADMIN_REQUEST_AND_CONFIRM_SHIFT_FAIL";
export const ADMIN_REQUEST_AND_CONFIRM_SHIFT_SUCCESS = "ADMIN_REQUEST_AND_CONFIRM_SHIFT_SUCCESS";

export const ADMIN_EDIT_TECH_SHIFT = "ADMIN_EDIT_TECH_SHIFT";
export const ADMIN_EDIT_TECH_SHIFT_FAIL = "ADMIN_EDIT_TECH_SHIFT_FAIL";
export const ADMIN_EDIT_TECH_SHIFT_SUCCESS = "ADMIN_EDIT_TECH_SHIFT_SUCCESS";

export const ADMIN_ADD_TECH_SHIFT = "ADMIN_ADD_TECH_SHIFT";
export const ADMIN_ADD_TECH_SHIFT_FAIL = "ADMIN_ADD_TECH_SHIFT_FAIL";
export const ADMIN_ADD_TECH_SHIFT_SUCCESS = "ADMIN_ADD_TECH_SHIFT_SUCCESS";

export const ADMIN_OVERTIME_ALREADY_EXISTS = "ADMIN_OVERTIME_ALREADY_EXISTS";

export const GET_SHIFT_BILLING_INFO = "GET_SHIFT_BILLING_INFO";
export const GET_SHIFT_BILLING_INFO_FAIL = "GET_SHIFT_BILLING_INFO_FAIL";
export const GET_SHIFT_BILLING_INFO_SUCCESS = "GET_SHIFT_BILLING_INFO_SUCCESS";

export const GET_SHIFT_ADDITIONAL_PAY_INFO = "GET_SHIFT_ADDITIONAL_PAY_INFO";
export const GET_SHIFT_ADDITIONAL_PAY_INFO_FAIL = "GET_SHIFT_ADDITIONAL_PAY_INFO_FAIL";
export const GET_SHIFT_ADDITIONAL_PAY_INFO_SUCCESS = "GET_SHIFT_ADDITIONAL_PAY_INFO_SUCCESS";

export const GET_CONTRACTOR_ADDITIONAL_PAY = "GET_CONTRACTOR_ADDITIONAL_PAY";
export const GET_CONTRACTOR_ADDITIONAL_PAY_FAIL = "GET_CONTRACTOR_ADDITIONAL_PAY_FAIL";
export const GET_CONTRACTOR_ADDITIONAL_PAY_SUCCESS = "GET_CONTRACTOR_ADDITIONAL_PAY_SUCCESS";

export const INITIALIZE_ADMIN_STATE = "INITIALIZE_ADMIN_STATE";

export const NO_REPLACEMENT_FOUND_EMAIL = "NO_REPLACEMENT_FOUND_EMAIL";
export const NO_REPLACEMENT_FOUND_EMAIL_SUCCESS = "NO_REPLACEMENT_FOUND_EMAIL_SUCCESS";

export const BULK_DELETE_SHIFTS = "BULK_DELETE_SHIFTS";
