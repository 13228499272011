import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import jwt from "jsonwebtoken";
import { map, pick } from "lodash";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { SocketSource, initSocketConnection } from "../../Common/Chat/service";
import { identifyPosthog } from "../../Common/Wrappers/identifyPosthog";
import {
  getPendingHospitalTechRatingsAsync,
  getPendingHospitalVetRatingsAsync,
} from "../../Hospital/saga/hospitalSaga";
import { post, rooFetch } from "../../api/common/rooFetch";
import * as loginActions from "../../constants/Login";
import * as userRoles from "../../constants/UserRoleConstants";
import * as userTypes from "../../constants/UserTypeConstants";
import { newVetMarketsReleaseDate, techRegistrationFlows } from "../../constants/constants";
import * as errorMessages from "../../constants/errorMessage";
import * as hospitalActions from "../../constants/hospitalConstants";
import { SET_LOADER, UNSET_LOADER } from "../../constants/loader";
import { GET_REFERRAL_PROMO_AMOUNT } from "../../constants/referNewUser";
import { californiaStateId } from "../../constants/states";
import * as TechActions from "../../constants/techConstants";
import * as userStatusTypes from "../../constants/userStatusTypes";
import * as VetActions from "../../constants/vetConstants";

export const getIsLicenseExpiredFromState = (state) => state.vetDashboard.isLicenseExpired;
export const getCurrentHospitalStateFromState = (state) => state.hospital;

export function* loginSucceededAction(action) {
  const { data, jwtToken } = action.payload;

  const { results, hasPostedShifts } = data.data;
  const oUser = results[0];

  const { userTypeId, email } = oUser;

  const isMultiHospitalAccess = !!Number(oUser.isMultiHospitalAccess);
  const isHospital = userTypeId === userTypes.HOSPITAL_USER_TYPE;
  const isVet = userTypeId === userTypes.VET_USER_TYPE;
  const isTech = userTypeId === userTypes.TECH_USER_TYPE;
  const isRooUniStudent = userTypeId === userTypes.STUDENT_USER_TYPE;

  if (data?.data?.webAppVersion) {
    localStorage.setItem("webAppVersion", data.data.webAppVersion);
  }

  let id;

  if (oUser.vetId !== undefined) {
    id = oUser.vetId;
  } else if (oUser.techId !== undefined) {
    id = oUser.techId;
  } else if (oUser.hospitalId !== undefined) {
    id = oUser.hospitalId;
  } else if (oUser.studentId) {
    id = oUser.studentId;
  }

  // Don't overwrite the Posthog identity with the super user login details
  if (userTypeId !== userTypes.ROO_SUPER_USER_TYPE) {
    identifyPosthog({
      userId: oUser.userId,
      userType: userTypes.isUserType(userTypeId) ? userTypeId : undefined,
      email,
      firstName: oUser.firstName,
      lastName: oUser.lastName,
      fullName: oUser.fullName,
      metroAreaId: oUser.metroAreaId ? parseInt(oUser.metroAreaId) : undefined,
      enterpriseId: oUser.enterpriseId,
      hospitalId: oUser.hospitalId,
    });

    window.dataLayer?.push({ event: "IDENTIFY", email: email, ui: oUser.userId });
  }

  if (!isRooUniStudent) {
    yield put({ type: hospitalActions.INITIALIZE_DEFAULT_VET_RATING_COUNT });
    yield put({ type: hospitalActions.INITIALIZE_DEFAULT_TECH_RATING_COUNT });
  }

  if (oUser.isActive == 0 && (oUser.vetId || oUser.techId)) {
    yield put({
      type: loginActions.LOGIN_ROO_DEMO_USER_SUCCESS,
      payload: {
        status: data.status_code,
        userTypeId: oUser.userTypeId,
        userId: oUser.userId,
        id: id,
        fullName: oUser.fullName,
        firstName: oUser.firstName,
        lastName: oUser.lastName,
        isNameUpdated: oUser.isNameUpdated,
        dateOfBirth: oUser.dateOfBirth,
        enterpriseName: oUser.enterpriseName,
        enterpriseLogo: oUser.enterpriseLogo,
        enterpriseId: oUser.enterpriseId,
        enterpriseStatus: oUser.enterpriseStatus,
        hospitalGroupIds: oUser.hospitalGroupIds,
        groupNames: oUser.groupNames,
        hospitalUserId: oUser.hospitalUserId,
        hospitalIds: oUser.hospitalIds,
        hospitalNames: oUser.hospitalNames,
        jwtToken: jwtToken,
        email: oUser.emailId,
        phoneNumber: oUser.phoneNumber,
        profileImage: oUser.profileImage,
        probationStatus: oUser.probationStatus,
        isRegistrationComplete: oUser.isRegistrationComplete,
        isInternshipsAvailable: oUser.isInternshipsAvailable,
        isShowUniCallout: oUser.isShowUniCallout,
        isShowDashboardTour: oUser.isShowDashboardTour,
        isShowPendingReviews: oUser.isShowPendingReviews,
        hasPostedShifts,
        isActive: oUser.isActive, // isActive holds user status type
        trainingStatus: oUser.trainingStatus,
        trainingCount: oUser.trainingCount,
        hasPaymentSetup: String(oUser.hasPaymentSetup) === "1",
        isVerifyMicroDeposits: String(oUser.isVerifyMicroDeposits) === "1",
        isRetryAddBank: String(oUser.isRetryAddBank) === "1",
        isStripeAccountVerifyAgain: String(oUser.isStripeAccountVerifyAgain) === "1",
        hasW9: String(oUser.hasW9) === "1",
        hasLicense: String(oUser.hasLicense) === "1",
        isShowUpdateMessage: oUser.isShowUpdateMessage,
        isHospitalBillingSetUp: oUser.isHospitalBillingSetUp,
        isInvoicedEnabled: oUser?.isInvoicedEnabled ?? 0,
        typeOfHospital: oUser.typeOfHospital,
        isShiftPostsExpire: oUser.isShiftPostsExpire,
        hospitalTimezone: oUser.hospitalTimezone,
        zipcode: oUser.zipcode,
        stateId: oUser.stateId,
        metroAreaId: parseInt(oUser.metroAreaId),
        metroAreaName: oUser.metroAreaName,
        latitude: oUser.latitude,
        longitude: oUser.longitude,
        cognitoId: oUser.cognitoId,
        userName: oUser.userName,
        userRoleId: oUser.userRoleId,
        isMultiHospitalAccess: oUser.isMultiHospitalAccess,
        isHospitalAdmin: oUser.isHospitalAdmin,
        vetRegistrationPageNo: oUser.vetRegistrationPageNo,
        techRegistrationPageNo: oUser.techRegistrationPageNo,
        studentRegistrationPageNo: oUser.studentRegistrationPageNo,
        hospitalRegistrationPageNo: oUser.hospitalRegistrationPageNo,
        newRegistrationPage: oUser.newRegistrationPage,
        travelDistance: oUser.travelDistance,
        licenses: oUser.licenses,
        school: oUser.school,
        yearsOfPractice: oUser.yearsOfPractice,
        isShowAlertFormPop: oUser.isShowAlertFormPop,
        alertFormPopCount: oUser.alertFormPopCount,
        tier: oUser.tier,
        favorites: oUser.favorites, // vet & tech favorite hospitals
        referralCode: oUser.referralCode,
        isTechBackgroundChecked: oUser.isBackgroundChecked === 1,
        isRooTechState: oUser.isRooTechState,
        isRooVetState: oUser.isRooVetState,
        techRegistrationFlow: oUser.techRegistrationFlow,
        locationFavorability: oUser.locationFavorability == 1 ? 1 : null,
        isPostingPaused: oUser.isActive == 10,
        termsAccepted: oUser.termsAccepted,
        icAgreementAcceptedOn: oUser.icAgreementAcceptedOn,
        icAgreementOptions: oUser.icAgreementOptions,
        icAgreementSignature: oUser.icAgreementSignature,
        approvedOnDate: oUser.approvedOnDate,
        calendlyLink: oUser.calendlyLink,
        graduationYear: oUser.graduationYear,
        hospitalGroupNames: oUser.hospitalGroupNames,
        isShowOnboarding: oUser.isShowOnboarding,
        hasDEA: oUser.hasDEA,
        hasFearFreeCertification: oUser.hasFearFreeCertification,
        hasSeenFearFreeAnnouncement: oUser.hasSeenFearFreeAnnouncement,
      },
    });
  } else {
    const myHospitalsList = oUser.myHospitalsList?.length
      ? JSON.parse(`[${oUser.myHospitalsList}]`)
      : [];
    const hospitalIds = map(myHospitalsList, (o) => o.id)?.join(",");
    const hospitalNames = map(myHospitalsList, (o) => o.name)?.join(",");
    yield put({
      type: loginActions.LOGIN_SUCCESS,
      payload: {
        status: data.status_code,
        userTypeId: oUser.userTypeId,
        userId: oUser.userId,
        id: id,
        fullName: oUser.fullName,
        firstName: oUser.firstName,
        lastName: oUser.lastName,
        isNameUpdated: oUser.isNameUpdated,
        dateOfBirth: oUser.dateOfBirth,
        enterpriseName: oUser.enterpriseName,
        enterpriseLogo: oUser.enterpriseLogo,
        enterpriseId: oUser.enterpriseId,
        enterpriseStatus: oUser.enterpriseStatus,
        hospitalGroupIds: oUser.hospitalGroupIds,
        groupNames: oUser.groupNames,
        hospitalUserId: oUser.hospitalUserId,
        myHospitalsList,
        hospitalIds,
        hospitalNames,
        jwtToken: jwtToken,
        email: oUser.emailId,
        phoneNumber: oUser.phoneNumber,
        profileImage: oUser.profileImage,
        probationStatus: oUser.probationStatus,
        isRegistrationComplete: oUser.isRegistrationComplete,
        isInternshipsAvailable: oUser.isInternshipsAvailable,
        isShowUniCallout: oUser.isShowUniCallout,
        isShowDashboardTour: oUser.isShowDashboardTour,
        isShowPendingReviews: oUser.isShowPendingReviews,
        hasPostedShifts,
        isActive: oUser.isActive, // isActive holds user status type
        trainingStatus: oUser.trainingStatus,
        trainingCount: oUser.trainingCount,
        hasDEA: oUser.hasDEA,
        hasFearFreeCertification: oUser.hasFearFreeCertification,
        hasSeenFearFreeAnnouncement: oUser.hasSeenFearFreeAnnouncement,
        hasPaymentSetup: String(oUser.hasPaymentSetup) === "1",
        isVerifyMicroDeposits: String(oUser.isVerifyMicroDeposits) === "1",
        isRetryAddBank: String(oUser.isRetryAddBank) === "1",
        isStripeAccountVerifyAgain: String(oUser.isStripeAccountVerifyAgain) === "1",
        hasW9: String(oUser.hasW9) === "1",
        hasLicense: String(oUser.hasLicense) === "1",
        isShowUpdateMessage: oUser.isShowUpdateMessage,
        isHospitalBillingSetUp: oUser.isHospitalBillingSetUp,
        isInvoicedEnabled: oUser?.isInvoicedEnabled ?? 0,
        isInternalReliefEnabled: oUser.isInternalReliefEnabled,
        typeOfHospital: oUser.typeOfHospital,
        isShiftPostsExpire: oUser.isShiftPostsExpire,
        hospitalTimezone: oUser.hospitalTimezone,
        zipcode: oUser.zipcode,
        stateId: oUser.stateId,
        metroAreaId: parseInt(oUser.metroAreaId),
        metroAreaName: oUser.metroAreaName,
        latitude: oUser.latitude,
        longitude: oUser.longitude,
        cognitoId: oUser.cognitoId,
        userName: oUser.userName,
        userRoleId: oUser.userRoleId,
        isMultiHospitalAccess: oUser.isMultiHospitalAccess,
        isHospitalAdmin: oUser.isHospitalAdmin,
        vetRegistrationPageNo: oUser.vetRegistrationPageNo,
        techRegistrationPageNo: oUser.techRegistrationPageNo,
        studentRegistrationPageNo: oUser.studentRegistrationPageNo,
        hospitalRegistrationPageNo: oUser.hospitalRegistrationPageNo,
        newRegistrationPage: oUser.newRegistrationPage,
        travelDistance: oUser.travelDistance,
        licenses: oUser.licenses,
        school: oUser.school,
        yearsOfPractice: oUser.yearsOfPractice,
        isShowAlertFormPop: oUser.isShowAlertFormPop,
        alertFormPopCount: oUser.alertFormPopCount,
        tier: oUser.tier,
        favorites: oUser.favorites, // vet & tech favorite hospitals
        referralCode: oUser.referralCode,
        isTechBackgroundChecked: oUser.isBackgroundChecked === 1,
        isRooTechState: oUser.isRooTechState,
        isRooVetState: oUser.isRooVetState,
        techRegistrationFlow: oUser.techRegistrationFlow,
        locationFavorability: oUser.locationFavorability == 1 ? 1 : null,
        isPostingPaused: oUser.isActive == 10,
        past3MonthsShiftRequestCount: oUser.past3MonthsShiftRequestCount,
        termsAccepted: oUser.termsAccepted,
        termsReAccepted: oUser.termsReAccepted,
        icAgreementAcceptedOn: oUser.icAgreementAcceptedOn,
        icAgreementOptions: oUser.icAgreementOptions,
        icAgreementSignature: oUser.icAgreementSignature,
        approvedOnDate: oUser.approvedOnDate,
        calendlyLink: oUser.calendlyLink,
        graduationYear: oUser.graduationYear,
        hospitalGroupNames: oUser.hospitalGroupNames,
        isShowOnboarding: oUser.isShowOnboarding,
        completedOnboardingStep: oUser.completedOnboardingStep,
        hasPaymentFailed: oUser.hasPaymentFailed,
        hasTechLicense: oUser.hasTechLicense,
        shiftFullfilledCount: oUser.shiftFullfilledCount,
        isEligibleForNPS: oUser.isEligibleForNPS,
        hasCALicense: oUser.licenses?.some((license) => license.id === californiaStateId),
      },
    });
  }
  initSocketConnection(SocketSource.LOGIN);

  if (
    isHospital &&
    oUser.userRoleId !== userRoles.GROUP_ADMIN &&
    oUser.userRoleId !== userRoles.ENTERPRISE_ADMIN &&
    !isMultiHospitalAccess &&
    id
  ) {
    let getPendingTechTrainingRequest = false;
    yield getPendingHospitalVetRatingsAsync({
      payload: { isGetAllReviews: oUser.isShowPendingReviews },
    });

    if (oUser.stateId !== californiaStateId) {
      getPendingTechTrainingRequest = true;
      yield getPendingHospitalTechRatingsAsync({
        payload: { isCheckBlockedTechCount: true, isGetAllReviews: oUser.isShowPendingReviews },
      });
      // This is to open training popup
      yield showTrainingPopupAsync(oUser, getPendingTechTrainingRequest);
    } else {
      yield showTrainingPopupAsync(oUser, getPendingTechTrainingRequest);
    }
    yield put({ type: hospitalActions.FETCH_SHIFT_COUNT_NOTIFICATION_REQUEST });
    // yield put( { type: hospitalActions.FETCH_RENEW_SHIFTS_REQUEST } );
    yield put({ type: hospitalActions.NOTIFICATION_MODAL_OPEN });
  } else if (isVet) {
    yield put({
      type: VetActions.UPDATE_BLOCKED_HOSPITAL_COUNT,
      payload: {
        blockedHospitalCount: oUser.blockedHospitalCount,
        isSet: true,
      },
    });
    if (!oUser.termsAccepted) yield put({ type: loginActions.SHOW_AC_CONTRACT });
    //checking if the License is expired or not.
    yield put({ type: VetActions.CHECK_VET_LICENSE_EXPIRED_REQUEST });
    const isLicenseExpired = yield select(getIsLicenseExpiredFromState);

    if (isLicenseExpired && new Date().getDate() === 1) {
      //This is to open the license expire popup when the license is expired
      //and its first day of month.
      yield put({ type: VetActions.OPEN_VET_LICENSE_EXPIRED_MODAL });
    }

    yield put({ type: VetActions.GET_PENDING_VET_RATINGS_REQUEST });
    if (oUser.trainingStatus === 0 && oUser.hasW9 && oUser.trainingCount > 0) {
      yield put({ type: loginActions.SHOW_TRAINING_POPUP });
    }
  } else if (isTech) {
    yield put({
      type: TechActions.UPDATE_BLOCKED_HOSPITAL_COUNT,
      payload: {
        blockedHospitalCount: oUser.blockedHospitalCount,
        isSet: true,
      },
    });
    if (
      !oUser.termsAccepted &&
      oUser.techRegistrationFlow != techRegistrationFlows.TexasFlow &&
      oUser.hasPaymentSetup === 0
    ) {
      yield put({ type: loginActions.SHOW_AC_CONTRACT });
    }
    yield put({ type: TechActions.GET_PENDING_TECH_RATINGS_REQUEST });
    yield put({ type: loginActions.GET_TECH_REQUEST_COUNT });
    if (oUser.trainingStatus === 0 && oUser.hasW9 && oUser.trainingCount > 0) {
      yield put({ type: loginActions.SHOW_TRAINING_POPUP });
    }
  }
  yield put({ type: GET_REFERRAL_PROMO_AMOUNT });
}

export function* loginSucceededDemoAction(action) {
  const { data, jwtToken } = action.payload;

  const { results, hasPostedShifts } = data.data;
  const oUser = results[0];
  const { userTypeId } = oUser;

  const isRooUniStudent = userTypeId === userTypes.STUDENT_USER_TYPE;

  if (data?.data?.webAppVersion) {
    localStorage.setItem("webAppVersion", data.data.webAppVersion);
  }
  let id;
  if (oUser.vetId !== undefined) {
    id = oUser.vetId;
  } else if (oUser.techId !== undefined) {
    id = oUser.techId;
  } else if (oUser.hospitalId !== undefined) {
    id = oUser.hospitalId;
  } else if (oUser.studentId) {
    id = oUser.studentId;
  }
  if (!isRooUniStudent) {
    yield put({ type: hospitalActions.INITIALIZE_DEFAULT_VET_RATING_COUNT });
    yield put({ type: hospitalActions.INITIALIZE_DEFAULT_TECH_RATING_COUNT });
  }

  yield put({
    type: loginActions.LOGIN_ROO_DEMO_USER_SUCCESS,
    payload: {
      status: data.status_code,
      userTypeId: oUser.userTypeId,
      userId: oUser.userId,
      id: id,
      fullName: oUser.fullName,
      firstName: oUser.firstName,
      lastName: oUser.lastName,
      isNameUpdated: oUser.isNameUpdated,
      dateOfBirth: oUser.dateOfBirth,
      enterpriseName: oUser.enterpriseName,
      enterpriseLogo: oUser.enterpriseLogo,
      enterpriseId: oUser.enterpriseId,
      enterpriseStatus: oUser.enterpriseStatus,
      hospitalGroupIds: oUser.hospitalGroupIds,
      groupNames: oUser.groupNames,
      hospitalUserId: oUser.hospitalUserId,
      hospitalIds: oUser.hospitalIds,
      hospitalNames: oUser.hospitalNames,
      jwtToken: jwtToken,
      email: oUser.emailId,
      phoneNumber: oUser.phoneNumber,
      profileImage: oUser.profileImage,
      probationStatus: oUser.probationStatus,
      isRegistrationComplete: oUser.isRegistrationComplete,
      isInternshipsAvailable: oUser.isInternshipsAvailable,
      isShowUniCallout: oUser.isShowUniCallout,
      isShowDashboardTour: oUser.isShowDashboardTour,
      isShowPendingReviews: oUser.isShowPendingReviews,
      hasPostedShifts,
      isActive: oUser.isActive, // isActive holds user status type
      trainingStatus: oUser.trainingStatus,
      trainingCount: oUser.trainingCount,
      hasDEA: oUser.hasDEA,
      hasFearFreeCertification: oUser.hasFearFreeCertification,
      hasSeenFearFreeAnnouncement: oUser.hasSeenFearFreeAnnouncement,
      hasPaymentSetup: String(oUser.hasPaymentSetup) === "1",
      isVerifyMicroDeposits: String(oUser.isVerifyMicroDeposits) === "1",
      isRetryAddBank: String(oUser.isRetryAddBank) === "1",
      isStripeAccountVerifyAgain: String(oUser.isStripeAccountVerifyAgain) === "1",
      hasW9: String(oUser.hasW9) === "1",
      hasLicense: String(oUser.hasLicense) === "1",
      isShowUpdateMessage: oUser.isShowUpdateMessage,
      isHospitalBillingSetUp: oUser.isHospitalBillingSetUp,
      isInvoicedEnabled: oUser?.isInvoicedEnabled ?? 0,
      typeOfHospital: oUser.typeOfHospital,
      isShiftPostsExpire: oUser.isShiftPostsExpire,
      hospitalTimezone: oUser.hospitalTimezone,
      zipcode: oUser.zipcode,
      stateId: oUser.stateId,
      metroAreaId: parseInt(oUser.metroAreaId),
      metroAreaName: oUser.metroAreaName,
      latitude: oUser.latitude,
      longitude: oUser.longitude,
      cognitoId: oUser.cognitoId,
      userName: oUser.userName,
      userRoleId: oUser.userRoleId,
      isMultiHospitalAccess: oUser.isMultiHospitalAccess,
      isHospitalAdmin: oUser.isHospitalAdmin,
      vetRegistrationPageNo: oUser.vetRegistrationPageNo,
      techRegistrationPageNo: oUser.techRegistrationPageNo,
      studentRegistrationPageNo: oUser.studentRegistrationPageNo,
      hospitalRegistrationPageNo: oUser.hospitalRegistrationPageNo,
      newRegistrationPage: oUser.newRegistrationPage,
      travelDistance: oUser.travelDistance,
      licenses: oUser.licenses,
      school: oUser.school,
      yearsOfPractice: oUser.yearsOfPractice,
      isShowAlertFormPop: oUser.isShowAlertFormPop,
      alertFormPopCount: oUser.alertFormPopCount,
      tier: oUser.tier,
      favorites: oUser.favorites, // vet & tech favorite hospitals
      referralCode: oUser.referralCode,
      isTechBackgroundChecked: oUser.isBackgroundChecked === 1,
      isRooTechState: oUser.isRooTechState,
      isRooVetState: oUser.isRooVetState,
      techRegistrationFlow: oUser.techRegistrationFlow,
      locationFavorability: oUser.locationFavorability == 1 ? 1 : null,
      isPostingPaused: oUser.isActive == 10,
      termsAccepted: oUser.termsAccepted,
      icAgreementAcceptedOn: oUser.icAgreementAcceptedOn,
      icAgreementOptions: oUser.icAgreementOptions,
      icAgreementSignature: oUser.icAgreementSignature,
      approvedOnDate: oUser.approvedOnDate,
      calendlyLink: oUser.calendlyLink,
      graduationYear: oUser.graduationYear,
      hospitalGroupNames: oUser.hospitalGroupNames,
      isShowOnboarding: oUser.isShowOnboarding,
    },
  });
  yield put({ type: GET_REFERRAL_PROMO_AMOUNT });
}

export function* cognitoSignInSucceededAction(userData, jwtToken) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/user/login", {
      method: "POST",
      body: JSON.stringify(userData),
    });
    const data = yield response.json();
    const oUser = data?.data?.results[0] || {};
    const statusCode = data.status_code;

    const { userTypeId, calendlyLink } = oUser;

    const isHospital = userTypeId === userTypes.HOSPITAL_USER_TYPE;
    const isVet = userTypeId === userTypes.VET_USER_TYPE;
    const isTech = userTypeId === userTypes.TECH_USER_TYPE;
    const isRooUniStudent = userTypeId === userTypes.STUDENT_USER_TYPE;
    if (
      (statusCode === 200 || statusCode === 412) &&
      (isHospital || isVet || isTech || isRooUniStudent) &&
      oUser.userStatus === "ERROR"
    ) {
      // if userStatus is error
      const userStatusTypeId = oUser.isActive;

      const isPendingHospitalLoginEnabled = true;
      // pending vets and techs will be logged in as a demo user
      // blocked vets and techs will be logged in as a demo user
      // pending hospitals can login as normal if the feature flag is enabled
      if (
        (userStatusTypeId === userStatusTypes.PENDING &&
          (isVet || isTech || (isHospital && isPendingHospitalLoginEnabled))) ||
        (userStatusTypeId === userStatusTypes.BLOCKED && (isVet || isTech))
      ) {
        if (isHospital) yield loginSucceededAction({ payload: { data, jwtToken } });
        else yield loginSucceededDemoAction({ payload: { data, jwtToken } });
      } else {
        let errorMessage = "";
        let link = null;

        switch (userStatusTypeId) {
          case userStatusTypes.BLOCKED:
            errorMessage = errorMessages.LOGIN_BLOCKED_MESSAGE;
            break;

          case userStatusTypes.SUSPENDED:
            errorMessage = errorMessages.LOGIN_SUSPENDED_MESSAGE;
            break;

          case userStatusTypes.NONRESPONSIVE:
            errorMessage = errorMessages.LOGIN_REJECTED_MESSAGE;
            break;

          case userStatusTypes.NO_ASSIGNED_HOSPITALS:
            errorMessage = errorMessages.NO_ASSIGNED_HOSPITALS;
            break;
          case userStatusTypes.PENDING:
          default:
            link = calendlyLink;
            if (isHospital) {
              errorMessage =
                "Your application is under review. If you have not already spoken to a Roo team member, please schedule an appointment by ";
            } else if (isRooUniStudent) {
              errorMessage =
                "Your application is under review. If you have not already spoken " +
                "to our coordinator, please schedule an appointment by ";
              link = "https://calendly.com/andrew-findlaytor/roouniversity";
            }
        }

        yield put({
          type: loginActions.LOGIN_FAIL,
          payload: {
            message: errorMessage,
            link,
            status: statusCode,
          },
        });
        localStorage.clear(); // clear localStorage if userStatus is error
      }
    } else if (statusCode === 200) {
      yield loginSucceededAction({ payload: { data, jwtToken } });
    } else if (statusCode === 401) {
      yield put({
        type: loginActions.LOGIN_FAIL,
        payload: { status: statusCode, message: data.message },
      });
    } else {
      yield put({
        type: loginActions.LOGIN_FAIL,
        payload: { status: statusCode, message: errorMessages.EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in cognitoSignInSucceededAction" } });
    yield put({
      type: loginActions.LOGIN_FAIL,
      payload: { status: 500, message: errorMessages.EXCEPTION_MESSAGE },
    });
  }
}

export function* cognitoLogin(data) {
  const email = data.email.toLowerCase();
  const { password } = data;
  const signInRequest = yield Auth.signIn(email, password);
  return signInRequest;
}

export function* loginAsync(action) {
  try {
    const email = action.payload.email.toLowerCase();
    const { password, isFromRegistration } = action.payload;
    const signInRequest = action.payload.signInRequest || (yield Auth.signIn(email, password));
    const { signInUserSession, challengeName } = signInRequest;

    if (challengeName === "NEW_PASSWORD_REQUIRED") {
      yield put({
        type: loginActions.NEW_PASSWORD_REQUIRED,
        payload: { email, tempPassword: password, signInRequest },
      });
    }

    if (signInUserSession && signInRequest.username) {
      const jwtToken = signInRequest.signInUserSession.idToken.jwtToken;
      const decoded = jwt.decode(jwtToken, { complete: true });

      const userData = {
        email: decoded.payload.email,
        userTypeId: parseInt(decoded.payload["custom:usertype"]),
        cognitoId: signInRequest.username,
      };

      if (!isFromRegistration) {
        yield cognitoSignInSucceededAction(userData, jwtToken);
      }
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in loginAsync" } });
    yield put({ type: loginActions.LOGIN_FAIL, payload: { status: 401, message: error.message } });
  }
}

function* vetCompleteTrainingAsync() {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/updateTrainingStatus",
      {
        method: "POST",
        body: JSON.stringify({ userId: localStorage.getItem("userId") }),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: loginActions.VET_COMPLETE_TRAINING_SUCCESS });
    } else {
      yield put({ type: loginActions.VET_COMPLETE_TRAINING_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in vetCompleteTrainingAsync" } });
  }
}

function* techCompleteTrainingAsync() {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/updateTrainingStatus",
      {
        method: "POST",
        body: JSON.stringify({ userId: localStorage.getItem("userId") }),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: loginActions.TECH_COMPLETE_TRAINING_SUCCESS });
    } else {
      yield put({ type: loginActions.TECH_COMPLETE_TRAINING_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in techCompleteTrainingAsync" } });
  }
}

function* setSessionAsync(action) {
  try {
    const enterpriseHospitalIdParam = action.payload?.hospitalId;

    if (enterpriseHospitalIdParam) {
      localStorage.setItem("hospitalId", enterpriseHospitalIdParam);
    }
    const userTypeId = parseInt(localStorage.getItem("userTypeId"));

    const isHospital = userTypeId === userTypes.HOSPITAL_USER_TYPE;
    const isVet = userTypeId === userTypes.VET_USER_TYPE;
    const isTech = userTypeId === userTypes.TECH_USER_TYPE;

    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/user/getUserInfo", {
      method: "POST",
      body: JSON.stringify({
        userTypeId: userTypeId,
        email: localStorage.getItem("email"),
        hospitalId: localStorage.getItem("hospitalId"),
      }),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      if (data?.webAppVersion) {
        localStorage.setItem("webAppVersion", data.webAppVersion);
      }

      const oUserInfo = data.data.results[0];
      const { calendlyLink, isActive, emailId, userId } = oUserInfo;

      window.dataLayer?.push({ event: "IDENTIFY", email: emailId, ui: userId });

      if (calendlyLink && calendlyLink.length > 0) {
        localStorage.setItem("calendlyLink", calendlyLink);
      }
      // if an approved vet is in preview mode, reset localStorage value to recognize they're approved
      if (
        isActive == userStatusTypes.APPROVED &&
        localStorage.getItem("isActive") != String(userStatusTypes.APPROVED)
      ) {
        localStorage.setItem("isReloadAfterDemo", "true");
      }
      localStorage.setItem("isActive", isActive);

      const isMultiHospitalAccess = !!Number(oUserInfo.isMultiHospitalAccess);
      const isHospitalSession =
        isHospital && (!isMultiHospitalAccess || !!enterpriseHospitalIdParam);

      if (
        isHospitalSession &&
        oUserInfo.userRoleId !== userRoles.GROUP_ADMIN &&
        oUserInfo.userRoleId !== userRoles.ENTERPRISE_ADMIN &&
        !isMultiHospitalAccess
      ) {
        oUserInfo.hasPostedShifts = data.data?.hasPostedShifts;
        const {
          fullName,
          isRegistrationComplete,
          stateId,
          metroAreaId,
          zipcode,
          firstName,
          lastName,
        } = oUserInfo;
        const userFullName = firstName ? `${firstName} ${lastName}` : fullName;
        localStorage.setItem("fullName", userFullName); //hospital name
        localStorage.setItem("isRegistrationComplete", isRegistrationComplete);
        localStorage.setItem("hospitalStateId", stateId);
        localStorage.setItem("metroAreaId", parseInt(metroAreaId));
        localStorage.setItem("zipcode", zipcode);
        yield put({
          type: hospitalActions.GET_HOSPITAL_PENDING_VET_RATING_REQUEST,
          payload: { isGetAllReviews: oUserInfo.isShowPendingReviews },
        });
        if (oUserInfo.stateId !== californiaStateId) {
          yield put({
            type: hospitalActions.GET_HOSPITAL_PENDING_TECH_RATING_REQUEST,
            payload: {
              isCheckBlockedTechCount: true,
              isGetAllReviews: oUserInfo.isShowPendingReviews,
            },
          });
        }
        yield put({ type: hospitalActions.FETCH_SHIFT_COUNT_NOTIFICATION_REQUEST });
        yield put({
          type: loginActions.SET_LOCATION_AND_SHIFT_FAVORABILITY,
          payload: oUserInfo,
        });
      } else if (isVet) {
        /* VET */

        yield put({
          type: VetActions.UPDATE_BLOCKED_HOSPITAL_COUNT,
          payload: {
            blockedHospitalCount: data.data.results[0].blockedHospitalCount,
            isSet: true,
          },
        });
        if (
          !oUserInfo.termsAccepted &&
          new Date(oUserInfo.approvedOnDate).getTime() >=
            new Date(newVetMarketsReleaseDate).getTime()
        ) {
          yield put({ type: loginActions.SHOW_AC_CONTRACT });
        }
        yield put({ type: VetActions.GET_PENDING_VET_RATINGS_REQUEST });
        //checking if the License is expired or not.
        yield put({ type: VetActions.CHECK_VET_LICENSE_EXPIRED_REQUEST });
        const isLicenseExpired = yield select(getIsLicenseExpiredFromState);

        if (isLicenseExpired && new Date().getDate() === 1) {
          //This is to open the license expire popup when the license is expired
          //and its first day of month.
          yield put({ type: VetActions.OPEN_VET_LICENSE_EXPIRED_MODAL });
        }
      } else if (isTech) {
        /* TECH */

        yield put({
          type: TechActions.UPDATE_BLOCKED_HOSPITAL_COUNT,
          payload: {
            blockedHospitalCount: data.data.results[0].blockedHospitalCount,
            isSet: true,
          },
        });
        if (
          !oUserInfo.termsAccepted &&
          oUserInfo.techRegistrationFlow != techRegistrationFlows.TexasFlow
        ) {
          yield put({ type: loginActions.SHOW_AC_CONTRACT });
        }
        yield put({ type: TechActions.GET_PENDING_TECH_RATINGS_REQUEST });
        yield put({ type: loginActions.GET_TECH_REQUEST_COUNT });
      }

      /* ALL */
      yield put({ type: GET_REFERRAL_PROMO_AMOUNT });
      yield put({ type: loginActions.SET_SESSION_SUCCESS, payload: oUserInfo });
    } else {
      yield put({ type: loginActions.SET_SESSION_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in setSessionAsync" } });
  }
}

function* showTrainingPopupAsync(oUser, getPendingTechTrainingRequest) {
  try {
    const hospital = yield select(getCurrentHospitalStateFromState);
    // This is to open training popup if user has not completed training and
    // if ratings for vet is not pending
    const isShowTraining =
      oUser.trainingStatus === 0 &&
      oUser.isHospitalBillingSetUp === 1 &&
      oUser.isHospitalAdmin === 1 &&
      oUser.trainingCount > 0 &&
      !hospital.isPendingVetRatings &&
      hospital.isGetVetRatingsCompleted;

    if (getPendingTechTrainingRequest) {
      if (isShowTraining && !hospital.isPendingTechRatings && hospital.isGetTechRatingCompleted) {
        // This is to open training popup if rating for vet & tech is not pending
        yield put({ type: loginActions.SHOW_TRAINING_POPUP });
      }
    } else if (isShowTraining) {
      yield put({ type: loginActions.SHOW_TRAINING_POPUP });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in showTrainingPopupAsync" } });
  }
}

function* updateFavoritesAsync(action) {
  try {
    const { vetId, studentId } = action.payload;
    const user = studentId ? "student" : vetId ? "vet" : "tech";
    const response = yield call(
      rooFetch,
      `${window.RooConfig.API_URL}api/${user}/updateFavorites`,
      {
        method: "POST",
        body: JSON.stringify(pick(action.payload, ["vetId", "techId", "studentId", "favorites"])),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: loginActions.UPDATE_FAVORITES_SUCCESS, payload: action.payload });
    } else {
      yield put({ type: loginActions.UPDATE_FAVORITES_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in updateFavoritesAsync" } });
  }
}

export function* sendEventRequestSaga(action) {
  try {
    const response = yield post("api/event/eventRequest", action.payload);

    if (response.status_code === 200) {
      yield put({
        type: loginActions.SEND_REQUEST_FOR_EVENT_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in sendEventRequestSaga" } });

    yield put({
      type: loginActions.SEND_REQUEST_FOR_EVENT_FAIL,
      payload: { status: error.status, message: errorMessages.EXCEPTION_MESSAGE },
    });
  }
}

export function* sendEventCancelRequestSaga(action) {
  try {
    const response = yield post("api/event/eventCancelRequest", action.payload);

    if (response.status_code === 200) {
      yield put({
        type: loginActions.SEND_CANCEL_REQUEST_FOR_EVENT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in sendEventCancelRequestSaga" } });

    yield put({
      type: loginActions.SEND_CANCEL_REQUEST_FOR_EVENT_FAIL,
      payload: { status: error.status, message: errorMessages.EXCEPTION_MESSAGE },
    });
  }
}

function* signICAgreement(action) {
  try {
    yield put({ type: SET_LOADER });

    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/user/signICAgreement", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: loginActions.AC_CONTRACT_SUCCESS_SET_DATA,
        payload: data.data.data,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in signICAgreement" } });
    throw new Error(error);
  }
}

function* acceptTerms(action) {
  try {
    yield put({ type: SET_LOADER });

    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/user/acceptTerms", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: loginActions.ACCEPT_TERMS_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: loginActions.AC_CONTRACT_SUCCESS_SET_DATA,
        payload: { termsAccepted: 1 },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in acceptTerms" } });
    throw new Error(error);
  }
}

function* acceptCodeOfConduct(action) {
  try {
    yield put({ type: SET_LOADER });

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/acceptCodeOfConduct",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: loginActions.ACCEPT_CODE_OF_CONDUCT_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: loginActions.AC_CONTRACT_SUCCESS_SET_DATA,
        payload: { codeOfConductAccepted: 1 },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in acceptCodeOfConduct" } });
    throw new Error(error);
  }
}

function* updateUserName(action) {
  try {
    const { firstName, lastName } = action.payload;
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/user/updateUserName", {
      method: "PUT",
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    if (data.success) {
      yield put({ type: loginActions.UPDATE_USER_NAME_SUCCESS, payload: { firstName, lastName } });
    } else {
      yield put({
        type: loginActions.UPDATE_USER_NAME_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error in updateUserName" } });
    yield put({
      type: loginActions.UPDATE_USER_NAME_FAIL,
      payload: { status: error.status_code },
    });
  }
}

function* getCancelledShiftDetailsIn30Days(action) {
  try {
    yield put({ type: SET_LOADER });
    const { isVet } = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/${isVet ? "vet" : "tech"}/getCancelledShiftDetails/${action.payload.userId}`
    );

    const resData = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (resData.status_code == 200) {
      yield put({
        type: loginActions.GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS_SUCCESS,
        payload: resData.data,
      });
    }
  } catch (error) {
    yield put({ type: UNSET_LOADER });
    yield put({ type: loginActions.GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS_FAIL });
    Sentry.captureException(error, {
      extra: { context: "getCancelledShiftDetailsIn30Days :: Error : " },
    });
  }
}

function* updateHasSeenFearFreeAnnouncement() {
  yield put({
    type: loginActions.UPDATE_HAS_SEEN_FEAR_FREE_ANNOUNCEMENT_SUCCESS,
  });
}

// eslint-disable-next-line no-restricted-exports
export default function* rootLoginSaga() {
  yield takeEvery(loginActions.LOGIN_REQUEST, loginAsync);
  yield takeEvery(loginActions.LOG_IN_AS_USER_SUCCESS, loginSucceededAction); // roo super user view user
  yield takeEvery(loginActions.VET_COMPLETE_TRAINING_REQUEST, vetCompleteTrainingAsync);
  yield takeEvery(loginActions.TECH_COMPLETE_TRAINING_REQUEST, techCompleteTrainingAsync);
  yield takeEvery(loginActions.SET_SESSION_REQUEST, setSessionAsync);
  yield takeEvery(loginActions.UNSET_ENTERPRISE_HOSPITAL_SESSION, setSessionAsync);
  yield takeEvery(loginActions.UPDATE_FAVORITES, updateFavoritesAsync);
  yield takeEvery(loginActions.SEND_REQUEST_FOR_EVENT, sendEventRequestSaga);
  yield takeEvery(loginActions.SEND_CANCEL_REQUEST_FOR_EVENT, sendEventCancelRequestSaga);
  yield takeEvery(loginActions.SIGN_AC_CONTRACT, signICAgreement);
  yield takeEvery(loginActions.ACCEPT_TERMS, acceptTerms);
  yield takeEvery(loginActions.LOGIN_ROO_DEMO_USER, loginSucceededDemoAction);
  yield takeEvery(loginActions.UPDATE_USER_NAME, updateUserName);
  yield takeEvery(loginActions.ACCEPT_CODE_OF_CONDUCT, acceptCodeOfConduct);
  yield takeEvery(
    loginActions.GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS,
    getCancelledShiftDetailsIn30Days
  );
  yield takeEvery(
    loginActions.UPDATE_HAS_SEEN_FEAR_FREE_ANNOUNCEMENT,
    updateHasSeenFearFreeAnnouncement
  );
}
