export const createShortLink = async (link: string): Promise<string> => {
  const requestData = {
    domain: "link.roo.vet",
    originalURL: link,
  };

  // eslint-disable-next-line roo/no-restricted-functions
  const response = await fetch("https://api.short.io/links/public", {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      authorization: "pk_0H7QAun8up8YhhYA", // @TODO: Leaked secret?
    },
    body: JSON.stringify(requestData),
  });

  const data = await response.json();
  return data.shortURL;
};
