import React from "react";

import { FormLabel } from "../FormLabel";
import { ErrorText } from "../Text/ErrorText";

import {
  CheckboxGroupContainer,
  CheckboxGroupFlex,
  CheckboxGroupGridLayout,
} from "./CustomCheckboxStyles";

import { Checkbox, CustomCheckboxProps } from "./index";

export interface CheckboxGroupProps<NarrowCheckboxProps extends CustomCheckboxProps> {
  checkboxes: NarrowCheckboxProps[];
  onChange: (event: any, updatedCheckboxes: NarrowCheckboxProps[], value?: any) => void;
  label?: string;
  flex?: boolean;
  error?: string;
  name?: string;
  disabled?: boolean;
}
export const CheckboxGroup = <NarrowCheckboxProps extends CustomCheckboxProps>({
  checkboxes,
  label,
  onChange,
  flex = false,
  error,
  name,
  disabled,
}: CheckboxGroupProps<NarrowCheckboxProps>) => {
  const handleChange = (e: any, value: any) => {
    onChange(
      e,
      checkboxes.map((checkbox) => ({
        ...checkbox,
        checked: String(checkbox.id) === e.target.id ? e.target.checked : checkbox.checked,
      })),
      value
    );
  };
  // Errors may be controlled at an individual checkbox level, but we still want to display
  // the error as a group
  const groupError = error || checkboxes.find((checkbox) => checkbox.error)?.error;
  return (
    <CheckboxGroupContainer error={!!groupError}>
      {label && (
        <FormLabel error={!!groupError} disabled={disabled}>
          {label}
        </FormLabel>
      )}
      {!flex && (
        <CheckboxGroupGridLayout>
          {checkboxes?.map((checkbox) => (
            <Checkbox
              key={checkbox.id}
              {...checkbox}
              onChange={handleChange}
              name={name}
              error={groupError}
              hideErrorLabel
              disabled={disabled}
            />
          ))}
        </CheckboxGroupGridLayout>
      )}
      {flex && (
        <CheckboxGroupFlex>
          {checkboxes?.map((checkbox) => (
            <Checkbox
              key={checkbox.id}
              {...checkbox}
              onChange={handleChange}
              error={groupError}
              name={name}
              hideErrorLabel
              disabled={disabled}
            />
          ))}
        </CheckboxGroupFlex>
      )}
      {groupError && <ErrorText>{groupError}</ErrorText>}
    </CheckboxGroupContainer>
  );
};
