export const INITIALIZE_ROO_ADMIN_ENTERPRISE_FLAGS = "INITIALIZE_ROO_ADMIN_ENTERPRISE_FLAGS";

export const GET_ENTERPRISE_LIST = "GET_ENTERPRISE_LIST";
export const GET_ENTERPRISE_LIST_SUCCESS = "GET_ENTERPRISE_LIST_SUCCESS";
export const GET_ENTERPRISE_LIST_FAIL = "GET_ENTERPRISE_LIST_FAIL";

export const GET_PENDING_HOSPITALS_FOR_ENTERPRISE = "GET_PENDING_HOSPITALS_FOR_ENTERPRISE";
export const GET_PENDING_HOSPITALS_FOR_ENTERPRISE_SUCCESS =
  "GET_PENDING_HOSPITALS_FOR_ENTERPRISE_SUCCESS";
export const GET_PENDING_HOSPITALS_FOR_ENTERPRISE_FAIL =
  "GET_PENDING_HOSPITALS_FOR_ENTERPRISE_FAIL";

export const REJECT_HOSPITAL_FOR_ENTERPRISE = "REJECT_HOSPITAL_FOR_ENTERPRISE";
export const REJECT_HOSPITAL_FOR_ENTERPRISE_SUCCESS = "REJECT_HOSPITAL_FOR_ENTERPRISE_SUCCESS";
export const REJECT_HOSPITAL_FOR_ENTERPRISE_FAIL = "REJECT_HOSPITAL_FOR_ENTERPRISE_FAIL";

export const GET_ENTERPRISE_DETAILS = "GET_ENTERPRISE_DETAILS";
export const GET_ENTERPRISE_DETAILS_SUCCESS = "GET_ENTERPRISE_DETAILS_SUCCESS";
export const GET_ENTERPRISE_DETAILS_FAIL = "GET_ENTERPRISE_DETAILS_FAIL";

export const CREATE_ENTERPRISE = "CREATE_ENTERPRISE";
export const CREATE_ENTERPRISE_SUCCESS = "CREATE_ENTERPRISE_SUCCESS";
export const CREATE_ENTERPRISE_FAIL = "CREATE_ENTERPRISE_FAIL";

export const SAVE_ENTERPRISE_LEAD = "SAVE_ENTERPRISE_LEAD";
export const SAVE_ENTERPRISE_LEAD_SUCCESS = "SAVE_ENTERPRISE_LEAD_SUCCESS";
export const SAVE_ENTERPRISE_LEAD_FAIL = "SAVE_ENTERPRISE_LEAD_FAIL";

export const SAVE_ENTERPRISE_HOSPITAL = "SAVE_ENTERPRISE_HOSPITAL";
export const SAVE_ENTERPRISE_HOSPITAL_SUCCESS = "SAVE_ENTERPRISE_HOSPITAL_SUCCESS";
export const SAVE_ENTERPRISE_HOSPITAL_FAIL = "SAVE_ENTERPRISE_HOSPITAL_FAIL";

export const SAVE_HOSPITAL_GROUP = "SAVE_HOSPITAL_GROUP";
export const SAVE_HOSPITAL_GROUP_SUCCESS = "SAVE_HOSPITAL_GROUP_SUCCESS";
export const SAVE_HOSPITAL_GROUP_FAIL = "SAVE_HOSPITAL_GROUP_FAIL";

export const SAVE_IMPORTED_HOSPITALS = "SAVE_IMPORTED_HOSPITALS";
export const SAVE_IMPORTED_HOSPITALS_SUCCESS = "SAVE_IMPORTED_HOSPITALS_SUCCESS";
export const SAVE_IMPORTED_HOSPITALS_FAIL = "SAVE_IMPORTED_HOSPITALS_FAIL";

export const SAVE_GROUP_HOSPITALS = "SAVE_GROUP_HOSPITALS";
export const SAVE_GROUP_HOSPITALS_SUCCESS = "SAVE_GROUP_HOSPITALS_SUCCESS";
export const SAVE_GROUP_HOSPITALS_FAIL = "SAVE_GROUP_HOSPITALS_FAIL";

export const SAVE_GROUP_ADMIN = "SAVE_GROUP_ADMIN";
export const SAVE_GROUP_ADMIN_SUCCESS = "SAVE_GROUP_ADMIN_SUCCESS";
export const SAVE_GROUP_ADMIN_FAIL = "SAVE_GROUP_ADMIN_FAIL";

// ---

export const RESET_ENTERPRISE_STATE = "RESET_ENTERPRISE_STATE";
export const CLOSE_FEEDBACK_RESEST_FLAGS = "CLOSE_FEEDBACK_RESEST_FLAGS";

export const DELETE_ENTERPRISE = "DELETE_ENTERPRISE";
export const DELETE_ENTERPRISE_SUCCESS = "DELETE_ENTERPRISE_SUCCESS";
export const DELETE_ENTERPRISE_FAIL = "DELETE_ENTERPRISE_FAIL";

export const DELETE_HOSPITAL_GROUP = "DELETE_HOSPITAL_GROUP";
export const DELETE_HOSPITAL_GROUP_SUCCESS = "DELETE_HOSPITAL_GROUP_SUCCESS";
export const DELETE_HOSPITAL_GROUP_FAIL = "DELETE_HOSPITAL_GROUP_FAIL";

export const UPDATE_HOSPITAL_GROUP_NAME = "UPDATE_HOSPITAL_GROUP_NAME";
export const UPDATE_HOSPITAL_GROUP_NAME_SUCCESS = "UPDATE_HOSPITAL_GROUP_NAME_SUCCESS";
export const UPDATE_HOSPITAL_GROUP_NAME_FAIL = "UPDATE_HOSPITAL_GROUP_NAME_FAIL";

export const DELETE_GROUP_HOSPITAL = "DELETE_GROUP_HOSPITAL";
export const DELETE_GROUP_HOSPITAL_SUCCESS = "DELETE_GROUP_HOSPITAL_SUCCESS";
export const DELETE_GROUP_HOSPITAL_FAIL = "DELETE_GROUP_HOSPITAL_FAIL";

export const DELETE_GROUP_ADMIN = "DELETE_GROUP_ADMIN";
export const DELETE_GROUP_ADMIN_SUCCESS = "DELETE_GROUP_ADMIN_SUCCESS";
export const DELETE_GROUP_ADMIN_FAIL = "DELETE_GROUP_ADMIN_FAIL";

export const DELETE_ENTERPRISE_HOSPITAL = "DELETE_ENTERPRISE_HOSPITAL";
export const DELETE_ENTERPRISE_HOSPITAL_SUCCESS = "DELETE_ENTERPRISE_HOSPITAL_SUCCESS";
export const DELETE_ENTERPRISE_HOSPITAL_FAIL = "DELETE_ENTERPRISE_HOSPITAL_FAIL";

export const GET_MY_HOSPITAL_GROUPS_LIST = "GET_MY_HOSPITAL_GROUPS_LIST";
export const GET_MY_HOSPITAL_GROUPS_LIST_SUCCESS = "GET_MY_HOSPITAL_GROUPS_LIST_SUCCESS";
export const GET_MY_HOSPITAL_GROUPS_LIST_FAIL = "GET_MY_HOSPITAL_GROUPS_LIST_FAIL";

export const UPDATE_ACCESS_OF_HOSPITAL_USER = "UPDATE_ACCESS_OF_HOSPITAL_USER";

export const GET_GROUP_MANAGE_USERS = "GET_GROUP_MANAGE_USERS";
export const GET_GROUP_MANAGE_USERS_SUCCESS = "GET_GROUP_MANAGE_USERS_SUCCESS";
export const GET_GROUP_MANAGE_USERS_FAIL = "GET_GROUP_MANAGE_USERS_FAIL";

export const REGISTER_ENTERPRISE_HOSPITAL_USER = "REGISTER_ENTERPRISE_HOSPITAL_USER";
export const REGISTER_ENTERPRISE_HOSPITAL_USER_SUCCESS =
  "REGISTER_ENTERPRISE_HOSPITAL_USER_SUCCESS";
export const REGISTER_ENTERPRISE_HOSPITAL_USER_FAIL = "REGISTER_ENTERPRISE_HOSPITAL_USER_FAIL";

export const REGISTER_ENTERPRISE_HOSPITAL = "REGISTER_ENTERPRISE_HOSPITAL";
export const REGISTER_ENTERPRISE_HOSPITAL_SUCCESS = "REGISTER_ENTERPRISE_HOSPITAL_SUCCESS";
export const REGISTER_ENTERPRISE_HOSPITAL_FAIL = "REGISTER_ENTERPRISE_HOSPITAL_FAIL";

export const UPDATE_HOSPITAL_ENTERPRISE_STATUS = "UPDATE_HOSPITAL_ENTERPRISE_STATUS";
export const UPDATE_HOSPITAL_ENTERPRISE_STATUS_SUCCESS =
  "UPDATE_HOSPITAL_ENTERPRISE_STATUS_SUCCESS";
export const UPDATE_HOSPITAL_ENTERPRISE_STATUS_FAIL = "UPDATE_HOSPITAL_ENTERPRISE_STATUS_FAIL";

export const UPDATE_HOSPITAL_INVOICED_STATUS = "UPDATE_HOSPITAL_INVOICED_STATUS";
export const UPDATE_HOSPITAL_INVOICED_STATUS_SUCCESS = "UPDATE_HOSPITAL_INVOICED_STATUS_SUCCESS";
export const UPDATE_HOSPITAL_INVOICED_STATUS_FAIL = "UPDATE_HOSPITAL_INVOICED_STATUS_FAIL";

export const GET_VET_ANALYTICS = "GET_VET_ANALYTICS";
export const GET_VET_ANALYTICS_SUCCESS = "GET_VET_ANALYTICS_SUCCESS";
export const GET_VET_ANALYTICS_FAIL = "GET_VET_ANALYTICS_FAIL";

export const CALCULATE_HOSPITAL_RATINGS = "CALCULATE_HOSPITAL_RATINGS";
export const CALCULATE_HOSPITAL_RATINGS_SUCCESS = "CALCULATE_HOSPITAL_RATINGS_SUCCESS";
export const CALCULATE_HOSPITAL_RATINGS_FAIL = "CALCULATE_HOSPITAL_RATINGS_FAIL";

export const RESET_ENTERPRISE_INVOICED_CUSTOMER = "RESET_ENTERPRISE_INVOICED_CUSTOMER";
export const GET_ENTERPRISE_INVOICED_CUSTOMER = "GET_ENTERPRISE_INVOICED_CUSTOMER";
export const GET_ENTERPRISE_INVOICED_CUSTOMER_SUCCESS = "GET_ENTERPRISE_INVOICED_CUSTOMER_SUCCESS";
export const GET_ENTERPRISE_INVOICED_CUSTOMER_FAIL = "GET_ENTERPRISE_INVOICED_CUSTOMER_FAIL";

export const UPDATE_ENTERPRISE_NAME = "UPDATE_ENTERPRISE_NAME";

export const UPDATE_ENTERPRISE_INVOICED_CUSTOMER = "UPDATE_ENTERPRISE_INVOICED_CUSTOMER";
export const UPDATE_ENTERPRISE_INVOICED_CUSTOMER_SUCCESS =
  "UPDATE_ENTERPRISE_INVOICED_CUSTOMER_SUCCESS";
export const UPDATE_ENTERPRISE_INVOICED_CUSTOMER_FAIL = "UPDATE_ENTERPRISE_INVOICED_CUSTOMER_FAIL";

export const BATCH_UPLOAD_HOSPITALS_REQUEST = "BATCH_UPLOAD_HOSPITALS_REQUEST";
export const BATCH_UPLOAD_HOSPITALS_SUCCESS = "BATCH_UPLOAD_HOSPITALS_SUCCESS";
export const BATCH_UPLOAD_HOSPITALS_FAIL = "BATCH_UPLOAD_HOSPITALS_FAIL";

export const GET_FORMATTED_HOSPITAL_UPLOAD_LIST = "GET_FORMATTED_HOSPITAL_UPLOAD_LIST";
export const GET_FORMATTED_HOSPITAL_UPLOAD_LIST_SUCCESS =
  "GET_FORMATTED_HOSPITAL_UPLOAD_LIST_SUCCESS";
export const GET_FORMATTED_HOSPITAL_UPLOAD_LIST_FAIL = "GET_FORMATTED_HOSPITAL_UPLOAD_LIST_FAIL";

export const RESET_BATCH_UPLOAD_FLAGS = "RESET_BATCH_UPLOAD_FLAGS";

export const UPDATE_INTERNAL_RELIEF_REQUEST = "UPDATE_INTERNAL_RELIEF_REQUEST";
export const UPDATE_INTERNAL_RELIEF_SUCCESS = "UPDATE_INTERNAL_RELIEF_SUCCESS";
export const UPDATE_INTERNAL_RELIEF_FAIL = "UPDATE_INTERNAL_RELIEF_FAIL";

export const UPDATE_ENTERPRISE_LOGO_REQUEST = "UPDATE_ENTERPRISE_LOGO_REQUEST";
export const UPDATE_ENTERPRISE_LOGO_SUCCESS = "UPDATE_ENTERPRISE_LOGO_SUCCESS";
export const UPDATE_ENTERPRISE_LOGO_FAIL = "UPDATE_ENTERPRISE_LOGO_FAIL";

export const DELETE_ENTERPRISE_LOGO_REQUEST = "DELETE_ENTERPRISE_LOGO_REQUEST";
export const DELETE_ENTERPRISE_LOGO_SUCCESS = "DELETE_ENTERPRISE_LOGO_SUCCESS";
export const DELETE_ENTERPRISE_LOGO_FAIL = "DELETE_ENTERPRISE_LOGO_FAIL";

export const GET_BLOCKED_CONTRACTORS_ENTERPRISE = "GET_BLOCKED_CONTRACTORS_ENTERPRISE";
export const GET_BLOCKED_CONTRACTORS_ENTERPRISE_SUCCESS =
  "GET_BLOCKED_CONTRACTORS_ENTERPRISE_SUCCESS";
export const GET_BLOCKED_CONTRACTORS_ENTERPRISE_FAIL = "GET_BLOCKED_CONTRACTORS_ENTERPRISE_FAIL";

export const UNBLOCK_CONTRACTOR_ENTERPRISE = "UNBLOCK_CONTRACTOR_ENTERPRISE";
export const UNBLOCK_CONTRACTOR_ENTERPRISE_SUCCESS = "UNBLOCK_CONTRACTOR_ENTERPRISE_SUCCESS";
export const UNBLOCK_CONTRACTOR_ENTERPRISE_FAIL = "UNBLOCK_CONTRACTOR_ENTERPRISE_FAIL";

export const BLOCK_CONTRACTOR_ENTERPRISE = "BLOCK_CONTRACTOR_ENTERPRISE";
export const BLOCK_CONTRACTOR_ENTERPRISE_SUCCESS = "BLOCK_CONTRACTOR_ENTERPRISE_SUCCESS";
export const BLOCK_CONTRACTOR_ENTERPRISE_FAIL = "BLOCK_CONTRACTOR_ENTERPRISE_FAIL";

export const UPDATE_BLOCK_CONTRACTOR_TYPE_ENTERPRISE = "UPDATE_BLOCK_CONTRACTOR_TYPE_ENTERPRISE";
