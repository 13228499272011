import styled from "styled-components";

import {
  BodyText,
  FormLabelText,
  blueDark600,
  blueLighter,
  checkboxBorderRadius,
  grayBase,
  grayWhite,
  redBase,
  spacingL,
  spacingM,
  spacingS,
  spacingXS,
} from "../../styles/constants";
import { smallMediaBreakpoint } from "../../styles/constants/mediaBreakpoints";
import { TwoColumnGrid } from "../Grid";

export interface CustomCheckLabelProps {
  disabled?: boolean;
  error?: boolean;
}

export interface InformationalDivProps {
  disabled?: boolean;
  checked?: boolean;
  size?: "small" | "large";
  error?: boolean;
}

export interface IconDivProps {
  icon: string;
}

export const CustomCheckLabel = styled.label<{
  disabled?: boolean;
  error?: boolean;
  topAlignedLabel?: boolean;
}>`
  display: grid;
  grid-template-columns: 16px auto;
  grid-gap: 8px;
  align-items: ${({ topAlignedLabel }) => (topAlignedLabel ? "start" : "center")};
  height: min-content;
  cursor: ${(props) => `${props.disabled ? "not-allowed" : "pointer"}`};
  ${BodyText};
  color: ${(props) => `${props.error ? redBase : grayBase}`};
  margin-bottom: 0;
  span:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div {
    grid-area: 2 / span 2;
  }
  p {
    margin: 0;
  }
`;

export const InputContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  -moz-appearance: none; /* Remove default firefox styling */
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  &[type="radio"] + span {
    border-radius: 50%;
  }
  &:checked + span {
    background: ${blueDark600};
  }
  &:disabled + span {
    background: ${grayWhite};
    border: 2px solid ${blueLighter};
  }
  &:checked:disabled + span {
    background: ${blueLighter};
    border: 2px solid ${blueLighter};
  }
`;

export const Control = styled.span<{ error: boolean; topAlignedLabel?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin-top: ${({ topAlignedLabel }) => (topAlignedLabel ? spacingXS : "0")};
  background: ${grayWhite};
  border: 2px solid ${blueDark600};
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: ${checkboxBorderRadius};
  border-color: ${(props) => (props.error ? redBase : blueDark600)};
`;

export const InformationalDiv = styled.div<InformationalDivProps>`
  display: inline-flex;
  height: ${(props) => `${props.size === "small" ? "40px" : "56px"}`};
  padding: ${spacingL};
  align-items: center;
  gap: ${spacingM};
  flex-shrink: 0;
  border-radius: 24px;
  cursor: ${(props) => `${props.disabled ? "not-allowed" : "pointer"}`};
  text-align: center;
  &.checked {
    ${(props) => {
      if (props.disabled) {
        return `
          background: ${blueLighter};
          border: 2px solid ${blueLighter};
        `;
      } else if (props.error) {
        return `
          background: ${grayWhite};
          border: 2px solid ${redBase};
        `;
      } else {
        return `
          background: ${blueDark600};
          border: 2px solid ${blueDark600};
        `;
      }
    }}
    div {
      color: ${grayWhite};
    }
  }
  &.unchecked {
    ${(props) => {
      if (props.disabled) {
        return `
          border: 2px solid ${blueLighter};
        `;
      } else if (props.error) {
        return `
          border: 2px solid ${redBase};
        `;
      } else {
        return `
          border: 2px solid ${blueDark600};
        `;
      }
    }}
    div {
      ${(props) => {
        if (props.disabled) {
          return `
          color: ${blueLighter};
        `;
        } else if (props.error) {
          return `
          color: ${redBase};
        `;
        } else {
          return `
          color: ${blueDark600};
        `;
        }
      }}
    }
  }
`;

export const IconLabel = styled.div<InformationalDivProps>`
  ${FormLabelText};
`;

export const CheckboxGroupFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacingL};
  align-items: center;
  @media (max-width: ${smallMediaBreakpoint}) {
    align-items: unset;
  }
`;

export const CheckboxGroupLabelContainer = styled.div<{ flex: boolean }>`
  margin-bottom: ${(props) => (props.flex ? spacingL : spacingM)};
`;

export const CheckboxGroupContainer = styled.div<{ error: boolean }>`
  ${(props) => {
    if (props.error) {
      return `
          span span {
            border-color: ${redBase}
          }
          label {
            color: ${redBase}
          }
        `;
    }
  }}
`;

export const CheckboxGroupGridLayout = styled(TwoColumnGrid)`
  @media (max-width: ${smallMediaBreakpoint}) {
    grid-template-columns: 1fr;
    gap: ${spacingS};
  }
`;
