import posthog from "posthog-js";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { loginRequestAction } from "../../../../Login/Action/LoginActions";
import { REGISTRATION_PATHS } from "../../../../RegistrationNew/Components/Common/service";
import { useGetStates } from "../../../../api/utility/getStates";
import {
  EventVetSignUpForm,
  VetPreferredAreasOfPractice,
  createVetDataAccount,
} from "../../../../api/vet";
import { autoApprove } from "../../../../api/vet/autoApprove";
import { getLicenseData } from "../../../../api/vet/getLicenseData";
import { patchVet } from "../../../../api/vet/patchVet";
import { VET_USER_TYPE } from "../../../../constants/UserTypeConstants";
import {
  IMPROVED_FILTER_APPOINTMENT_TYPES,
  IMPROVED_PREFERRED_PROCEDURES,
  idToFieldMap,
} from "../../../../constants/checkBoxConstants";
import { useAppDispatch, useAppSelector } from "../../../../store/service";
import { Event, EventTracker } from "../../../../tracking/service/EventTracker";
import { OnVetSignedUp } from "../common/EventSignUp";
import { useValidate } from "../common/useValidate";
import { getUtmParams } from "../common/util";

import { VetSignUp } from "./VetSignUp";
import { FormProvider } from "./useFormContext";

export const VetSignUpContainer = ({
  booth,
  onSuccess,
  eventSlug,
}: {
  booth: boolean;
  onSuccess: OnVetSignedUp;
  eventSlug: string;
}) => {
  const [defaultForm, setDefaultForm] = useState<EventVetSignUpForm>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { userId, userTypeId } = useAppSelector(({ login }) => login);
  const { data: states } = useGetStates();
  const isAutoApproved = useRef(false);
  const {
    utm_source: utmSource,
    utm_campaign: utmCampaign,
    utm_medium: utmMedium,
  } = getUtmParams(eventSlug, booth);
  const { validate } = useValidate();

  useEffect(() => {
    setDefaultForm({
      userTypeId: VET_USER_TYPE,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNumber: "",
      zipcode: "",
      reenterZipcode: "",
      travelDistance: 35,
      licenseState: null,
      licenseNumber: "",
      yearsOfPractice: null,
      lookingToStart: undefined,
      currentSituation: undefined,
      typicalAvailableDays: undefined,
      preferredAreasOfPractice: IMPROVED_FILTER_APPOINTMENT_TYPES.map(({ id, label }) => ({
        id,
        label,
      })),
      preferredProcedures: IMPROVED_PREFERRED_PROCEDURES.map((procedure) => ({
        id: procedure.id,
        label: procedure.label,
        name: "procedureTypeId",
      })),
      utmSource,
      utmCampaign,
      utmMedium,
    });
  }, [utmSource, utmCampaign, utmMedium]);

  useEffect(() => {
    if (userId && userTypeId === VET_USER_TYPE) {
      const pathname = isAutoApproved.current ? "/vet" : REGISTRATION_PATHS.Calendly;
      history.push({ pathname, search: "?fromEvent=1" });
    }
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  const save = async (form: EventVetSignUpForm) => {
    const { signInRequest, ...result } = await createVetDataAccount(form);

    if (!result.success) {
      throw new Error(result.error);
    }

    EventTracker.send(
      Event.Name.VET_CONFERENCE_EVENT_REGISTRATION_COMPLETE,
      Event.Type.CLICK,
      Event.Entity.EVENT,
      null,
      { eventSlug }
    );

    posthog.setPersonPropertiesForFlags({ email: form.email.toLowerCase() });

    const vetPreferredAreasOfPractice: VetPreferredAreasOfPractice =
      form.preferredAreasOfPractice.reduce((acc, curr) => {
        const key = idToFieldMap[Number(curr.id)];
        if (key) {
          acc[key] = curr.checked;
        }
        return acc;
      }, {} as VetPreferredAreasOfPractice);

    const updateResponse = await patchVet({
      userId: parseInt(result.data.userId),
      vetId: parseInt(result.data.vetId),
      preferredProcedures: form.preferredProcedures
        .filter((pp) => pp.checked)
        .map((pp) => Number(pp.id)),
      preferredAreasOfPractice: true,
      ...vetPreferredAreasOfPractice,
    });

    if (updateResponse.status_code === 200) {
      const userId = parseInt(result.data.userId);
      const { email: emailId, licenseNumber, licenseState, zipcode } = form;
      const state = states?.find(({ id }) => id === licenseState)?.state_name;

      const getLicenseDataResult = await getLicenseData({
        userId,
        licenseNumber,
        state,
        zipcode,
        // Use APV1 for all events for now. We may switch to APV2 soon.
        autoApprovalVersion: "v1",
      });

      if (getLicenseDataResult.success) {
        const autoApproveResult = await autoApprove({ userId, emailId });
        isAutoApproved.current = autoApproveResult.data.success;
      }
    }

    if (booth) {
      return onSuccess({ ...result.data, isAutoApproved: isAutoApproved.current });
    }

    const { email, password } = form;
    dispatch(loginRequestAction({ email, password, signInRequest }));
  };

  return (
    <FormProvider
      defaultForm={defaultForm}
      save={save}
      validate={(form: EventVetSignUpForm) => validate({ providerType: "vet", form })}
    >
      <VetSignUp booth={booth} eventSlug={eventSlug} />
    </FormProvider>
  );
};
