import styled from "styled-components";

import {
  BodyText,
  BoldBodyText,
  Color,
  MediumBodyText,
  SmallBodyText,
  SmallMediumBodyText,
  disabledTextColor,
  greenBase,
  greenDark600,
  redBase,
} from "../../styles/constants";

const colorExtension = ({
  color,
  disabled,
  error,
}: {
  color?: Color;
  disabled?: boolean;
  error?: boolean;
}) => {
  if (disabled) {
    return `color: ${disabledTextColor};`;
  } else if (error) {
    return `color: ${redBase};`;
  }
  return color ? `color: ${color};` : "";
};

export const Text = styled.p.attrs((props) => ({
  className: props.className,
}))`
  ${BodyText};
  margin-bottom: 0px !important;
  & a {
    ${BoldBodyText};
    color: ${greenBase} !important;
    text-decoration: underline !important;
    &:hover {
      color: ${greenDark600} !important;
    }
  }
  ${colorExtension}
`;

export const BoldText = styled.p.attrs((props) => ({
  className: props.className,
}))`
  ${BoldBodyText};
  margin-bottom: 0;
  ${colorExtension}
`;

export const MediumText = styled.p.attrs((props) => ({
  className: props.className,
}))`
  ${MediumBodyText};
  margin-bottom: 0;
  & a {
    ${BoldBodyText};
    color: ${greenBase} !important;
    text-decoration: underline !important;
    &:hover {
      color: ${greenDark600} !important;
    }
  }
  ${colorExtension}
`;

export const SmallText = styled.p`
  ${SmallBodyText};
  margin-bottom: 0;
  ${colorExtension}
`;

export const SmallMediumText = styled.p`
  ${SmallMediumBodyText}
  margin-bottom: 0;
  ${colorExtension}
`;
