import React, { FC } from "react";
import { UploadFile } from "antd/es/upload/interface";
import { RcFile } from "antd/lib/upload";

import { SmallText } from "../Text";
import { blueDark600, blueLight400 } from "../../styles/constants";
import { Icon } from "../Icon";
import { Loading } from "../Loading";

import { RequirementsContainer, StyledUpload, TextStyled, UploadButton } from "./DragAndDropStyled";

export interface DragAndDropPropTypes {
  maxSize?: number;
  label?: string;
  accept?: string;
  fileList?: UploadFile[];
  onRemove?: (removedFile: UploadFile) => void;
  onAdd?: (addedFile: File, addedFiles: File[]) => void;
  multiple?: boolean;
  listType?: "picture-circle" | "picture-card";
  onPreview?: (previewFile: UploadFile) => void;
  maxCount?: number;
  loading?: boolean;
}
export const DragAndDrop: FC<DragAndDropPropTypes> = ({
  accept,
  fileList,
  maxSize,
  label = "Click or drag and drop to add photo",
  onRemove,
  onAdd,
  multiple,
  listType = "picture-card",
  onPreview,
  maxCount,
  loading,
}) => {
  const acceptLabel = accept?.replaceAll(".", "").replaceAll("image/", "").toUpperCase();
  const beforeUpload = (file: UploadFile, files: UploadFile[]) => {
    if (onAdd) {
      onAdd(file as RcFile, files as RcFile[]);
    }

    return false;
  };
  return (
    <>
      <StyledUpload
        beforeUpload={beforeUpload}
        listType={listType}
        onRemove={onRemove}
        onPreview={(uploadFile: UploadFile) => (onPreview ? onPreview(uploadFile) : null)}
        fileList={fileList}
        accept={accept}
        multiple={multiple}
        maxCount={maxCount}
        data-testid="dropZone"
        iconRender={() => <Icon name="Description" color={blueDark600} />}
      >
        <UploadButton data-testid="uploadBtn">
          <Icon name="OutlineCloudUpload" size="xl" color={blueLight400} />
          <TextStyled>{label}</TextStyled>
          {loading === true ? (
            <Loading color={blueLight400} />
          ) : (
            <RequirementsContainer>
              {acceptLabel && <SmallText>Supported file types: {acceptLabel}</SmallText>}
              {maxSize && <SmallText>Maximum file size: {maxSize}MB</SmallText>}
            </RequirementsContainer>
          )}
        </UploadButton>
      </StyledUpload>
    </>
  );
};
