import React from "react";
import { Heading, Text } from "@RooUI";

import * as UserTypes from "../../constants/UserTypeConstants";
import { ROO_MAIL } from "../../constants/constants";
import RooLink from "../RooLink";

type PolicyTabType = {
  tabTitle: string;
  body: {
    title: string;
    content: {
      title?: string;
      paragraph?: React.ReactNode;
      bullets?: React.ReactNode[];
    }[];
  }[];
};

const mailLink = <a href={`mailto:${ROO_MAIL}`}>{ROO_MAIL}</a>;

export const hospitalCancellationPolicy: PolicyTabType = {
  tabTitle: "Cancellation Policy",
  body: [
    {
      title: "Requested, unconfirmed shifts",
      content: [
        {
          paragraph: (
            <Text>
              Please try to limit these cancellations as it provides a poor experience for our vets
              and techs. Consistently removing requested shifts may result in this feature being
              temporarily disabled. Up to two unconfirmed shifts may be removed per month without
              penalty. 
            </Text>
          ),
        },
      ],
    },
    {
      title: "Weekday shifts",
      content: [
        {
          paragraph: (
            <Text>
              If a hospital cancels a shift after it’s confirmed, the hospital will receive:
            </Text>
          ),
          bullets: [
            <li key="a">8+ weeks before shift – 100% refund </li>,
            <li key="a">6 to 8 weeks before shift – 75% refund </li>,
            <li key="b">4 to 6 weeks before shift – 50% refund</li>,
            <li key="c">2 to 4 weeks before shift – 25% refund </li>,
            <li key="d">Under 2 weeks before shift – no refund</li>,
          ],
        },
      ],
    },
    {
      title: "Weekend and holiday shifts",
      content: [
        {
          paragraph: (
            <Text>
              If a hospital cancels a shift after it’s confirmed, no refund will be issued.
            </Text>
          ),
        },
      ],
    },
    {
      title: "Vet/tech cancellations",
      content: [
        {
          paragraph: (
            <Text>
              Hospitals will not be penalized if a vet/tech cancels and will receive a full refund
              if the shift cannot be filled.
            </Text>
          ),
        },
      ],
    },
    {
      title: "Altering shifts after confirmation",
      content: [
        {
          paragraph: (
            <Text>
              Please contact us directly if you need to adjust a shift. If a hospital changes the
              duration or type of shift after it has been confirmed, cancellation fees may be
              applied.
            </Text>
          ),
        },
      ],
    },
    {
      title: "COVID-19",
      content: [
        {
          paragraph: (
            <Text>
              If somebody in your hospital tests positive for COVID-19, it is your responsibility to
              notify any Roo relief veterinarians or vet techs of possible exposure if they worked
              the same shift, and then inform Roo immediately at {mailLink}
            </Text>
          ),
          bullets: [
            <li key="a">
              Their contact information can be found in your confirmation email or on your user
              dashboard under confirmed shifts on the right hand side.
            </li>,
            <li key="b">
              You should provide the date and location of exposure but do not share who tested
              positive.
            </li>,
            <div key="spacer" className="spacer"></div>,
          ],
        },
        {
          paragraph: (
            <Text>
              If somebody in your hospital tests positive for COVID-19 and you are not closing the
              clinic, it is also your responsibility to inform any upcoming Roo relief veterinarians
              or vet techs of potential exposure, and then inform Roo at {mailLink}
            </Text>
          ),
          bullets: [
            <li key="a">
              It is your responsibility to determine whether booked vets and techs are comfortable
              visiting your hospital within the next 2-5 days.
            </li>,
            <div key="spacer" className="spacer"></div>,
          ],
        },
        {
          paragraph: (
            <Text>
              If your hospital is forced to close because of a positive case, it is your
              responsibility to inform any affected Roo relief veterinarians or vet techs that their
              shifts will be cancelled, and then inform Roo at {mailLink}
            </Text>
          ),
          bullets: [
            <li key="a">
              If the hospital closes less than 48 hours after the start of the affected shift, the
              vet or vet tech will be compensated at the hospital’s expense.
            </li>,
            <li key="b">
              For any cancelled shifts more than 48 hours after closure, the hospital will be
              refunded the full shift amount.
            </li>,
          ],
        },
      ],
    },
  ],
};

const vetAndTechCancellationPolicy = [
  {
    title: "Confirmed shifts - first cancellation",
    content: [
      {
        paragraph: (
          <Text>
            You are permitted one cancellation per 30 days as long as the shift is at least 4 days
            away.
          </Text>
        ),
      },
    ],
  },
  {
    title: "Confirmed shifts - second cancellation",
    content: [
      {
        bullets: [
          <>
            <li>
              A second cancellation within a 30 day period (starting from the date of the first
              cancellation) may result in your account being restricted and from requesting new
              shifts until you speak with your Account Manager
            </li>
            <li>
              Please note: you will still keep any confirmed shifts and should complete those as you
              normally would.
            </li>
          </>,
        ],
      },
    ],
  },
  {
    title: "Confirmed shifts - third cancellation",
    content: [
      {
        paragraph: (
          <Text>
            A third cancellation within this time period may result in your account being suspended
            for 30 days (starting from the date of the third cancellation). During this time, you
            won't be able to request new shifts, and you'll be removed from any confirmed shifts
            within this 30-day suspension period.
          </Text>
        ),
      },
    ],
  },
  {
    title: "No-call/no-shows",
    content: [
      {
        paragraph: (
          <Text>
            A no-call/no-show is when you don't show up for a shift and don't contact the
            hospital/Roo within a 24 hour grace-period after the shift start time to explain your
            absence. This will not be tolerated and can result in your suspension or removal from
            the Roo platform.
          </Text>
        ),
      },
    ],
  },
];

const vetCancellationPolicy: PolicyTabType = {
  tabTitle: "Cancellation Policy",
  body: [
    {
      title: "Cancelling a confirmed shift",
      content: [
        {
          paragraph: (
            <Text>
              To cancel a shift, please find the shift you'd like to cancel and use the "Cancel
              Shift" link. Keep in mind, calling out can leave the hospital without a vet for the
              day, which can create problems for the hospital and hurt client relationships.
              Therefore, we limit the number of cancellations allowed over a 30-day period.
            </Text>
          ),
        },
      ],
    },
    ...vetAndTechCancellationPolicy,
  ],
};

const techCancellationPolicy: PolicyTabType = {
  tabTitle: "Cancellation Policy",
  body: [
    {
      title: "Cancelling a confirmed shift",
      content: [
        {
          paragraph: (
            <Text>
              To cancel a shift, please find the shift you'd like to cancel and use the "Cancel
              Shift" link. Keep in mind, calling out can leave the hospital without a tech for the
              day, which can create problems for the hospital and hurt client relationships.
              Therefore, we limit the number of cancellations allowed over a 30-day period.
            </Text>
          ),
        },
      ],
    },
    ...vetAndTechCancellationPolicy,
  ],
};

export const codeOfConduct: PolicyTabType = {
  tabTitle: "Code of Conduct",
  body: [
    {
      title: "",
      content: [
        {
          paragraph: (
            <>
              <Text>
                We believe everyone in the Roo community should feel welcomed and supported. That’s
                why we created a code of conduct to help users like you feel comfortable while using
                our platform and help hospitals and their clients feel safe. Please conduct yourself
                in a professional manner, arrive on time, and dress appropriately. If you have to
                miss your shift for any reason, please call the hospital directly at least 24 hours
                in advance, do not notify them via chat. No-shows will not be tolerated and could
                result in your suspension from the platform.
              </Text>
              <div className="spacer"></div>
            </>
          ),
        },
        {
          paragraph: (
            <Text>
              You can read our full{" "}
              <a
                href="https://www.roo.vet/code-of-conduct"
                target="_blank"
                className="primary-color"
                rel="noreferrer"
              >
                Roo Code Of Conduct
              </a>{" "}
              here.
            </Text>
          ),
        },
      ],
    },
  ],
};

export const hospitalPermanentPlacement: PolicyTabType = {
  tabTitle: "Full-Time Placement",
  body: [
    {
      title: "General veterinarian rates",
      content: [
        {
          paragraph: <Text> 20% of first year base salary. This is not to exceed $40,000.00.</Text>,
        },
      ],
    },
    {
      title: "General technician rates",
      content: [
        {
          paragraph: (
            <Text>
              A fee of $1,000 per tech tier (so a tier 4 tech would cost the hospital $4,000).
            </Text>
          ),
        },
      ],
    },
    {
      title: "Full-time placement policies",
      content: [
        {
          paragraph: (
            <>
              <Text>
                We are thrilled you found a veterinarian or technician on Roo that you’d like to
                work with on a permanent basis. Roo requires a two week written notice for a
                full-time placement emailed to {mailLink}. If your hospital has shifts scheduled
                with the doctor or technician you are interested in prior to two weeks, Roo will
                still collect those fees and pay out the veterinarian or technician accordingly.
                Anything after two weeks we will refund free of charge.
              </Text>
              <div className="spacer" />
            </>
          ),
        },
        {
          paragraph: (
            <>
              <Text>
                {" "}
                Your Roo Business Development Representative or Account Manager will generate an
                invoice for you which is due upon receipt. In order to verify full-time placement
                fees, we may request an offer letter from you, the veterinarian or technician in
                order to confirm the amount you are invoiced.
              </Text>
              <div className="spacer" />
            </>
          ),
        },
        {
          paragraph: (
            <Text>
              If you have any further questions about full-time placement fees, please refer back to
              our Terms of Service or contact your Business Development Representative or Account
              Manager.
            </Text>
          ),
        },
      ],
    },
  ],
};

export const generatePoliciesPageStructure = (userType: number) => {
  if (userType === UserTypes.HOSPITAL_USER_TYPE) {
    return [hospitalCancellationPolicy, codeOfConduct, hospitalPermanentPlacement];
  } else if (userType === UserTypes.TECH_USER_TYPE) {
    return [techCancellationPolicy, codeOfConduct];
  } else {
    return [vetCancellationPolicy, codeOfConduct];
  }
};

export const generateHospitalSidePanelCancellationPolicies = () => {
  const body: PolicyTabType["body"] = [
    {
      title: "",
      content: [
        {
          paragraph: (
            <div className="first-paragraph">
              <Heading level={3}>
                Need to cancel this shift? Please review our cancellation policies below.
              </Heading>
              <span>
                Please note, submitting a cancellation request does not guarantee shift removal
                until confirmed by Roo.
              </span>
              <div />
              <RooLink
                onClick={() => {
                  window.open("/policies", "_blank");
                }}
                className="primary-color"
                title="See all policies"
              />
            </div>
          ),
        },
      ],
    },
    ...hospitalCancellationPolicy.body.slice(1),
  ];

  return { ...hospitalCancellationPolicy, body };
};
