import * as C from "../../constants/enterpriseConstants";
import { SET_SESSION_REQUEST, UNSET_ENTERPRISE_HOSPITAL_SESSION } from "../../constants/Login";

export function getEnterpriseList(data) {
  return {
    type: C.GET_ENTERPRISE_LIST,
    payload: data,
  };
}

export function getEnterpriseDetails(data) {
  return {
    type: C.GET_ENTERPRISE_DETAILS,
    payload: data,
  };
}

export function saveEnterpriseHospital(data) {
  return {
    type: C.SAVE_ENTERPRISE_HOSPITAL,
    payload: data,
  };
}

export function saveHospitalGroup(data) {
  return {
    type: C.SAVE_HOSPITAL_GROUP,
    payload: data,
  };
}

export function saveHospitalGroupHospital(data) {
  return {
    type: C.SAVE_GROUP_HOSPITALS,
    payload: data,
  };
}

export function saveHospitalGroupAdmin(data) {
  return {
    type: C.SAVE_GROUP_ADMIN,
    payload: data,
  };
}

export function setEnterpriseHospitalSession(data) {
  return {
    type: SET_SESSION_REQUEST,
    payload: data,
  };
}

export function unsetEnterpriseHospitalSession() {
  return {
    type: UNSET_ENTERPRISE_HOSPITAL_SESSION,
  };
}

export function getGroupManageUsers(data) {
  return {
    type: C.GET_GROUP_MANAGE_USERS,
    payload: data,
  };
}

export function getMyHospitalGroupDetails(data) {
  return {
    type: C.GET_MY_HOSPITAL_GROUPS_LIST,
    payload: data,
  };
}

export function closeFeedbackResetFlages() {
  return {
    type: C.CLOSE_FEEDBACK_RESEST_FLAGS,
  };
}

export function deleteHospitalGroup(data) {
  return {
    type: C.DELETE_HOSPITAL_GROUP,
    payload: data,
  };
}

export function updateHospitalGroupName(data) {
  return {
    type: C.UPDATE_HOSPITAL_GROUP_NAME,
    payload: data,
  };
}

export function deleteGroupHospital(data) {
  return {
    type: C.DELETE_GROUP_HOSPITAL,
    payload: data,
  };
}

export function deleteGroupAdmin(data) {
  return {
    type: C.DELETE_GROUP_ADMIN,
    payload: data,
  };
}

export function deleteEnterpriseHospital(data) {
  return {
    type: C.DELETE_ENTERPRISE_HOSPITAL,
    payload: data,
  };
}

export function updateHospitalEnterpriseStatus(data) {
  return {
    type: C.UPDATE_HOSPITAL_ENTERPRISE_STATUS,
    payload: data,
  };
}

export function updateHospitalInvoicedStatus(data) {
  return {
    type: C.UPDATE_HOSPITAL_INVOICED_STATUS,
    payload: data,
  };
}

export function getVetAnalyticsData(data) {
  return {
    type: C.GET_VET_ANALYTICS,
    payload: data,
  };
}

export function calculateHospitalRatings(data) {
  return {
    type: C.CALCULATE_HOSPITAL_RATINGS,
    payload: data,
  };
}

export function getEnterpriseInvoicedCustomer(data) {
  return {
    type: C.GET_ENTERPRISE_INVOICED_CUSTOMER,
    payload: data,
  };
}

export function resetEnterpriseInvoicedCustomer() {
  return {
    type: C.RESET_ENTERPRISE_INVOICED_CUSTOMER,
  };
}

export function updateEnterpriseInvoicedCustomer(data) {
  return {
    type: C.UPDATE_ENTERPRISE_INVOICED_CUSTOMER,
    payload: data,
  };
}

export function getBlockedContractorsEnterprise(data) {
  return {
    type: C.GET_BLOCKED_CONTRACTORS_ENTERPRISE,
    payload: data,
  };
}

export function unblockContractorEnterprise(data) {
  return {
    type: C.UNBLOCK_CONTRACTOR_ENTERPRISE,
    payload: data,
  };
}

export function blockContractorEnterprise(data) {
  return {
    type: C.BLOCK_CONTRACTOR_ENTERPRISE,
    payload: data,
  };
}

export function updateBlockContractorTypeEnterprise(data) {
  return {
    type: C.UPDATE_BLOCK_CONTRACTOR_TYPE_ENTERPRISE,
    payload: data,
  };
}
