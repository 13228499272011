import { cloneDeep } from "lodash";

import { getISODateToday } from "../../Common/dateUtils";
import { processShiftDBdata, processTechShiftDBdata } from "../../Common/shiftDataHelper";
import * as calendarConstants from "../../constants/calendar";
import * as C from "../../constants/hospitalConstants";
import { SHOW_TRAINING_POPUP, UNSET_ENTERPRISE_HOSPITAL_SESSION } from "../../constants/Login";

const initialState = {
  commonData: {
    appointmentType: [],
    procedureType: [],
  },
  selectedFederalHolidays: [],
  isBulkShiftPricingFetched: false,
  bulkShiftPricing: [],
  shiftFullfillmentPrediction: {},
  isFetchingShiftPrediction: false,
  threeMonthShiftHistory: {},
  currentCalendarDate: null,
  status: "",
  vetShiftsFullData: [],
  vetShiftsTrimmedData: [],
  shiftUpdated: false,
  previousShifts: [],
  billingModal: false,
  isShowProfilePanel: false,
  profilePanelData: null,
  isPendingVetRatings: false,
  isPendingTechRatings: false,
  shiftConfirmed: false,
  shiftDetails: null,
  showModal: false,
  isShiftConfirmSuccessClosed: false,
  isVetConfirmedError: false,
  errorTypeConfirmingShift: null,
  defaultHospVetRatingDetails: [],
  notificationShiftCount: null,
  isNotificationModalClosed: false,
  pendingReviewData: [],
  // --- tech related
  techShiftsFullData: [],
  techShiftsTrimmedData: [],
  isInitialTechShiftsFetched: false,
  techShiftUpdated: false,
  previousTechShifts: [],
  isTechShiftConfirmed: false,
  techShiftDetails: null,
  isTechConfirmedError: false,
  defaultHospTechRatingDetails: [],
  notificationTechShiftCount: null,
  downloadedPDFStatus: 0,
  isRequested: false,
  techPendingReviewData: [],
  isFetchingTechReviews: false,
  isFetchingVetReviews: false,
  // --- end tech related
  // for set up billing modal
  confirmVetShiftGroupId: null,
  confirmVetId: null,
  confirmTechShiftGroupId: null,
  confirmTechId: null,
  // end for set up billing modal
  isHireRequestSuccess: false,
  isHireRequestError: false,
  hireRequestErrorMessage: "",
  isShowTrainingPopup: false,
  isGetVetRatingsCompleted: false,
  isGetTechRatingCompleted: false,
  isRemoveConfirmedTechActionCompleted: false,
  isError: false,
  removeConfirmedTechErrorMsg: "",
  isReloadTechShifts: false,
  overtimeRequestStatus: null,
  isVetShiftBoosted: null,
  isTechShiftBoosted: null,
  isModalOpen: false,
  openChat: false,
  addShiftFail: false,
  addTechShiftFail: false,
  cancelledShiftsId: [],
};

// eslint-disable-next-line default-param-last
const hospitalReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNSET_ENTERPRISE_HOSPITAL_SESSION:
      return {
        ...initialState,
      };

    case C.FETCH_ADD_SHIFT_COMMON_DATA_REQUEST:
      return {
        ...state,
        commonData: {
          ...state.commonData,
        },
      };

    case C.FETCH_ADD_SHIFT_COMMON_DATA_SUCCESS:
      return {
        ...state,
        commonData: {
          ...state.commonData,
          appointmentType: action.payload.commonData.appointmentType.data,
          procedureType: action.payload.commonData.procedureType.data,
          shiftPriceIncreaseAmounts: action.payload.commonData.shiftPriceIncreaseAmounts.data,
          techShiftPriceIncreaseAmounts:
            action.payload.commonData.techShiftPriceIncreaseAmounts.data,
        },
        shiftUpdated: false,
        techShiftUpdated: false,
      };

    case C.FETCH_ADD_SHIFT_COMMON_DATA_FAIL:
      return {
        ...state,
        ...action.payload,
      };

    case C.FETCH_HOLIDAYS:
    case C.RESET_HOLIDAYS:
      return {
        ...state,
        selectedFederalHolidays: [],
      };

    case C.FETCH_HOLIDAYS_SUCCESS:
    case C.FETCH_HOLIDAYS_FAIL:
      return {
        ...state,
        selectedFederalHolidays: action.payload.holidayList,
      };

    case C.CALCULATE_BULK_SHIFT_PRICING:
      return {
        ...state,
        isBulkShiftPricingFetched: false,
        bulkShiftPricing: action.payload.isConcat ? state.bulkShiftPricing : [],
      };

    case C.CALCULATE_BULK_SHIFT_PRICING_SUCCESS:
      return {
        ...state,
        isBulkShiftPricingFetched: true,
        bulkShiftPricing: action.payload.isConcat
          ? [...state.bulkShiftPricing, ...action.payload.pricing]
          : action.payload.pricing,
      };

    case C.FETCH_PREVIOUS_SHIFT_SUCCESS:
      return {
        ...state,
        previousShifts:
          action.payload.previousShifts.data !== undefined
            ? [...action.payload.previousShifts.data]
            : [],
      };

    case C.FETCH_PREVIOUS_SHIFT_FAIL:
      return {
        ...state,
        ...action.payload,
        previousShifts: [],
      };

    case C.GET_SHIFT_FULLFILLMENT_PREDICTION:
      return {
        ...state,
        shiftFullfillmentPrediction: {},
        isFetchingShiftPrediction: true,
      };

    case C.GET_SHIFT_FULLFILLMENT_PREDICTION_FAIL:
      return {
        ...state,
        shiftFullfillmentPrediction: {},
        isFetchingShiftPrediction: false,
      };

    case C.GET_SHIFT_FULLFILLMENT_PREDICTION_SUCCESS:
      const { probOfRequest, top3PositiveFactors, top3NegativeFactors } = action.payload;
      return {
        ...state,
        shiftFullfillmentPrediction: {
          ...state.shiftFullfillmentPrediction,
          predictionPercent: probOfRequest,
          top3PositiveFactors,
          top3NegativeFactors,
        },
        isFetchingShiftPrediction: false,
      };

    case C.ADD_SHIFT_SUCCESS:
      return {
        ...state,
        shiftUpdated: true,
        recentlyAddedShiftGroupId: action?.payload?.shiftGroupId,
      };

    case C.EDIT_SHIFT_SUCCESS:
      return {
        ...state,
        shiftUpdated: true,
      };

    case C.RESET_SHIFT_UPDATED_FLAG:
      return {
        ...state,
        shiftUpdated: false,
      };

    case C.SHOW_HOSPITAL_BILLING_MODAL:
      // localstorage to account for refresh at or befor all done screen, of first confirmation
      if (action.payload.shiftGroupId) {
        localStorage.setItem("confirmVetShiftGroupId", action.payload.shiftGroupId);
        localStorage.setItem("confirmVetId", action.payload.vetId);
      } else if (action.payload.techShiftGroupId) {
        localStorage.setItem("confirmTechShiftGroupId", action.payload.techShiftGroupId);
        localStorage.setItem("confirmTechId", action.payload.techId);
      }
      localStorage.setItem("isFromMobile", action.payload.isFromMobile);
      return {
        ...state,
        confirmVetShiftGroupId: action.payload.shiftGroupId,
        confirmVetId: action.payload.vetId,
        confirmTechShiftGroupId: action.payload.techShiftGroupId,
        confirmTechId: action.payload.techId,
        billingModal: true,
      };

    case C.HIDE_HOSPITAL_BILLING_MODAL:
      return {
        ...state,
        billingModal: false,
      };

    case C.SHOW_PROFILE_PANEL:
      return {
        ...state,
        isShowProfilePanel: true,
        profilePanelData: cloneDeep(action.payload),
        openChat: action.payload?.openChat,
      };

    case C.HIDE_PROFILE_PANEL:
      return {
        ...state,
        isShowProfilePanel: false,
        profilePanelData: null,
        openChat: false,
      };

    case C.GET_HOSPITAL_PENDING_VET_RATING_REQUEST: {
      return { ...state, isFetchingVetReviews: true };
    }

    case C.GET_HOSPITAL_PENDING_VET_RATING_SUCCESS: {
      const data = {
        ...state.defaultHospVetRatingDetails,
        shiftDate: action.payload.defaultHospVetRatingDetails.shiftDate,
        vetName: action.payload.defaultHospVetRatingDetails.vetName,
        ratingId: action.payload.defaultHospVetRatingDetails.ratingId,
        deferredCount: action.payload.defaultHospVetRatingDetails.deferredCount,
        defaultRatingTotalCount: action.payload.defaultRatingTotalCount,
        vetImage: action.payload.defaultHospVetRatingDetails.vetImage,
        vetId: action.payload.defaultHospVetRatingDetails.vetId,
        dontWorkAgainCountForVet:
          action.payload.defaultHospVetRatingDetails.dontWorkAgainCountForVet,
        isBlocked: action.payload.defaultHospVetRatingDetails.isBlocked,
      };
      const isReviewsReloaded =
        state?.defaultHospVetRatingDetails?.totalPendingRatingCount -
          (state?.defaultHospVetRatingDetails?.currentPendingVetRatingIndex - 1) !=
        action.payload.defaultRatingTotalCount
          ? true
          : false;
      if (!state?.defaultHospVetRatingDetails?.isMoreReviews || isReviewsReloaded) {
        data.isMoreReviews = true;
        data.totalPendingRatingCount = action.payload.defaultRatingTotalCount;
        data.currentPendingVetRatingIndex = 1;
      }
      return {
        ...state,
        isPendingVetRatings: true,
        isGetVetRatingsCompleted: true,
        isFetchingVetReviews: false,
        defaultHospVetRatingDetails: { ...data },
      };
    }

    case C.GET_HOSPITAL_PENDING_VET_RATING_FAIL: {
      return {
        ...state,
        defaultHospVetRatingDetails: {},
        isPendingVetRatings: false,
        isGetVetRatingsCompleted: true,
        isFetchingVetReviews: false,
      };
    }

    case C.UPDATE_CURRENT_VET_RATING_INDEX: {
      return {
        ...state,
        defaultHospVetRatingDetails: {
          ...state.defaultHospVetRatingDetails,
          currentPendingVetRatingIndex:
            state.defaultHospVetRatingDetails.currentPendingVetRatingIndex + 1,
        },
      };
    }

    case C.UPDATE_CURRENT_TECH_RATING_INDEX: {
      return {
        ...state,
        defaultHospTechRatingDetails: {
          ...state.defaultHospTechRatingDetails,
          currentPendingTechRatingIndex:
            state.defaultHospTechRatingDetails.currentPendingTechRatingIndex + 1,
        },
      };
    }

    case C.GET_VET_SHIFTS: {
      return {
        ...state,
        vetShiftsTrimmedData: [],
      };
    }

    case calendarConstants.INITIALIZE_STATE: {
      return {
        ...initialState,
        commonData: { ...initialState.commonData },
      };
    }

    case C.GET_VET_SHIFTS_SUCCESS: {
      const oShiftData = processShiftDBdata(
        action.payload.oShiftsDB,
        action.payload.isShiftPostsExpire
      );
      return {
        ...state,
        vetShiftsFullData: oShiftData.fullShiftListData,
        vetShiftsTrimmedData: oShiftData.trimmedShiftListData,
        currentCalendarDate: action.currentCalendarDate,
        shiftConfirmed: false,
      };
    }

    case calendarConstants.RENEW_SHIFT_SUCCESS:
      const updatedShiftsTrimmedData = state.vetShiftsTrimmedData.map((o) => {
        if (o.shiftId === action.payload.shiftId) {
          const latestShiftAmount = action.payload.shiftAmount
            ? action.payload.shiftAmount
            : o.shiftAmount || o.price;
          return {
            ...o,
            price: latestShiftAmount,
            shiftAmount: latestShiftAmount,
            lastRenewShiftDate: getISODateToday(),
            postingExpiresOn: getISODateToday(),
            title: "Posted",
            isPostExpiresSoon: false,
            isPostExpired: false,
          };
        } else {
          return { ...o };
        }
      });
      const updatedShiftsFullData = state.vetShiftsFullData.map((o) => {
        if (o.shiftId === action.payload.shiftId) {
          const latestShiftAmount = action.payload.shiftAmount
            ? action.payload.shiftAmount
            : o.shiftAmount || o.price;
          return {
            ...o,
            price: latestShiftAmount,
            shiftAmount: latestShiftAmount,
            lastRenewShiftDate: getISODateToday(),
            isPostExpiresSoon: false,
            isPostExpired: false,
          };
        } else {
          return { ...o };
        }
      });
      return {
        ...state,
        vetShiftsTrimmedData: updatedShiftsTrimmedData,
        vetShiftsFullData: updatedShiftsFullData,
      };

    case C.MOBILE_CONFIRM_VET_SUCCESS: {
      return {
        ...state,
        shiftConfirmed: true,
        showModal: true,
        isVetConfirmedError: false,
        errorTypeConfirmingShift: null,
      };
    }

    case C.MOBILE_CONFIRM_VET_FAIL: {
      return {
        ...state,
        showModal: true,
        isVetConfirmedError: true,
        errorTypeConfirmingShift: action.payload.message,
      };
    }

    case C.FETCH_SHIFT_DETAILS_SUCCESS: {
      return {
        ...state,
        shiftDetails: action.payload[0],
      };
    }

    case C.INITIALIZE_ADD_SHIFT_STATE: {
      return {
        ...state,
        shiftDetails: null,
      };
    }

    case C.CLOSE_SHIFT_ACTION_RESULT_MESSAGE_MODAL: {
      return {
        ...state,
        showModal: false,
        isShiftConfirmSuccessClosed: true, // flag to start training if other criteria met
      };
    }

    case C.INITIALIZE_RATING_MODAL_FLAG: {
      return {
        ...initialState,
        isPendingVetRatings: false,
        isPendingTechRatings: false,
      };
    }

    case C.FETCH_SHIFT_COUNT_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        notificationShiftCount: action.payload.shiftCount,
      };
    }

    case C.NOTIFICATION_MODAL_CLOSE: {
      return {
        ...state,
        isNotificationModalClosed: true,
      };
    }

    case C.NOTIFICATION_MODAL_OPEN: {
      return {
        ...state,
        isNotificationModalClosed: false,
      };
    }
    // --- tech related

    case C.GET_TECH_SHIFTS:
      return {
        ...state,
        techShiftsTrimmedData: [],
      };

    case C.GET_TECH_SHIFTS_SUCCESS:
      const oTechShiftsData = processTechShiftDBdata(action.payload);
      return {
        ...state,
        techShiftsFullData: oTechShiftsData.fullShiftListData,
        techShiftsTrimmedData: oTechShiftsData.trimmedShiftListData,
        isTechShiftConfirmed: false,
        isInitialTechShiftsFetched: true,
      };

    case C.FETCH_PREVIOUS_TECH_SHIFT_SUCCESS:
      return {
        ...state,
        previousTechShifts:
          action.payload.previousTechShifts.data !== undefined
            ? [...action.payload.previousTechShifts.data]
            : [],
      };

    case C.FETCH_PREVIOUS_TECH_SHIFT_FAIL:
      return {
        ...state,
        ...action.payload,
        previousTechShifts: [],
      };

    case C.FETCH_ADD_TECH_SHIFT_SUCCESS:
      return {
        ...state,
        techShiftUpdated: true,
        recentlyAddedTechShiftGroupId: action?.payload?.shiftGroupId,
      };

    case C.EDIT_TECH_SHIFT_REQUEST_SUCCESS:
      return {
        ...state,
        techShiftUpdated: true,
      };

    case C.ADD_SHIFT_FAIL:
      return {
        ...state,
        addShiftFail: true,
      };

    case C.FETCH_ADD_TECH_SHIFT_FAIL:
      return {
        ...state,
        addTechShiftFail: true,
      };

    case C.RESET_ADD_SHIFT_FAIL:
      return {
        ...state,
        addShiftFail: false,
      };

    case C.RESET_ADD_TECH_SHIFT_FAIL:
      return {
        ...state,
        addTechShiftFail: false,
      };

    case C.RESET_TECH_SHIFT_UPDATED_FLAG:
      return {
        ...state,
        techShiftUpdated: false,
      };

    case C.UPDATE_REQUESTED_SHIFTS_LOCAL:
      return {
        ...state,
        techShiftsFullData: [...state.techShiftsFullData],
        techShiftsTrimmedData: [...state.techShiftsTrimmedData],
        vetShiftsFullData: [...state.vetShiftsFullData],
        vetShiftsTrimmedData: [...state.vetShiftsTrimmedData],
      };

    case C.GET_HOSPITAL_PENDING_TECH_RATING_REQUEST: {
      return { ...state, isFetchingTechReviews: true };
    }

    case C.GET_HOSPITAL_PENDING_TECH_RATING_SUCCESS: {
      const data = {
        ...state.defaultHospTechRatingDetails,
        shiftDate: action.payload.defaultHospTechRatingDetails.shiftDate,
        techName: action.payload.defaultHospTechRatingDetails.techName,
        ratingId: action.payload.defaultHospTechRatingDetails.ratingId,
        deferredCount: action.payload.defaultHospTechRatingDetails.deferredCount,
        defaultRatingTotalCount: action.payload.defaultRatingTotalCount,
        techEmailId: action.payload.techEmailId,
        probationStatus: action.payload.probationStatus,
        techImage: action.payload.defaultHospTechRatingDetails.techImage,
        techId: action.payload.defaultHospTechRatingDetails.techId,
        dontWorkAgainCountForTech:
          action.payload.defaultHospTechRatingDetails.dontWorkAgainCountForTech,
        isBlocked: action.payload.defaultHospTechRatingDetails.isBlocked,
        lastSelectedWorkAgain: action.payload.defaultHospTechRatingDetails.lastSelectedWorkAgain,
      };
      const isReviewsReloaded =
        state?.defaultHospTechRatingDetails?.totalPendingRatingCount -
          (state?.defaultHospTechRatingDetails?.currentPendingTechRatingIndex - 1) !=
        action.payload.defaultRatingTotalCount
          ? true
          : false;
      if (!state?.defaultHospTechRatingDetails?.isMoreReviews || isReviewsReloaded) {
        data.isMoreReviews = true;
        data.totalPendingRatingCount = action.payload.defaultRatingTotalCount;
        data.currentPendingTechRatingIndex = 1;
      }
      return {
        ...state,
        isPendingTechRatings: true,
        isGetTechRatingCompleted: true,
        isFetchingTechReviews: false,
        defaultHospTechRatingDetails: { ...data },
      };
    }

    case C.GET_HOSPITAL_PENDING_TECH_RATING_FAIL: {
      return {
        ...state,
        defaultHospTechRatingDetails: {},
        isPendingTechRatings: false,
        isGetTechRatingCompleted: true,
        isFetchingTechReviews: false,
      };
    }

    case C.HOSPITAL_CONFIRM_TECH_SUCCESS: {
      return {
        ...state,
        isTechShiftConfirmed: true,
        showModal: true,
        isTechConfirmedError: false,
        errorTypeConfirmingShift: null,
      };
    }

    case C.HOSPITAL_CONFIRM_TECH_FAIL: {
      return {
        ...state,
        showModal: true,
        isTechConfirmedError: true,
        errorTypeConfirmingShift: action.payload.message,
      };
    }

    case C.FETCH_TECH_SHIFT_DETAILS_FOR_EDIT_SUCCESS: {
      return {
        ...state,
        techShiftDetails: action.payload[0],
      };
    }

    case C.INITIALIZE_ADD_TECH_SHIFT_STATE: {
      return {
        ...state,
        techShiftDetails: null,
        techShiftUpdated: false,
      };
    }

    case C.FETCH_TECH_SHIFT_COUNT_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        notificationTechShiftCount: action.payload.shiftCount,
      };
    }

    case C.DOWNLOAD_PDF_SUCCESS: {
      return {
        ...state,
        downloadedPDFStatus: 1,
      };
    }

    case C.DOWNLOAD_PDF_FAIL: {
      return {
        ...state,
        downloadedPDFStatus: action.payload.delayPDF ? 3 : 2,
      };
    }

    case C.INIT_HIRE_FORM: {
      return {
        ...state,
        isHireRequestSuccess: false,
        isHireRequestError: false,
        hireRequestErrorMessage: "",
      };
    }

    case C.SAVE_HIRE_REQUEST: {
      return {
        ...state,
        isHireRequestSuccess: false,
        isHireRequestError: false,
        hireRequestErrorMessage: "",
      };
    }

    case C.SAVE_HIRE_REQUEST_SUCCESS: {
      return {
        ...state,
        isHireRequestSuccess: true,
      };
    }

    case C.SAVE_HIRE_REQUEST_FAIL: {
      return {
        ...state,
        isHireRequestError: true,
        hireRequestErrorMessage: action.payload.message,
      };
    }

    case calendarConstants.DECLINE_REQUEST_SUCCESS:
      const oShiftRequest = action.payload.shiftRequest;
      const oShiftToBeUpdated = {
        ...state.vetShiftsFullData.find((o) => o.shiftGroupId === oShiftRequest.shiftGroupId),
      };
      oShiftToBeUpdated.requestCount -= 1;
      if (oShiftToBeUpdated.requestCount === 0) {
        oShiftToBeUpdated.requestedOn = null;
        oShiftToBeUpdated.shiftRequestedOn = null;
      }

      let aShiftRequestDetailsToBeUpdated = JSON.parse(oShiftToBeUpdated.shiftRequestDetails);
      aShiftRequestDetailsToBeUpdated = aShiftRequestDetailsToBeUpdated.filter(
        (o) => o.vetId !== oShiftRequest.vetId
      );
      oShiftToBeUpdated.shiftRequestDetails = JSON.stringify(aShiftRequestDetailsToBeUpdated);

      const updatedVetShiftsFullData = state.vetShiftsFullData.map((o) => {
        if (o.shiftGroupId === oShiftToBeUpdated.shiftGroupId) {
          return oShiftToBeUpdated;
        } else {
          return { ...o };
        }
      });
      return {
        ...state,
        vetShiftsFullData: updatedVetShiftsFullData,
      };

    case calendarConstants.DECLINE_TECH_REQUEST_SUCCESS:
      const oTechShiftRequest = action.payload.shiftRequest;
      const oTechShiftToBeUpdated = {
        ...state.techShiftsFullData.find((o) => o.shiftGroupId === oTechShiftRequest.shiftGroupId),
      };
      oTechShiftToBeUpdated.requestCount -= 1;
      if (oTechShiftToBeUpdated.requestCount === 0) {
        oTechShiftToBeUpdated.requestedOn = null;
        oTechShiftToBeUpdated.shiftRequestedOn = null;
      }

      let aTechShiftRequestDetailsToBeUpdated = JSON.parse(
        oTechShiftToBeUpdated.shiftRequestDetails
      );
      aTechShiftRequestDetailsToBeUpdated = aTechShiftRequestDetailsToBeUpdated.filter(
        (o) => o.techId !== oTechShiftRequest.techId
      );
      oTechShiftToBeUpdated.shiftRequestDetails = JSON.stringify(
        aTechShiftRequestDetailsToBeUpdated
      );

      const updatedTechShiftsFullData = state.techShiftsFullData.map((o) => {
        if (o.shiftGroupId === oTechShiftToBeUpdated.shiftGroupId) {
          return oTechShiftToBeUpdated;
        } else {
          return { ...o };
        }
      });
      return {
        ...state,
        techShiftsFullData: updatedTechShiftsFullData,
      };

    case SHOW_TRAINING_POPUP: {
      return {
        ...state,
        isShowTrainingPopup: true,
      };
    }

    case C.HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_REQUEST:
    case C.RESET_HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_ACTION: {
      return {
        ...state,
        isRemoveConfirmedTechActionCompleted: false,
        isError: false,
        isReloadTechShifts: false,
        removeConfirmedTechErrorMsg: "",
      };
    }

    case C.HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_SUCCESS: {
      return {
        ...state,
        isRemoveConfirmedTechActionCompleted: true,
      };
    }

    case C.HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_FAIL: {
      return {
        ...state,
        isRemoveConfirmedTechActionCompleted: true,
        isError: true,
        removeConfirmedTechErrorMsg: action.payload,
      };
    }

    case C.SET_RELOAD_TECH_SHIFTS_FLAG: {
      return {
        ...state,
        isReloadTechShifts: true,
        isRemoveConfirmedTechActionCompleted: false,
        isError: false,
      };
    }

    case C.SUBMIT_OVERTIME_SUCCESS: {
      const { responseAlreadyRecorded, status } = action.payload;
      return {
        ...state,
        overtimeRequestStatus: responseAlreadyRecorded
          ? "responseAlreadyRecorded"
          : status == "accepted"
          ? "accepted"
          : "rejected",
      };
    }

    case C.SUBMIT_OVERTIME_FAIL: {
      return {
        ...state,
        overtimeRequestStatus: "error",
      };
    }

    case C.BOOST_SHIFT: {
      return {
        ...state,
        [action.payload?.isVet ? "isVetShiftBoosted" : "isTechShiftBoosted"]: null,
      };
    }

    case C.RESET_BOOST_SHIFT: {
      return {
        ...state,
        ...state,
        isVetShiftBoosted: null,
        isTechShiftBoosted: null,
      };
    }

    case C.BOOST_SHIFT_SUCCESS: {
      const { isVet } = action.payload;
      return {
        ...state,
        [isVet ? "isVetShiftBoosted" : "isTechShiftBoosted"]: true,
      };
    }
    case C.BOOST_SHIFT_FAIL: {
      return {
        ...state,
        isVetShiftBoosted: false,
        isTechShiftBoosted: false,
      };
    }

    case C.HOSPITAL_RESPONSE_RATING_COMMENTS_SUCCESS: {
      return {
        ...state,
        hospitalRatingComments: action.payload.hospitalRatingComments,
      };
    }

    case C.HOSPITAL_RESPONSE_RATING_COMMENTS_FAIL: {
      return {
        ...state,
        hospitalRatingComments: [],
      };
    }

    case C.HOSPITAL_RESPONSE_RATING_SUCCESS: {
      return {
        ...state,
        hospitalResponseRating: action.payload.data,
      };
    }

    case C.HOSPITAL_RESPONSE_RATING_FAIL: {
      return {
        ...state,
        hospitalResponseRating: 0,
      };
    }

    case C.UPDATE_HOSPITAL_RESPONSE_RATING_STATUS_SUCCESS: {
      return {
        ...state,
        hospitalResponseRatingStatus: action.payload.data,
      };
    }

    case C.HOSPITAL_RESPONSE_RATING_DATA_SUCCESS: {
      const {
        responseRating,
        responseRatingStatus,
        currMonthResponseRating,
        prevMonthResponseRating,
        secondPrevMonthResponseRating,
      } = action.payload;
      return {
        ...state,
        hospitalResponseRating: responseRating,
        hospitalResponseRatingStatus: responseRatingStatus,
        hospitalResponseRatingData: action.payload,
      };
    }

    case C.HOSPITAL_RESPONSE_RATING_DATA_FAIL: {
      return {
        ...state,
        hospitalResponseRating: 0,
        hospitalResponseRatingData: action.payload,
      };
    }

    case C.GET_THREE_MONTHS_HOSPITAL_SHIFTS_SUCCESS: {
      const {
        isGetCompletedOnly,
        dates,
        requestedShifts,
        confirmedShifts,
        unfilledShifts,
        completedShifts,
        cancelledShifts,
      } = action.payload;

      let newState = {};
      if (isGetCompletedOnly === 1) {
        newState = {
          completedShifts,
        };
      } else {
        newState = {
          requestedShifts,
          confirmedShifts,
          unfilledShifts,
          cancelledShifts,
          dates,
        };
      }
      return {
        ...state,
        threeMonthShiftHistory: { ...state.threeMonthShiftHistory, ...newState },
      };
    }
    case C.GET_THREE_MONTHS_HOSPITAL_SHIFTS_FAIL: {
      return {
        ...state,
        threeMonthShiftHistory: {},
      };
    }
    case C.BULK_DELETE_SHIFTS_SUCCESS: {
      return {
        ...state,
        isBulkDeleteSuccess: true,
      };
    }
    case C.BULK_DELETE_SHIFTS_FAIL: {
      return {
        ...state,
        isBulkDeleteSuccess: false,
      };
    }
    case C.CLEAR_BULK_DELETE_DATA: {
      return {
        ...state,
        isBulkDeleteSuccess: false,
      };
    }
    case C.BOOST_ALL_SHIFTS_SUCCESS: {
      return {
        ...state,
        isBoostAllShiftsSuccess: true,
      };
    }
    case C.BOOST_ALL_SHIFTS_SUCCESS_NO_MODAL: {
      return {
        ...state,
      };
    }
    case C.BOOST_ALL_SHIFTS_FAIL: {
      return {
        ...state,
        isBoostAllShiftsSuccess: false,
      };
    }
    case C.CLEAR_BOOST_ALL_DATA: {
      return {
        ...state,
        isBoostAllShiftsSuccess: false,
      };
    }
    case C.BOOST_ALL_TECH_SHIFTS_SUCCESS: {
      return {
        ...state,
        isBoostAllTechShiftsSuccess: true,
      };
    }
    case C.BOOST_ALL_TECH_SHIFTS_SUCCESS_NO_MODAL: {
      return {
        ...state,
      };
    }
    case C.BOOST_ALL_TECH_SHIFTS_FAIL: {
      return {
        ...state,
        isBoostAllTechShiftsSuccess: false,
      };
    }
    case C.CLEAR_BOOST_ALL_TECH_DATA: {
      return {
        ...state,
        isBoostAllTechShiftsSuccess: false,
      };
    }
    case C.PENDING_REVIEW_DATA_SUCCESS: {
      return {
        ...state,
        pendingReviewData: action.payload,
      };
    }
    case C.PENDING_REVIEW_DATA_FAIL: {
      return {
        ...state,
        pendingReviewData: [],
      };
    }
    case C.TECH_PENDING_REVIEW_DATA_SUCCESS: {
      return {
        ...state,
        techPendingReviewData: action.payload,
      };
    }
    case C.TECH_PENDING_REVIEW_DATA_FAIL: {
      return {
        ...state,
        techPendingReviewData: [],
      };
    }

    case C.SET_MODAL_OPEN_FLAG: {
      return {
        ...state,
        isModalOpen: true,
      };
    }

    case C.RESET_MODAL_OPEN_FLAG: {
      return {
        ...state,
        isModalOpen: false,
      };
    }

    case C.GET_INVOICED_CUSTOMER_SUCCESS: {
      return {
        ...state,
        invoicedCustomerData: action.payload,
      };
    }

    case C.ADD_CANCELLATION_SHIFT_ID: {
      return {
        ...state,
        cancelledShiftsId: [...state.cancelledShiftsId, action.payload],
      };
    }

    case C.GET_REFUND_AMOUNT: {
      return {
        ...state,
        hasRefundAmountFetched: false,
      };
    }

    case C.GET_REFUND_AMOUNT_SUCCESS: {
      return {
        ...state,
        hasRefundAmountFetched: true,
        shiftCancelRefundData: action.payload,
      };
    }

    case C.GET_REFUND_AMOUNT_FAIL: {
      return {
        ...state,
        isFetchingRefundAmount: true,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default hospitalReducer;
