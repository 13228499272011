import style from "styled-components";

import {
  BoldBodyText,
  SmallBodyText,
  greenBase,
  greenDark600,
  redBase,
  redDark600,
} from "../../styles/constants";

import { LinkTextProps } from ".";

export const LinkContainer = style.div`
    display: inline-flex;
    gap: 2px;
    align-items: center;
    cursor: pointer;
`;

export const Link = style.a<LinkTextProps>`
  ${(props) => (props.linkSize === "small" ? SmallBodyText : BoldBodyText)}
  font-weight: 600;
  color: ${greenBase} !important;
  text-decoration: underline !important;
  &:hover {
    color: ${greenDark600} !important;
  }
  ${(props) => props.disabled && `opacity: 0.5;`}
`;

export const DangerLink = style.a<LinkTextProps>`
  ${(props) => (props.linkSize === "small" ? SmallBodyText : BoldBodyText)}
  font-weight: 600;
  color: ${redBase} !important;
  text-decoration: underline !important;
  &:hover {
    color: ${redDark600} !important;
  }
  ${(props) => props.disabled && `opacity: 0.5;`}
`;

export const TextLink = style.p<LinkTextProps>`
  ${(props) => (props.linkSize === "small" ? SmallBodyText : BoldBodyText)}
  font-weight: 600;
  color: ${greenBase} !important;
  text-decoration: underline !important;
  &:hover {
    color: ${greenDark600} !important;
  }
  ${(props) => props.disabled && `opacity: 0.5;`}
  margin-bottom: 0 !important;
`;

export const DangerTextLink = style.p<LinkTextProps>`
  ${(props) => (props.linkSize === "small" ? SmallBodyText : BoldBodyText)}
  font-weight: 600;
  color: ${redBase} !important;
  text-decoration: underline !important;
  &:hover {
    color: ${redDark600} !important;
  }
  ${(props) => props.disabled && `opacity: 0.5;`}
  margin-bottom: 0 !important;
`;

export const Icon = style.img`
  width: 16px;
  height: 16px;
`;
