import React from "react";

import { greenBase } from "../../styles/constants";
import { Icon, IconProps } from "../Icon";

import {
  DangerLink,
  DangerTextLink,
  LinkContainer,
  Link as StandardLink,
  TextLink,
} from "./LinkStyles";

export interface LinkTextProps {
  icon?: IconProps["name"] | IconProps;
  children?: React.ReactNode;
  onClick?: (event: any) => void;
  iconPosition?: "left" | "right";
  href?: string;
  target?: string;
  rel?: string;
  disabled?: boolean;
  linkStyle?: "standard" | "danger" | "text" | "dangerText";
  linkSize?: "small";
  testId?: string;
}

export const Link: React.FC<LinkTextProps> = (props) => {
  const { children, icon, onClick, iconPosition = "left", testId, href } = props;

  let { linkStyle = "standard" } = props;

  if (!href && linkStyle === "danger") linkStyle = "dangerText";
  else if (!href) linkStyle = "text";

  const LinkComponent = {
    standard: StandardLink,
    danger: DangerLink,
    text: TextLink,
    dangerText: DangerTextLink,
  }[linkStyle] as React.ElementType;

  let iconComponent = null;
  if (icon) {
    if (typeof icon === "object") {
      iconComponent = <Icon {...(icon as IconProps)} />;
    } else {
      iconComponent = <Icon name={icon as IconProps["name"]} color={greenBase} />;
    }
  }

  return (
    <LinkContainer onClick={onClick}>
      <LinkComponent
        {...props}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => !!onClick && e.preventDefault()}
        data-testid={testId}
      >
        {icon && iconPosition === "left" && iconComponent}
        {children}
        {icon && iconPosition === "right" && iconComponent}
      </LinkComponent>
    </LinkContainer>
  );
};
