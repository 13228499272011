import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Alert, ButtonTabs, Checkbox, Container, Heading, Text, spacingL } from "@RooUI";

import { RooContext } from "../../App";
import * as UserTypes from "../../constants/UserTypeConstants";
import { TASK_TYPE, findTaskByType, getTaskDetails, useTaskContext } from "../../taskContext";
import HeaderLoggedIn from "../HeaderLoggedIn";

import {
  codeOfConduct,
  generatePoliciesPageStructure,
  hospitalCancellationPolicy,
  hospitalPermanentPlacement,
} from "./initPoliciesPage";
import { PolicyInterruptModal } from "./PolicyInterruptModal";

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const LOCAL_STORAGE_POLICY_MODAL_KEY = "hasSeenHospitalPoliciesModal";

const PoliciesPage = () => {
  const history = useHistory();
  const user = useSelector((state: any) => state.login);
  const { tasks, markTaskComplete, isTaskCenterEnabled } = useTaskContext();

  const policiesPageStructure = generatePoliciesPageStructure(user.userTypeId);
  const { isPendingStatus } = useContext(RooContext);

  const [hospitalCheckBoxes, setHospitalCheckBoxes] = useState<boolean[]>([false, false, false]);
  const [showModal, setShowModal] = useState(false);

  const isHospitalUser = user.userTypeId === UserTypes.HOSPITAL_USER_TYPE;

  const acceptPolicyTask = findTaskByType(tasks, TASK_TYPE.HOSPITAL_ONBOARDING_ACCEPT_POLICIES);
  const enablePolicyCheckboxes =
    isTaskCenterEnabled && isHospitalUser && !!acceptPolicyTask && !acceptPolicyTask.completedOn;

  useEffect(() => {
    if (!enablePolicyCheckboxes) return;

    const handleNavigation = () => {
      if (
        hospitalCheckBoxes.some((checked) => !checked) &&
        !localStorage.getItem(LOCAL_STORAGE_POLICY_MODAL_KEY)
      ) {
        setShowModal(true);
        localStorage.setItem(LOCAL_STORAGE_POLICY_MODAL_KEY, "true");
        return false;
      }
      return undefined;
    };

    const unblock = history.block(handleNavigation);
    return () => {
      unblock();
    };
  }, [enablePolicyCheckboxes, history, hospitalCheckBoxes]);

  useEffect(() => {
    const allChecked = hospitalCheckBoxes.every((checked) => checked);
    if (allChecked && acceptPolicyTask && !acceptPolicyTask.completedOn) {
      const taskDetails = getTaskDetails(acceptPolicyTask.type);
      markTaskComplete(acceptPolicyTask.id, acceptPolicyTask.type, taskDetails.title);
    }
  }, [hospitalCheckBoxes, acceptPolicyTask, markTaskComplete]);

  const panelContentArr: any = [];
  policiesPageStructure?.forEach((section: any, index) => {
    const { body, tabTitle } = section;

    let checkboxLabel = "";
    switch (tabTitle) {
      case hospitalCancellationPolicy.tabTitle:
        checkboxLabel = "I have read and understood Roo's Cancellation Policy";
        break;
      case codeOfConduct.tabTitle:
        checkboxLabel = "I have read and understood Roo's Code of Conduct";
        break;
      case hospitalPermanentPlacement.tabTitle:
        checkboxLabel = "I have read and understood Roo's Full-Time Placement Policy";
        break;
      default:
        checkboxLabel = `I have read and understood Roo's ${tabTitle}`;
    }

    const panelContent = (
      <>
        {body.map((bodyContent: any) => {
          const { title, content } = bodyContent;
          return (
            <Container key={title}>
              <Heading level={6}>{title}</Heading>
              {content?.map((contentItem: any, index: number) => (
                <Text key={`${title}-${index}`}>
                  {contentItem?.paragraph ? contentItem.paragraph : null}
                  {contentItem?.bullets && <ul className="mb-0">{contentItem.bullets}</ul>}
                </Text>
              ))}
            </Container>
          );
        })}
        {enablePolicyCheckboxes && checkboxLabel && (
          <Container>
            <CenteredDiv>
              <Checkbox
                data-testid="policyCheckbox"
                checked={hospitalCheckBoxes[index]}
                label={checkboxLabel}
                topAlignedLabel={true}
                onChange={() => {
                  const newCheckboxStates = [...hospitalCheckBoxes];
                  newCheckboxStates[index] = !newCheckboxStates[index];
                  setHospitalCheckBoxes(newCheckboxStates);
                }}
              />
            </CenteredDiv>
          </Container>
        )}
      </>
    );
    panelContentArr.push({ index, name: tabTitle, panelContent });
  });

  return (
    <div className="inner-container sm-top">
      <HeaderLoggedIn />
      <div className="container">
        <article className="policies-wrapper">
          {isPendingStatus ? (
            <div style={{ marginTop: spacingL }}>
              <Alert
                alertStatus="info"
                alertBody={
                  <Text data-testid="policyBanner">
                    Take a moment to familiarize yourself with our policies. Check the box below to
                    let us know you've read it.
                  </Text>
                }
              />
            </div>
          ) : null}
          <Heading level={1}>Our Policies</Heading>
          <ButtonTabs
            data-testid="policyTabs"
            defaultSelectedTabIndex={0}
            tabNameArr={panelContentArr}
          />
        </article>
      </div>
      {enablePolicyCheckboxes && (
        <PolicyInterruptModal showModal={showModal} onCloseModal={() => setShowModal(false)} />
      )}
    </div>
  );
};

// eslint-disable-next-line no-restricted-exports
export default PoliciesPage;
