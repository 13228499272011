import { map } from "lodash";

import {
  CHANGE_PRIMARY_CONTACT_SUCCESS,
  GET_HOSPITAL_PENDING_REVIEWS_FLAG_SUCCESS,
  UPDATE_HAS_PAYMENT_FAILED_FLAG,
  UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG_SUCCESS,
  UPDATE_HOSPITAL_TOUR_STATUS_SUCCESS,
  UPDATE_ROO_UNI_CALLOUT_STATUS_SUCCESS,
} from "../../constants/hospitalConstants";
import * as C from "../../constants/Login";
import * as techC from "../../constants/techConstants";
import { UPDATE_TECH_SHOW_ONBOARDING_SUCCESS } from "../../constants/techConstants";
import * as userRoles from "../../constants/UserRoleConstants";
import * as userTypes from "../../constants/UserTypeConstants";
import * as vetC from "../../constants/vetConstants";
import { UPDATE_VET_SHOW_ONBOARDING_SUCCESS } from "../../constants/vetConstants";
import { AuthUtility } from "../../helpers/AuthUtility";

const isProdHostname = ["roo.vet"].includes(window.location.hostname);

const initialState = {
  isProdHostname,
  isShowLoader: false,
  isSessionInfoFetched: false,
  loginMessage: {
    statusCode: null,
    message: "",
    link: "",
  },
  fullName: "", // in case of hospital, is hospital name
  firstName: "",
  lastName: "",
  isNameUpdated: 1,
  dateOfBirth: "",
  enterpriseId: null,
  enterpriseName: "",
  hospitalGroupIds: "",
  groupNames: "",
  myHospitalsList: [],
  hospitalIds: "",
  hospitalNames: "",
  isRegistrationComplete: null,
  userTypeId: null,
  phoneNumber: null,
  email: null,
  shiftRequestId: null,
  stripeCustomerId: "",
  trainingStatus: null,
  trainingCount: 0,
  hasPaymentSetup: null,
  isVerifyMicroDeposits: false,
  isRetryAddBank: false,
  isStripeAccountVerifyAgain: false,
  hasW9: null,
  hasLicense: null,
  redirectUrl: null,
  zipcode: null,
  stateId: null,
  metroAreaId: null,
  latitude: null,
  longitude: null,
  cognitoId: null,
  isAuthorisedUser: false,
  isShowUserSuspendedPopup: false,
  isShowPendingReviews: false,
  isNewPasswordRequired: false,
  tempPassword: null,
  signInRequest: null,
  isShowAlertFormPop: null,
  alertFormPopCount: null,
  userName: null,
  isHospitalAdmin: null,
  userRoleId: null,
  isGroupAdmin: null,
  isEnterpriseAdmin: null,
  isMultiHospitalAccess: null,
  referralCode: null,
  vetRegistrationPageNo: null,
  techRegistrationPageNo: null,
  studentRegistrationPageNo: null,
  hospitalRegistrationPageNo: null,
  newRegistrationPage: null,
  isShowUpdateMessage: false,
  isTechBackgroundChecked: false,
  studentGradYear: null,
  isShiftConfirmSuccessClosed: false,
  isShowTrainingPopup: false,
  isRequestShiftModalClosed: false,
  favorites: [],
  favoritedFromId: null,
  favoriteUpdatedHospitalId: null,
  isFavoriteUpdating: false,
  isFavoriteUpdateSuccess: false,
  isRooTechState: false,
  isRooVetState: false,
  locationFavorability: localStorage.getItem("locationFavorability") == "1" ? 1 : null,
  isPostingPaused: localStorage.getItem("isPostingPaused") == "1",
  isEventRequestPending: "",
  isEventCancelPending: "",
  eventRequestedId: null,
  hospitalGroupNames: null,
  isShowOnboarding: null,
  completedOnboardingStep: null,
  isActive: null,
  userId: undefined,
  vetId: undefined,
  techId: undefined,
  studentId: undefined,
  hospitalId: undefined,
  travelDistance: undefined,
  school: undefined,
  licenses: [],
  yearsOfPractice: undefined,
  hasDEAUpdatedSuccessfully: false,
  profileCompletedOn: undefined,
  hasSeenFearFreeAnnouncement: 1,
  approvedOnDate: null,
};

// eslint-disable-next-line default-param-last
const LoginReducer = (state = initialState, action) => {
  let isGroupAdmin = null;
  let isEnterpriseAdmin = null;
  let isMultiHospitalAccess = null;
  let derivedFullName = null;
  let locationFavorability = {};
  let agreementParams = {};
  switch (action.type) {
    case C.SET_PROFILE_IMAGE:
      if (action.payload) {
        localStorage.setItem("profileImage", action.payload);
      } else {
        localStorage.removeItem("profileImage");
      }
      return {
        ...state,
        profileImage: action.payload,
      };

    case C.LOGIN_REQUEST:
      return {
        ...state,
        isShowLoader: true,
        loginMessage: {
          statusCode: null,
          message: "",
          link: "",
        },
      };

    case C.LOGIN_SUCCESS:
      let hospitalId = null;
      let vetId = null;
      let techId = null;
      let studentId = null;
      let isShowUpdateMessage = null;
      localStorage.removeItem("vetId");
      localStorage.removeItem("techId");
      localStorage.removeItem("studentId");
      localStorage.removeItem("tier"); // tech tier
      localStorage.removeItem("userId");
      localStorage.setItem("userTypeId", action.payload.userTypeId || "");
      localStorage.setItem("userId", action.payload.userId || "");
      localStorage.setItem("email", action.payload.email || "");
      localStorage.setItem("metroAreaId", action.payload.metroAreaId || "");
      const { userTypeId } = action.payload;
      const isHospital = userTypeId === userTypes.HOSPITAL_USER_TYPE;

      if (isHospital) {
        if (action.payload.userRoleId === userRoles.GROUP_ADMIN) {
          isGroupAdmin = "1";
          localStorage.setItem("isGroupAdmin", isGroupAdmin);
        } else if (action.payload.userRoleId === userRoles.ENTERPRISE_ADMIN) {
          isEnterpriseAdmin = "1";
          localStorage.setItem("isEnterpriseAdmin", isEnterpriseAdmin);
        }

        if (String(action.payload.isMultiHospitalAccess) === "1") {
          isMultiHospitalAccess = "1";
          localStorage.setItem("isMultiHospitalAccess", isMultiHospitalAccess);
        }
      }

      locationFavorability = {};
      if (action.payload.id !== undefined) {
        const isVet = userTypeId === userTypes.VET_USER_TYPE;
        const isTech = userTypeId === userTypes.TECH_USER_TYPE;
        const isRooUniStudent = userTypeId === userTypes.STUDENT_USER_TYPE;

        if (isHospital) {
          localStorage.setItem("hospitalPhoneNumber", action.payload.phoneNumber || "");
          localStorage.setItem("hospitalId", action.payload.id || "");
          localStorage.setItem("hospitalStateId", action.payload.stateId || "");
          localStorage.setItem("typeOfHospital", action.payload.typeOfHospital || "");
          localStorage.setItem("isShiftPostsExpire", action.payload.isShiftPostsExpire);
          localStorage.setItem("isInternshipsAvailable", action.payload.isInternshipsAvailable);
          hospitalId = action.payload.id;
          isShowUpdateMessage = String(action.payload.isShowUpdateMessage) === "1";
          if (action.payload.locationFavorability == 1) {
            localStorage.setItem("locationFavorability", action.payload.locationFavorability);
            locationFavorability = {
              locationFavorability: action.payload.locationFavorability,
            };
          } else {
            localStorage.removeItem("locationFavorability");
          }

          if (
            action.payload.stateId &&
            !action.payload.isRooTechState &&
            !action.payload.isRooVetState
          ) {
            return {
              ...state,
              isShowLoader: false,
              loginMessage: {
                ...state.loginMessage,
                statusCode: 200,
                message: "isHospitalTech",
                link: null,
              },
              trainingStatus: null,
            };
          }
        } else if (isVet) {
          localStorage.setItem("vetId", action.payload.id || "");
          vetId = action.payload.id;
        } else if (isTech) {
          localStorage.setItem("techId", action.payload.id || "");
          localStorage.setItem("tier", action.payload.tier || "");
          localStorage.setItem("techStateId", action.payload.stateId || "");
          techId = action.payload.id;
        } else if (isRooUniStudent) {
          localStorage.setItem("studentId", action.payload.id || "");
          studentId = action.payload.id;
        }
      }

      if (action.payload.calendlyLink && action.payload.calendlyLink.length > 0) {
        localStorage.setItem("calendlyLink", action.payload.calendlyLink);
      }
      derivedFullName = action.payload.firstName
        ? `${action.payload.firstName} ${action.payload.lastName}`
        : action.payload.fullName;
      localStorage.setItem("userName", action.payload.userName || "");
      localStorage.setItem("fullName", derivedFullName || "");
      localStorage.setItem("isNameUpdated", action.payload.isNameUpdated || 1);
      localStorage.setItem("zipcode", action.payload.zipcode || "");
      localStorage.setItem("userId", action.payload.userId || "");
      localStorage.setItem("jwtToken", action.payload.jwtToken || "");
      localStorage.setItem("isRegistrationComplete", action.payload.isRegistrationComplete || "");
      localStorage.setItem("profileImage", action.payload.profileImage || "");
      AuthUtility.setIsAuthorisedUser(true);
      localStorage.setItem("isActive", action.payload.isActive);
      if (action.payload.isPostingPaused) {
        localStorage.setItem("isPostingPaused", 1);
      }
      if (action?.payload?.enterpriseId) {
        localStorage.setItem("enterpriseId", action.payload.enterpriseId);
      }
      return {
        ...state,
        isShowLoader: false,
        loginMessage: {
          ...state.loginMessage,
          statusCode: action.payload.status,
          message: action.payload.message,
        },
        approvedOnDate: action.payload.approvedOnDate,
        fullName: derivedFullName,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        isNameUpdated: action.payload.isNameUpdated,
        dateOfBirth: action.payload.dateOfBirth,
        enterpriseId: action.payload.enterpriseId,
        enterpriseEnabled: parseInt(action.payload.enterpriseStatus) === 1,
        enterpriseName: action.payload.enterpriseName,
        enterpriseLogo: action.payload.enterpriseLogo,
        hospitalGroupIds: action.payload.hospitalGroupIds,
        groupNames: action.payload.hospitalGroupNames,
        myHospitalsList: action.payload.myHospitalsList,
        hospitalIds: action.payload.hospitalIds,
        hospitalGroupNames: action.payload.hospitalGroupNames,
        hospitalNames: action.payload.hospitalNames,
        email: action.payload.email,
        phoneNumber: action.payload.phoneNumber,
        userTypeId: action.payload.userTypeId,
        isRegistrationComplete: action.payload.isRegistrationComplete,
        jwtToken: action.payload.jwtToken,
        profileImage: action.payload.profileImage,
        probationStatus: action.payload.probationStatus,
        trainingStatus: action.payload.trainingStatus,
        trainingCount: action.payload.trainingCount,
        hasDEA: action.payload.hasDEA,
        hasPaymentSetup: action.payload.hasPaymentSetup,
        isVerifyMicroDeposits: action.payload.isVerifyMicroDeposits,
        isRetryAddBank: action.payload.isRetryAddBank,
        isStripeAccountVerifyAgain: action.payload.isStripeAccountVerifyAgain,
        hasW9: action.payload.hasW9,
        hasLicense: action.payload.hasLicense,
        hasTechLicense: action.payload.hasTechLicense,
        isShowUpdateMessage: isShowUpdateMessage,
        isHospitalBillingSetUp: action.payload.isHospitalBillingSetUp,
        isInvoicedEnabled: action.payload?.isInvoicedEnabled ?? 0,
        isInternalReliefEnabled: parseInt(action.payload.isInternalReliefEnabled) === 1,
        hasPaymentFailed: action.payload.hasPaymentFailed ? true : false,
        hospitalId: hospitalId,
        hospitalUserId: action.payload.hospitalUserId,
        typeOfHospital: action.payload.typeOfHospital,
        isShiftPostsExpire: action.payload.isShiftPostsExpire,
        isInternshipsAvailable: action.payload.isInternshipsAvailable,
        isShowUniCallout: action.payload.isShowUniCallout,
        isShowDashboardTour: action.payload.isShowDashboardTour,
        isShowPendingReviews: action.payload.isShowPendingReviews,
        hasPostedShifts: action.payload.hasPostedShifts,
        vetId: vetId,
        techId: techId,
        studentId: studentId,
        userId: action.payload.userId,
        hospitalTimezone: action.payload.hospitalTimezone,
        zipcode: action.payload.zipcode,
        stateId: action.payload.stateId,
        techRegistrationFlow: action.payload.techRegistrationFlow,
        metroAreaId: parseInt(action.payload.metroAreaId),
        metroAreaName: action.payload.metroAreaName,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
        cognitoId: action.payload.cognitoId,
        isAuthorisedUser: true,
        userName: action.payload.userName,
        userRoleId: action.payload.userRoleId,
        isHospitalAdmin: action.payload.isHospitalAdmin,
        isGroupAdmin: isGroupAdmin,
        isEnterpriseAdmin: isEnterpriseAdmin,
        isMultiHospitalAccess: isMultiHospitalAccess,
        isShowAlertFormPop: action.payload.isShowAlertFormPop,
        alertFormPopCount: action.payload.alertFormPopCount,
        referralCode: action.payload.referralCode,
        vetRegistrationPageNo: action.payload.vetRegistrationPageNo,
        techRegistrationPageNo: action.payload.techRegistrationPageNo,
        studentRegistrationPageNo: action.payload.studentRegistrationPageNo,
        hospitalRegistrationPageNo: action.payload.hospitalRegistrationPageNo,
        newRegistrationPage: action.payload.newRegistrationPage,
        school: action.payload.school || action.payload.vetSchool,
        travelDistance: action.payload.travelDistance,
        licenses: action.payload.licenses,
        yearsOfPractice: action.payload.yearsOfPractice,
        isTechBackgroundChecked: action.payload.isTechBackgroundChecked,
        favorites: action.payload.favorites ? action.payload.favorites.split(",") : [],
        isRooTechState: action.payload.isRooTechState,
        isRooVetState: action.payload.isRooVetState,
        past3MonthsShiftRequestCount: action.payload.past3MonthsShiftRequestCount,
        isPostingPaused: action.payload.isPostingPaused,
        ...locationFavorability,
        icAgreementOptions: action.payload.icAgreementOptions,
        icAgreementAcceptedOn: action.payload.icAgreementAcceptedOn,
        icAgreementSignature: action.payload.icAgreementSignature,
        termsAccepted: action.payload.termsAccepted,
        termsReAccepted: action.payload.termsReAccepted,
        tempPassword: null,
        signInRequest: null,
        isNewPasswordRequired: false,
        studentGradYear: action.payload.graduationYear,
        isShowOnboarding: action.payload.isShowOnboarding,
        completedOnboardingStep: action.payload.completedOnboardingStep,
        isActive: action.payload.isActive,
        shiftFullfilledCount: action.payload.shiftFullfilledCount,
        isEligibleForNPS: action.payload.isEligibleForNPS,
        hasCALicense: action.payload.hasCALicense,
        hasSeenFearFreeAnnouncement: parseInt(action.payload.hasSeenFearFreeAnnouncement),
      };
    case C.LOGIN_ROO_DEMO_USER_SUCCESS:
      let demoVetId = null;
      let demoTechId = null;
      localStorage.removeItem("vetId");
      localStorage.removeItem("techId");
      localStorage.removeItem("studentId");
      localStorage.removeItem("tier"); // tech tier
      localStorage.removeItem("userId");
      localStorage.setItem("userTypeId", action.payload.userTypeId || "");
      localStorage.setItem("userId", action.payload.userId || "");
      localStorage.setItem("email", action.payload.email || "");
      localStorage.setItem("metroAreaId", action.payload.metroAreaId || "");
      // const { userTypeId } = action.payload;

      if (action.payload.id !== undefined) {
        const isVet = action.payload.userTypeId === userTypes.VET_USER_TYPE;
        const isTech = action.payload.userTypeId === userTypes.TECH_USER_TYPE;
        if (isVet) {
          localStorage.setItem("vetId", action.payload.id || "");
          demoVetId = action.payload.id;
        } else if (isTech) {
          localStorage.setItem("techId", action.payload.id || "");
          localStorage.setItem("tier", action.payload.tier || "");
          localStorage.setItem("techStateId", action.payload.stateId || "");
          demoTechId = action.payload.id;
        }
      }

      if (action.payload.calendlyLink && action.payload.calendlyLink.length > 0) {
        localStorage.setItem("calendlyLink", action.payload.calendlyLink);
      }
      derivedFullName = action.payload.firstName
        ? `${action.payload.firstName} ${action.payload.lastName}`
        : action.payload.fullName;
      localStorage.setItem("userName", action.payload.userName || "");
      localStorage.setItem("fullName", derivedFullName || "");
      localStorage.setItem("isNameUpdated", action.payload.isNameUpdated || 1);
      localStorage.setItem("zipcode", action.payload.zipcode || "");
      localStorage.setItem("userId", action.payload.userId || "");
      localStorage.setItem("jwtToken", action.payload.jwtToken || "");
      localStorage.setItem("isRegistrationComplete", action.payload.isRegistrationComplete || "");
      localStorage.setItem("profileImage", action.payload.profileImage || "");
      AuthUtility.setIsAuthorisedUser(true);
      localStorage.setItem("isActive", action.payload.isActive);
      return {
        ...state,
        isShowLoader: false,
        loginMessage: {
          ...state.loginMessage,
          statusCode: action.payload.status,
          message: action.payload.message,
        },
        approvedOnDate: action.payload.approvedOnDate,
        fullName: derivedFullName,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        isNameUpdated: action.payload.isNameUpdated,
        dateOfBirth: action.payload.dateOfBirth,
        enterpriseId: action.payload.enterpriseId,
        enterpriseEnabled: parseInt(action.payload.enterpriseStatus) === 1,
        enterpriseName: action.payload.enterpriseName,
        enterpriseLogo: action.payload.enterpriseLogo,
        hospitalGroupIds: action.payload.hospitalGroupIds,
        groupNames: action.payload.hospitalGroupNames,
        myHospitalsList: action.payload.myHospitalsList,
        hospitalIds: action.payload.hospitalIds,
        hospitalGroupNames: action.payload.hospitalGroupNames,
        hospitalNames: action.payload.hospitalNames,
        email: action.payload.email,
        phoneNumber: action.payload.phoneNumber,
        userTypeId: action.payload.userTypeId,
        isRegistrationComplete: action.payload.isRegistrationComplete,
        jwtToken: action.payload.jwtToken,
        profileImage: action.payload.profileImage,
        probationStatus: action.payload.probationStatus,
        trainingStatus: action.payload.trainingStatus,
        trainingCount: action.payload.trainingCount,
        hasDEA: action.payload.hasDEA,
        hasPaymentSetup: action.payload.hasPaymentSetup,
        isVerifyMicroDeposits: action.payload.isVerifyMicroDeposits,
        isRetryAddBank: action.payload.isRetryAddBank,
        isStripeAccountVerifyAgain: action.payload.isStripeAccountVerifyAgain,
        hasW9: action.payload.hasW9,
        hasLicense: action.payload.hasLicense,
        isHospitalBillingSetUp: action.payload.isHospitalBillingSetUp,
        isInvoicedEnabled: action.payload?.isInvoicedEnabled ?? 0,
        hospitalUserId: action.payload.hospitalUserId,
        typeOfHospital: action.payload.typeOfHospital,
        isShiftPostsExpire: action.payload.isShiftPostsExpire,
        isInternshipsAvailable: action.payload.isInternshipsAvailable,
        isShowUniCallout: action.payload.isShowUniCallout,
        isShowDashboardTour: action.payload.isShowDashboardTour,
        isShowPendingReviews: action.payload.isShowPendingReviews,
        hasPostedShifts: action.payload.hasPostedShifts,
        vetId: demoVetId,
        techId: demoTechId,
        userId: action.payload.userId,
        hospitalTimezone: action.payload.hospitalTimezone,
        zipcode: action.payload.zipcode,
        stateId: action.payload.stateId,
        techRegistrationFlow: action.payload.techRegistrationFlow,
        metroAreaId: parseInt(action.payload.metroAreaId),
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
        cognitoId: action.payload.cognitoId,
        isAuthorisedUser: true,
        userName: action.payload.userName,
        userRoleId: action.payload.userRoleId,
        isHospitalAdmin: action.payload.isHospitalAdmin,
        isGroupAdmin: isGroupAdmin,
        isEnterpriseAdmin: isEnterpriseAdmin,
        isMultiHospitalAccess: isMultiHospitalAccess,
        isShowAlertFormPop: action.payload.isShowAlertFormPop,
        alertFormPopCount: action.payload.alertFormPopCount,
        referralCode: action.payload.referralCode,
        vetRegistrationPageNo: action.payload.vetRegistrationPageNo,
        techRegistrationPageNo: action.payload.techRegistrationPageNo,
        studentRegistrationPageNo: action.payload.studentRegistrationPageNo,
        hospitalRegistrationPageNo: action.payload.hospitalRegistrationPageNo,
        newRegistrationPage: action.payload.newRegistrationPage,
        school: action.payload.school || action.payload.vetSchool,
        travelDistance: action.payload.travelDistance,
        licenses: action.payload.licenses,
        yearsOfPractice: action.payload.yearsOfPractice,
        isTechBackgroundChecked: action.payload.isTechBackgroundChecked,
        favorites: action.payload.favorites ? action.payload.favorites.split(",") : [],
        isRooTechState: action.payload.isRooTechState,
        isRooVetState: action.payload.isRooVetState,
        isPostingPaused: action.payload.isPostingPaused,
        icAgreementOptions: action.payload.icAgreementOptions,
        icAgreementAcceptedOn: action.payload.icAgreementAcceptedOn,
        tempPassword: null,
        signInRequest: null,
        isNewPasswordRequired: false,
        studentGradYear: action.payload.graduationYear,
        isShowOnboarding: action.payload.isShowOnboarding,
        completedOnboardingStep: action.payload.completedOnboardingStep,
        isShowRooDemoUser: true,
        isActive: action.payload.isActive,
        hasSeenFearFreeAnnouncement: parseInt(action.payload.hasSeenFearFreeAnnouncement),
      };

    case C.SET_LOCATION_AND_SHIFT_FAVORABILITY:
      locationFavorability = {};
      if (action.payload.locationFavorability == 1) {
        localStorage.setItem("locationFavorability", action.payload.locationFavorability);
        locationFavorability = {
          locationFavorability: action.payload.locationFavorability,
        };
      } else {
        localStorage.removeItem("locationFavorability");
      }
      if (action.payload.isActive == 10) {
        localStorage.setItem("isPostingPaused", 1);
      } else {
        localStorage.removeItem("isPostingPaused");
      }
      return {
        ...state,
        isPostingPaused: action.payload.isActive == 10,
        ...locationFavorability,
      };

    case C.NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        isNewPasswordRequired: true,
        email: action.payload.email,
        tempPassword: action.payload.tempPassword,
        signInRequest: action.payload.signInRequest,
      };

    case C.LOGIN_FAIL:
      return {
        ...state,
        isShowLoader: false,
        loginMessage: {
          ...state.loginMessage,
          statusCode: action.payload.status,
          message: action.payload.message,
          link: action.payload.link,
        },
        trainingStatus: null,
      };
    case C.INITIALIZE_LOGIN_STATE:
      return {
        ...initialState,
        redirectUrl: action.data,
      };

    case C.VET_COMPLETE_TRAINING_SUCCESS:
    case C.TECH_COMPLETE_TRAINING_SUCCESS:
    case C.HOSPITAL_COMPLETE_TRAINING_SUCCESS:
      return {
        ...state,
        trainingStatus: 1,
      };

    case C.VET_COMPLETE_TRAINING_FAIL:
    case C.TECH_COMPLETE_TRAINING_FAIL:
    case C.HOSPITAL_COMPLETE_TRAINING_FAIL:
      return {
        ...state,
        trainingStatus: 0,
      };

    case C.SET_LOCAL_VET_HAS_PAYMENT_SETUP:
    case C.SET_LOCAL_TECH_HAS_PAYMENT_SETUP:
      return {
        ...state,
        hasPaymentSetup: true,
      };

    case C.SET_LOCAL_VET_HAS_W9:
    case C.SET_LOCAL_TECH_HAS_W9:
      return {
        ...state,
        hasW9: true,
      };

    case vetC.UPDATE_VET_MICRO_DEPOSITS_VERIFIED_SUCCESS:
    case techC.UPDATE_TECH_MICRO_DEPOSITS_VERIFIED_SUCCESS:
      return {
        ...state,
        isVerifyMicroDeposits: false,
      };
    case vetC.UPDATE_VET_MICRO_DEPOSITS_FAILED_SUCCESS:
    case techC.UPDATE_TECH_MICRO_DEPOSITS_FAILED_SUCCESS:
      return {
        ...state,
        isVerifyMicroDeposits: false,
        isRetryAddBank: true,
      };
    case vetC.SET_VET_DEFAULT_BANK_SUCCESS:
    case techC.SET_TECH_DEFAULT_BANK_SUCCESS:
      return {
        ...state,
        isRetryAddBank: false,
      };

    case vetC.GET_VET_PAYMENT_INFO_SUCCESS:
    case techC.GET_TECH_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        isRetryAddBank: String(action.payload.isRetryAddBank) === "1",
        isVerifyMicroDeposits: String(action.payload.isVerifyMicroDeposits) === "1",
      };

    case C.SET_LOCAL_HOSPITAL_HAS_BILLING_SETUP:
      return {
        ...state,
        isHospitalBillingSetUp: 1,
      };

    case GET_HOSPITAL_PENDING_REVIEWS_FLAG_SUCCESS: {
      return { ...state, isShowPendingReviews: action.payload.isShowPendingReviews };
    }

    case UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG_SUCCESS: {
      return { ...state, isShowPendingReviews: action.isShowPendingReviews };
    }

    case C.SET_SESSION_REQUEST:
      return {
        ...state,
        isSessionInfoFetched: false,
      };

    case C.SET_SESSION_SUCCESS:
      agreementParams = {};
      if (action.payload.icAgreementAcceptedOn) {
        agreementParams = {
          icAgreementOptions: action.payload.icAgreementOptions,
          icAgreementAcceptedOn: action.payload.icAgreementAcceptedOn,
          icAgreementSignature: action.payload.icAgreementSignature,
          termsAccepted: action.payload.termsAccepted,
          termsReAccepted: action.payload.termsReAccepted,
        };
      }

      const myHospitalsList = action.payload.myHospitalsList?.length
        ? JSON.parse(`[${action.payload.myHospitalsList}]`)
        : [];
      const hospitalIds = map(myHospitalsList, (o) => o.id)?.join(",");
      const hospitalNames = map(myHospitalsList, (o) => o.name)?.join(",");

      if (action?.payload?.enterpriseId) {
        localStorage.setItem("enterpriseId", action.payload.enterpriseId);
      }

      // Needed for the transition from superuser back to admin
      const localStorageHasUserIdProperty = Object.prototype.hasOwnProperty.call(
        localStorage,
        "userId"
      );
      if (!localStorageHasUserIdProperty && action.payload.userId) {
        localStorage.setItem("userId", action.payload.userId);
      }

      return {
        ...state,
        isSessionInfoFetched: true,
        approvedOnDate: action.payload.approvedOnDate,
        fullName: action.payload.fullName, // in case of hospital, is hospital name
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        isNameUpdated: action.payload.isNameUpdated,
        dateOfBirth: action.payload.dateOfBirth,
        enterpriseId: action.payload.enterpriseId || state.enterpriseId,
        enterpriseEnabled:
          parseInt(action.payload.enterpriseStatus) === 1 || state.enterpriseEnabled,
        enterpriseName: action.payload.enterpriseName || state.enterpriseName,
        enterpriseLogo: action.payload.enterpriseLogo || state.enterpriseLogo,
        hospitalGroupIds: action.payload.hospitalGroupIds || state.hospitalGroupIds,
        groupNames: action.payload.hospitalGroupNames || state.groupNames,
        myHospitalsList: myHospitalsList || state.myHospitalsList,
        hospitalIds: hospitalIds || state.hospitalIds,
        hospitalGroupNames: action.payload.hospitalGroupNames || state.hospitalGroupNames,
        hospitalId: action.payload.hospitalId,
        hospitalUserId: action.payload.hospitalUserId,
        hospitalNames: hospitalNames || state.hospitalNames,
        email: action.payload.emailId,
        phoneNumber: action.payload.phoneNumber,
        userTypeId: action.payload.userTypeId,
        userRoleId: action.payload.userRoleId,
        isRegistrationComplete: action.payload.isRegistrationComplete,
        isInternshipsAvailable: action.payload.isInternshipsAvailable,
        isShowUniCallout: action.payload.isShowUniCallout,
        isShowDashboardTour: action.payload.isShowDashboardTour,
        isShowPendingReviews: action.payload.isShowPendingReviews,
        hasPostedShifts: action.payload.hasPostedShifts,
        past3MonthsShiftRequestCount: action.payload.past3MonthsShiftRequestCount,
        jwtToken: action.payload.jwtToken,
        profileImage: action.payload.profileImage,
        probationStatus: action.payload.probationStatus,
        trainingStatus: action.payload.trainingStatus,
        hasDEA: action.payload.hasDEA,
        hasPaymentSetup: action.payload.hasPaymentSetup,
        isVerifyMicroDeposits: action.payload.isVerifyMicroDeposits,
        isRetryAddBank: action.payload.isRetryAddBank,
        isStripeAccountVerifyAgain: action.payload.isStripeAccountVerifyAgain,
        hasW9: action.payload.hasW9,
        hasLicense: action.payload.hasLicense,
        isHospitalBillingSetUp: action.payload.isHospitalBillingSetUp,
        isInvoicedEnabled: action.payload?.isInvoicedEnabled ?? 0,
        isInternalReliefEnabled: parseInt(action.payload.isInternalReliefEnabled) === 1,
        hasPaymentFailed: action.payload.hasPaymentFailed ? true : false,
        vetId: action.payload.vetId,
        techId: action.payload.techId,
        studentId: action.payload.studentId,
        techRegistrationFlow: action.payload.techRegistrationFlow,
        hospitalTimezone: action.payload.hospitalTimezone,
        userId: action.payload.userId,
        zipcode: action.payload.zipcode,
        typeOfHospital: action.payload.typeOfHospital,
        isShiftPostsExpire: action.payload.isShiftPostsExpire,
        stateId: action.payload.stateId,
        metroAreaId: parseInt(action.payload.metroAreaId),
        metroAreaName: action.payload.metroAreaName,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
        cognitoId: action.payload.cognitoId,
        isAuthorisedUser: true,
        userName: action.payload.userName,
        isHospitalAdmin: action.payload.isHospitalAdmin,
        isGroupAdmin: action.payload.userRoleId === userRoles.GROUP_ADMIN ? "1" : null,
        isEnterpriseAdmin: action.payload.userRoleId === userRoles.ENTERPRISE_ADMIN ? "1" : null,
        isMultiHospitalAccess: action.payload.isMultiHospitalAccess,
        isShowAlertFormPop: action.payload.isShowAlertFormPop,
        alertFormPopCount: action.payload.alertFormPopCount,
        referralCode: action.payload.referralCode,
        isTechBackgroundChecked: action.payload.isBackgroundChecked === 1,
        favorites: action.payload.favorites ? action.payload.favorites.split(",") : [],
        isRooTechState: action.payload.isRooTechState,
        isRooVetState: action.payload.isRooVetState,
        studentGradYear: action.payload.graduationYear,
        hasTechLicense: action.payload.hasTechLicense,
        completedOnboardingStep: action.payload.completedOnboardingStep,
        isShowOnboarding: action.payload.isShowOnboarding,
        shiftFullfilledCount: action.payload.shiftFullfilledCount,
        isEligibleForNPS: action.payload.isEligibleForNPS,
        hasCALicense: action.payload.hasCALicense,
        profileCompletedOn: action.payload.profileCompletedOn,
        licenses: action.payload.licenses,
        ...agreementParams,
        hasSeenFearFreeAnnouncement: parseInt(action.payload.hasSeenFearFreeAnnouncement),
      };

    case C.UPDATE_USER_PROFILE_INFO_FROM_W9:
      localStorage.setItem("zipcode", action.payload.zipcode || "");
      return {
        ...state,
        ...action.payload,
      };

    case CHANGE_PRIMARY_CONTACT_SUCCESS:
      return { ...state, hospitalUserId: action.payload.newPrimaryUserId };

    case C.UNSET_ENTERPRISE_HOSPITAL_SESSION:
      localStorage.setItem("hospitalId", "");
      return {
        ...state,
        hospitalId: null,
      };

    case C.SET_SESSION_FAIL:
      return {
        ...state,
      };

    case C.SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.urlString,
      };

    case C.CLEAR_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: null,
      };

    case C.UPDATE_ADD_SHIFT_CLICK:
      const key = action?.payload?.isVet ? "addVetShiftClicked" : "addTechShiftClicked";
      return {
        ...state,
        [key]: action?.payload?.isSet,
      };

    case C.SET_AUTHORISE_USER:
      AuthUtility.setIsAuthorisedUser(true);
      return {
        ...state,
        isAuthorisedUser: true,
      };

    case C.INIT_AUTHORISE_USER:
      AuthUtility.setIsAuthorisedUser(false);
      return {
        ...state,
        isAuthorisedUser: false,
      };

    case C.SHOW_USER_SUSPENDED_POP_UP:
      return {
        ...state,
        isShowUserSuspendedPopup: true,
      };

    case C.HIDE_USER_SUSPENDED_POP_UP:
      return {
        ...state,
        isShowUserSuspendedPopup: false,
      };

    case C.HIDE_ALERT_FORM_POP_UP:
      return {
        ...state,
        isShowAlertFormPop: false,
      };

    case C.SET_TECH_BACKGROUND_CHECKED:
      return {
        ...state,
        isTechBackgroundChecked: true,
      };

    case C.GET_TECH_REQUEST_COUNT_SUCCESS:
      return {
        ...state,
        isTechRequested: true,
      };

    case C.GET_TECH_REQUEST_COUNT_FAIL:
      return {
        ...state,
        isTechRequested: false,
      };

    case C.SHOW_TRAINING_POPUP: {
      return {
        ...state,
        isShowTrainingPopup: true,
      };
    }

    case C.REQUEST_SHIFT_SUCCESS: {
      return {
        ...state,
        isRequestShiftModalClosed: true,
      };
    }

    case C.CONFIRM_SHIFT_SUCCESS: {
      return {
        ...state,
        isShiftConfirmSuccessClosed: true,
      };
    }

    case C.UPDATE_USER_PERSONAL_INFO_SUCCESS: {
      return {
        ...state,
        fullName: action.payload.fullName,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phoneNumber: action.payload.mobilePhoneNumber,
        zipcode: action.payload.zipcode,
        stateId: action.payload.stateId,
      };
    }

    case C.UPDATE_FAVORITES: {
      return {
        ...state,
        isFavoriteUpdating: true,
      };
    }

    case C.UPDATE_FAVORITES_SUCCESS: {
      return {
        ...state,
        favorites: action.payload.favorites ? action.payload.favorites.split(",") : [],
        favoritedFromId: action.payload.favoritedFromId,
        favoriteUpdatedHospitalId: action.payload.favoriteUpdatedHospitalId,
        isFavoriteUpdating: false,
        isFavoriteUpdateSuccess: true,
      };
    }

    case C.UPDATE_FAVORITES_FAIL: {
      return {
        ...state,
        isFavoriteUpdating: false,
      };
    }

    case C.RESET_FAVORITES_UPDATED: {
      return {
        ...state,
        favoritedFromId: null,
        favoriteUpdatedHospitalId: null,
        isFavoriteUpdating: false,
        isFavoriteUpdateSuccess: false,
      };
    }

    case C.SIGN_AC_CONTRACT_SUCCESS:
    case C.ACCEPT_TERMS_SUCCESS:
      return {
        ...state,
        showICAgreement: false,
      };

    case C.SKIP_IC_CONTRACT:
      return {
        ...state,
        hasICAgreementSkipped: true,
      };

    case C.SHOW_AC_CONTRACT:
      return {
        ...state,
        showICAgreement: true,
      };

    case C.AC_CONTRACT_SUCCESS_SET_DATA:
      agreementParams = {};
      // reAcceptedTerms field - temporary field for tech users that were shown the wrong IC contract the first time
      if (action.payload.icAgreementAcceptedOn) {
        agreementParams = {
          icAgreementOptions: action.payload.icAgreementOptions,
          icAgreementAcceptedOn: action.payload.icAgreementAcceptedOn,
          icAgreementSignature: action.payload.icAgreementSignature,
          termsReAccepted: action.payload.termsReAccepted,
        };
      }
      if (action.payload.termsAccepted) {
        agreementParams = {
          termsAccepted: action.payload.termsAccepted,
          termsReAccepted: action.payload.termsReAccepted,
        };
      }
      return {
        ...state,
        ...agreementParams,
      };

    case C.SEND_REQUEST_FOR_EVENT: {
      return { ...state, isEventRequestPending: true };
    }

    case C.SEND_REQUEST_FOR_EVENT_SUCCESS: {
      return {
        ...state,
        isRequestedEventSuccess: true,
        isEventRequestPending: false,
        eventRequestedId: action.payload.data.insertId,
      };
    }

    case C.SEND_REQUEST_FOR_EVENT_FAIL: {
      return {
        ...state,
        isEventRequestPending: false,
        isRequestedEventSuccess: false,
      };
    }

    case C.SEND_CANCEL_REQUEST_FOR_EVENT: {
      return { ...state, isEventCancelPending: true };
    }

    case C.SEND_CANCEL_REQUEST_FOR_EVENT_SUCCESS: {
      return {
        ...state,
        isRequestedEventSuccess: true,
        isEventCancelPending: false,
        payload: action.payload,
      };
    }

    case C.SEND_CANCEL_REQUEST_FOR_EVENT_FAIL: {
      return {
        ...state,
        isEventCancelPending: false,
        isRequestedEventSuccess: false,
      };
    }

    case UPDATE_HOSPITAL_TOUR_STATUS_SUCCESS: {
      return {
        ...state,
        isShowDashboardTour: 1,
      };
    }

    case UPDATE_VET_SHOW_ONBOARDING_SUCCESS: {
      localStorage.setItem("isShowOnboarding", 1);
      return {
        ...state,
        isShowOnboarding: 1,
      };
    }

    case UPDATE_TECH_SHOW_ONBOARDING_SUCCESS: {
      localStorage.setItem("isShowOnboarding", 1);
      return {
        ...state,
        isShowOnboarding: 1,
      };
    }

    case UPDATE_ROO_UNI_CALLOUT_STATUS_SUCCESS: {
      return {
        ...state,
        isShowUniCallout: 0,
      };
    }

    case UPDATE_HAS_PAYMENT_FAILED_FLAG: {
      return {
        ...state,
        hasPaymentFailed: action.payload.hasPaymentFailed ? true : false,
      };
    }

    case C.UPDATE_USER_NAME_SUCCESS: {
      localStorage.setItem("isNameUpdated", 1);
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        isNameUpdated: 1,
      };
    }

    case vetC.UPDATE_DEA_LICENSE_INFO:
    case vetC.UPDATE_DEA_LICENSE_INFO_FAIL: {
      return {
        ...state,
        hasDEAUpdatedSuccessfully: false,
      };
    }

    case vetC.UPDATE_DEA_LICENSE_INFO_SUCCESS:
      return {
        ...state,
        hasDEA: action.payload?.isDEA,
        hasDEAUpdatedSuccessfully: true,
      };

    case C.NEXT_STEP_ONBOARDING: {
      return {
        ...state,
        completedOnboardingStep: action.payload,
      };
    }

    case C.GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS:
    case C.GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS_FAIL: {
      return {
        ...state,
        cancelledShiftDetailsIn30Days: {},
      };
    }

    case C.GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS_SUCCESS: {
      return {
        ...state,
        cancelledShiftDetailsIn30Days: action.payload,
      };
    }

    case C.UPDATE_HAS_SEEN_FEAR_FREE_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        hasSeenFearFreeAnnouncement: 1,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default LoginReducer;
