import React from "react";

import { FormLabel as FormLabelStyle } from "./FormLabelStyles";

export interface LabelProps {
  children?: React.ReactNode;
  error?: boolean;
  disabled?: boolean;
  htmlFor?: string;
}

export const FormLabel: React.FC<LabelProps> = (props) => {
  const { error, disabled, children, htmlFor } = props;
  return (
    <FormLabelStyle error={!!error} disabled={disabled} htmlFor={htmlFor}>
      {children}
    </FormLabelStyle>
  );
};
