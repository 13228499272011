import React from "react";

import { NestedTabs, TabNameObj } from "../NestedTabs";
import { Button, ButtonProps } from "../Button";
import { Icon } from "../Icon";

import {
  ButtonContainer,
  CloseIconContainer,
  ModalBody,
  ModalContent,
  ModalHeader,
  PanelContentContainer,
  StyledModal,
} from "./ModalStyles";

export interface ModalProps {
  size?: "s" | "m" | "l";
  showModal?: boolean;
  onCloseModal?: () => void;
  noClose?: boolean;
  modalType?: "browse" | "photo upload";
  topPosition?: number;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  tabNameArr?: TabNameObj[];
  defaultSelectedTabIndex?: number;
  onClickTab?: (e: any) => void;
  header?: string;
  body?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    showModal,
    primaryButtonProps,
    secondaryButtonProps,
    tabNameArr,
    defaultSelectedTabIndex,
    onClickTab,
    header,
    body,
    onCloseModal,
    size,
  } = props;

  const modalButtons = (
    <ButtonContainer {...props}>
      {primaryButtonProps ? <Button buttonType="primary" {...primaryButtonProps} /> : null}
      {secondaryButtonProps ? <Button buttonType="secondary" {...secondaryButtonProps} /> : null}
    </ButtonContainer>
  );

  const newTabNameArr: TabNameObj[] | undefined = tabNameArr?.map((tab: TabNameObj) => {
    return {
      ...tab,
      panelContent: (
        <PanelContentContainer>
          {tab.panelContent}
          {modalButtons}
        </PanelContentContainer>
      ),
    };
  });

  return (
    <StyledModal
      open={showModal}
      data-testid="modalContainer"
      closeIcon={false}
      footer={null}
      size={size}
      width="auto"
      zIndex={1050}
    >
      <ModalContent {...props}>
        <CloseIconContainer {...props}>
          <Icon name="Close" onClick={onCloseModal} />
        </CloseIconContainer>

        {(header || body) && (
          <ModalBody>
            {header && <ModalHeader>{header}</ModalHeader>}
            {body && <>{body}</>}
          </ModalBody>
        )}
        {newTabNameArr && defaultSelectedTabIndex && (
          <NestedTabs
            tabNameArr={newTabNameArr}
            defaultSelectedTabIndex={defaultSelectedTabIndex}
            onClickTab={onClickTab}
          />
        )}
        {header && body && modalButtons}
      </ModalContent>
    </StyledModal>
  );
};
