import styled from "styled-components";
import {
  Button,
  SmallText,
  smallMediaBreakpoint,
  spacingL,
  spacingM,
  spacingS,
  spacingXL,
  spacingXS,
  spacingXXL,
  whiteBackground,
} from "@RooUI";

export const RecommendedShiftsChip = styled(SmallText)`
  color: ${whiteBackground};
`;

export const RecommendedShiftsChipContainer = styled.div`
  margin-bottom: ${spacingXL};
`;

export const SearchButtonContainer = styled.div`
  gap: ${spacingL};
  display: flex;
  margin-top: ${spacingXL};
  @media (max-width: ${smallMediaBreakpoint}) {
    width: 100%;
  }
`;

export const FilterContent = styled.div`
  padding: 0 ${spacingXXL};
  display: inline-grid;
  width: 100%;
  gap: 24px;
`;

export const FilterSectionContainer = styled.div`
  display: inline-grid;
  background: ${whiteBackground};
  border-radius: 28px;
  padding: ${spacingXL};
  width: 100%;
  gap: ${spacingM};
`;

export const SelectContainer = styled.div`
  display: inline-grid;
  width: 100%;
  gap: ${spacingL};
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacingXS};
`;

export const RadioContainer = styled.div`
  display: inline-grid;
  gap: ${spacingM};
`;

export const CTAButton = styled(Button)`
  padding: ${spacingS} 32px;
  width: 100%;
  align-items: center;
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingL};
  width: 100%;
  align-items: center;
`;

export const StyledCheckboxGroupWrapper = styled.div`
  & > div {
    & > div {
      display: block;
    }
  }
`;
