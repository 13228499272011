import React, { useEffect, useState } from "react";

import { Icon, IconProps } from "../Icon";
import {
  Control,
  CustomCheckLabel,
  IconLabel,
  InformationalDiv,
  Input,
  InputContainer,
} from "../Checkbox/CustomCheckboxStyles";
import { blueDark600, blueLighter, grayWhite, redBase } from "../../styles/constants";

export interface CustomRadioProps {
  id?: string;
  label?: string;
  name?: string;
  value?: string | number;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  type?: "classic" | "informational";
  icon?: IconProps["name"];
  size?: "small" | "large";
  className?: string;
  style?: React.CSSProperties;
  onChange?: (event: any) => void;
  error?: string;
}
export const Radio: React.FC<CustomRadioProps> = (props) => {
  const {
    type = "classic",
    id,
    label = "",
    disabled = false,
    name = "",
    value = "",
    defaultChecked = false,
    checked,
    icon,
    size = "large",
    className,
    style,
    error,
    onChange,
  } = props;
  const [infoRadiocChecked, setInfoRadioChecked] = useState(defaultChecked);
  useEffect(() => {
    if (checked !== undefined) {
      setInfoRadioChecked(checked);
    }
  }, [checked]);

  const handleRadioChange = (e: any) => {
    if (disabled) {
      return;
    }
    if (onChange) {
      // Patch the target value since we use a div, not an input
      if (type === "informational") {
        e.target.value = value;
      }
      onChange(e);
    }
  };
  let checkboxIconColor = defaultChecked ? grayWhite : blueDark600;
  if (checked) {
    checkboxIconColor = grayWhite;
  } else {
    if (disabled) {
      checkboxIconColor = blueLighter;
    } else if (error) {
      checkboxIconColor = redBase;
    }
  }

  return type === "classic" ? (
    <CustomCheckLabel disabled={disabled} className={className} style={style} error={!!error}>
      <InputContainer>
        <Input
          type="radio"
          id={id}
          name={name}
          checked={checked}
          defaultChecked={defaultChecked}
          value={value}
          disabled={disabled}
          onChange={handleRadioChange}
        />
        <Control error={!!error} />
      </InputContainer>
      {label}
    </CustomCheckLabel>
  ) : (
    <InformationalDiv
      id={id}
      className={`${className} ${infoRadiocChecked ? "checked" : "unchecked"}`}
      style={style}
      disabled={disabled}
      size={size}
      onClick={handleRadioChange}
      error={!!error}
    >
      {icon ? <Icon name={icon} color={checkboxIconColor} /> : null}
      <IconLabel error={!!error}>{label}</IconLabel>
    </InformationalDiv>
  );
};
