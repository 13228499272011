import { History } from "history";

import * as UserTypes from "../constants/UserTypeConstants";
import * as userStatusTypes from "../constants/userStatusTypes";
import { AuthUtility } from "../helpers/AuthUtility";

const BYPASS_HOMEPAGE_REROUTE_LOGIC_SET = new Set([
  "notification",
  "policies",
  "manageSettings",
  "licenseViewer",
  "superuser",
  "resetpassword",
]);

const STUDENT_REGISTRATION_PAGES = [
  "/registerStudent/basicInformation",
  "/registerStudent/educationExperience",
  "/registerStudent/knowYourStudent",
  "/registerStudent/photoUpload",
  "/registerStudent/profileComplete",
];

export interface RouteUserOptions {
  redirectUrl: string | null;
  isPostLogin: boolean;
  history: History;
  studentRegistrationPageNo: number;
  setSession: () => void;
  clearRedirect: () => void;
  termsAccepted?: boolean;
  hasICAgreementSkipped?: boolean;
  setLoader: () => void;
  isActive?: string;
  setIsRoutingDone: (isRoutingDone: boolean) => void;
}

export const routeUser = ({
  redirectUrl,
  isPostLogin,
  history,
  studentRegistrationPageNo,
  setSession,
  clearRedirect,
  setLoader,
  setIsRoutingDone,
}: RouteUserOptions) => {
  if (!AuthUtility.isAuthorisedUser()) {
    return;
  }

  const url = history.location.pathname.split("/");
  const urlPath = url[1];

  // Skip all logic for email unsubscribe page.
  if (urlPath === "unsubscribe") {
    return;
  }

  const userTypeId = parseInt(localStorage.getItem("userTypeId") || "-1");
  const isHospital = userTypeId === UserTypes.HOSPITAL_USER_TYPE;
  const isVet = userTypeId === UserTypes.VET_USER_TYPE;
  const isTech = userTypeId === UserTypes.TECH_USER_TYPE;
  const isRooUniStudent = userTypeId === UserTypes.STUDENT_USER_TYPE;
  const isProfileComplete = localStorage.getItem("isRegistrationComplete") === "1";
  const isGroupAdmin = localStorage.getItem("isGroupAdmin");
  const isEnterpriseAdmin = localStorage.getItem("isEnterpriseAdmin");
  const isPending = Number(localStorage.getItem("isActive")) === userStatusTypes.PENDING;
  const isRooAdmin =
    userTypeId === UserTypes.ADMIN_USER_TYPE || userTypeId === UserTypes.ROO_SUPER_USER_TYPE;

  setIsRoutingDone(true);

  if (!isPostLogin) {
    setLoader();
    setSession();
  }

  if (BYPASS_HOMEPAGE_REROUTE_LOGIC_SET.has(urlPath)) {
    history.push("/" + url[1] + history.location.search);
    return;
  }

  const urlPathNoQueryString = redirectUrl ? redirectUrl.split("?")[0] : undefined;

  if (
    redirectUrl &&
    ((isHospital && urlPathNoQueryString?.startsWith("/hospital") && !isPending) ||
      (isVet && urlPathNoQueryString?.startsWith("/vet")) ||
      (isTech && urlPathNoQueryString?.startsWith("/tech")) ||
      (isRooAdmin && urlPathNoQueryString?.startsWith("/admin")) ||
      (isRooUniStudent && urlPathNoQueryString?.startsWith("/student")))
  ) {
    clearRedirect(); // Clear redirectUrl from redux Store.
    localStorage.removeItem("redirectUrl"); // Also clear redirectUrl from localStorage (used to pass along from login).
    history.push(redirectUrl);
  } else if (isHospital) {
    routeHospitalUser(history, isProfileComplete, isEnterpriseAdmin, isGroupAdmin);
  } else if (isVet) {
    routeVetUser(history, isProfileComplete);
  } else if (isTech) {
    routeTechUser(history, isProfileComplete);
  } else if (isRooUniStudent) {
    routeStudentUser(history, isProfileComplete, studentRegistrationPageNo);
  } else if (isRooAdmin && history.location.pathname !== "/superuser") {
    routeAdminUser(history);
  }
};

function routeHospitalUser(
  history: History,
  isProfileComplete: boolean,
  isEnterpriseAdmin: string | null,
  isGroupAdmin: string | null
) {
  const hospitalId = localStorage.getItem("hospitalId");

  if ((isEnterpriseAdmin || isGroupAdmin) && !hospitalId) {
    history.replace(
      history.location.pathname.startsWith("/enterprise")
        ? history.location.pathname
        : "/enterprise"
    );
  } else if (isProfileComplete) {
    history.replace(
      history.location.pathname.startsWith("/hospital")
        ? history.location.pathname + history.location.search
        : "/hospital" + history.location.search
    );
  } else {
    history.push("/register/hospitalContinue");
  }
}

function routeVetUser(history: History, isProfileComplete: boolean) {
  if (isProfileComplete) {
    history.replace(
      history.location.pathname.startsWith("/vet")
        ? history.location.pathname + history.location.search
        : "/vet" + history.location.search
    );
  } else {
    history.push("/register/continue");
  }
}

function routeTechUser(history: History, isProfileComplete: boolean) {
  if (isProfileComplete) {
    history.replace(
      history.location.pathname.startsWith("/tech")
        ? history.location.pathname + history.location.search
        : "/tech" + history.location.search
    );
  } else {
    history.push("/register/continue");
  }
}

function routeStudentUser(
  history: History,
  isProfileComplete: boolean,
  studentRegistrationPageNo: number
) {
  if (isProfileComplete) {
    history.replace("/student/externships");
  } else {
    history.push(
      STUDENT_REGISTRATION_PAGES[studentRegistrationPageNo] || STUDENT_REGISTRATION_PAGES[0]
    );
  }
}

function routeAdminUser(history: History) {
  history.replace(
    history.location.pathname.startsWith("/admin") ? history.location.pathname : "/admin"
  );
}
