import React from "react";

import { TabButton as TabButtonComponent, TabButtonMobileHeader } from "./TabButtonStyles";

export interface TabButtonProps {
  children?: React.ReactNode;
  isSelected?: boolean;
  onClick?: (e: any) => void;
  index?: number;
  isMobileMenuOpen?: boolean;
  isMobileHeaderTab?: boolean;
}

export const TabButton: React.FC<TabButtonProps> = (props) => {
  const { index, isMobileHeaderTab, isMobileMenuOpen } = props;

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const keyboardArrowDown = require("./assets/keyboardArrowDown.svg").default;
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const keyboardArrowUp = require("./assets/keyboardArrowUp.svg").default;

  const imgSrc = isMobileMenuOpen ? keyboardArrowUp : keyboardArrowDown;
  const imgAlt = isMobileMenuOpen ? "keyboardArrowUp" : "keyboardArrowDown";
  return (
    <>
      {isMobileHeaderTab ? (
        <TabButtonMobileHeader {...props} value={index}>
          {props.children}
          <img src={imgSrc} alt={imgAlt} />
        </TabButtonMobileHeader>
      ) : (
        <TabButtonComponent {...props} value={index}>
          {props.children}
        </TabButtonComponent>
      )}
    </>
  );
};
