import React from "react";
import { IconType } from "react-icons";

import { grayBase } from "../../styles/constants";

import * as iconImport from "./constants";

const icons = iconImport as { [key: string]: IconType };

export interface IconProps {
  name:
    | "Close"
    | "Add"
    | "ExpandMore"
    | "ExpandLess"
    | "OpenInNew"
    | "Pets"
    | "OutlineCloudUpload"
    | "OutlineDelete"
    | "Description"
    | "InfoOutline"
    | "Error"
    | "OutlineCalendarMonth"
    | "OutlineAccessTime"
    | "KeyboardArrowLeft"
    | "KeyboardArrowRight"
    | "KeyboardArrowUp"
    | "KeyboardArrowDown"
    | "Search"
    | "ErrorOutline"
    | "Cancel"
    | "OutlineInfo"
    | "CheckCircleOutline"
    | "CheckCircle"
    | "MoreHoriz"
    | "AttachFile"
    | "OutlineStopCircle"
    | "MoreVert"
    | "ChatBubble"
    | "ChatBubbleOutline"
    | "Check"
    | "Link"
    | "ArrowBack"
    | "Visibility"
    | "VisibilityOff"
    | "School"
    | "LocalHospital"
    | "OutlineVaccines"
    | "Phone"
    | "AttachMoney"
    | "Medication"
    | "Edit"
    | "HelpOutline"
    | "Business"
    | "LightbulbOutline"
    | "Send"
    | "Place"
    | "Person"
    | "Report"
    | "OutlineSavings"
    | "Emergency"
    | "360Round"
    | "SoundDetectionDogBarkingRound"
    | "RavenOutlined"
    | "EmergencyOutlined"
    | "SpaOutlined"
    | "SurgicalOutlined"
    | "LocalPharmacyOutlined"
    | "StethoscopeOutlined"
    | "VaccinesOutlined"
    | "ContentCopyOutlined"
    | "OutlineAccountCircle"
    | "History"
    | "CloudUpload"
    | "Download"
    | "Home"
    | "LocationOn"
    | "CorporateFare"
    | "Email"
    | "Star"
    | "Login"
    | "Event"
    | "OutlineManageAccounts"
    | "Upload"
    | "Group"
    | "MedicalServices"
    | "Computer"
    | "Checkroom"
    | "OutlineCorporateFare"
    | "OutlineEventRepeat"
    | "ContentCut"
    | "Dentistry"
    | "Tibia"
    | "Brush"
    | "IdCardOutlined"
    | "AssignmentTurnedIn"
    | "LiveHelp"
    | "DirectionsCar"
    | "Assignment"
    | "FlashOn"
    | "SurgicalSharp"
    | "Warning";
  size?: "s" | "l" | "xl" | number;
  color?: string;
  onClick?: (e?: any) => void;
  testId?: string;
}

const ICON_SIZES: { [key: string]: number } = {
  s: 16,
  l: 24,
  xl: 32,
};

export const Icon: React.FC<IconProps> = ({
  name,
  size = "l",
  color = grayBase,
  onClick,
  testId,
}) => {
  const iconSize = typeof size === "number" ? size : ICON_SIZES[size];

  // Otherwise, use react-icons
  const FoundIcon: IconType & { isCustomSvg?: boolean } = icons[`Md${name}`] || icons[`Mds${name}`];
  if (FoundIcon) {
    if (FoundIcon.isCustomSvg) {
      return (
        <span
          onClick={onClick}
          style={{
            cursor: onClick ? "pointer" : "inherit",
            verticalAlign: "text-top",
            display: "inline-block",
            width: iconSize,
            height: iconSize,
          }}
          data-testid={testId}
        >
          <FoundIcon fill={color} width={iconSize} height={iconSize} />
        </span>
      );
    }
    return (
      <FoundIcon
        size={iconSize}
        color={color}
        onClick={onClick}
        style={{ cursor: onClick ? "pointer" : "inherit", verticalAlign: "text-top" }}
        data-testid={testId}
      />
    );
  }

  return <pre>Error: Icon not loaded</pre>;
};
