import style from "styled-components";

import { FormLabelText, blueDark600, disabledTextColor, redBase } from "../../styles/constants";

const getFormLabelColor = (error: boolean, disabled?: boolean) => {
  let color = blueDark600;
  if (error) color = redBase;
  else if (disabled) color = disabledTextColor;
  return color;
};
export const FormLabel = style.label<{ error: boolean; disabled?: boolean }>`
  ${FormLabelText};
  color: ${({ error, disabled }) => getFormLabelColor(error, disabled)};
  display: block;
`;
