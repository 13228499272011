import React from "react";
import { createPortal } from "react-dom";

import { greenBase } from "../../styles/constants";
import { ButtonProps } from "../Button";
import { Icon } from "../Icon";
import { Link } from "../Link";
import { NestedTabs } from "../NestedTabs";

import {
  ActionButton,
  ActionContainer,
  BreadCrumbContainer,
  CloseContainer,
  Overlay,
  Panel as PanelComponent,
  PanelContainer,
} from "./PanelStyles";

export interface PanelProps {
  isShowPanel?: boolean;
  tabNameArr?: any;
  defaultSelectedTabIndex: number;
  onClickTab?: (e: any) => void;
  onClosePanel?: () => void;
  id?: string;
  panelStyle?: "info" | "action";
  actionButtonProps?: ButtonProps;
  actionComponent?: React.ReactNode;
  breadcrumbText?: string;
  onBreadcrumbClick?: () => void;
}
export const Panel: React.FC<PanelProps> = (props) => {
  const {
    onClosePanel,
    panelStyle,
    actionButtonProps,
    tabNameArr,
    defaultSelectedTabIndex,
    onClickTab,
    actionComponent,
    breadcrumbText,
    onBreadcrumbClick,
  } = props;
  return (
    <>
      {createPortal(
        <PanelContainer {...props}>
          <Overlay onClick={() => (onClosePanel ? onClosePanel() : null)} />
          <PanelComponent {...props} data-testid="panelContainer">
            <CloseContainer hasBreadcrumb={!!breadcrumbText}>
              {breadcrumbText && (
                <BreadCrumbContainer>
                  <Icon
                    name="ArrowBack"
                    color={greenBase}
                    size="s"
                    onClick={() => (onBreadcrumbClick ? onBreadcrumbClick() : null)}
                  />
                  <Link
                    href="javascript:void(0)"
                    onClick={() => (onBreadcrumbClick ? onBreadcrumbClick() : null)}
                  >
                    {breadcrumbText}
                  </Link>
                </BreadCrumbContainer>
              )}
              <Icon
                name="Close"
                onClick={() => (onClosePanel ? onClosePanel() : null)}
                testId="closePanel"
              />
            </CloseContainer>
            <NestedTabs
              tabNameArr={tabNameArr}
              defaultSelectedTabIndex={defaultSelectedTabIndex}
              onClickTab={onClickTab}
              scrollbarSpace
            />
            {panelStyle === "action" && (actionComponent || actionButtonProps) ? (
              <ActionContainer id="action-container">
                {actionComponent ? actionComponent : null}
                {actionButtonProps && <ActionButton {...actionButtonProps} buttonType="primary" />}
              </ActionContainer>
            ) : null}
          </PanelComponent>
        </PanelContainer>,
        document.body
      )}
    </>
  );
};
