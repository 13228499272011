import React, { ReactNode } from "react";

import { ChipStyle } from "./ChipStyles";

export interface ChipProps {
  text?: string;
  borderColor?: string;
  textColor?: string;
  backgroundColor?: string;
  children?: ReactNode;
}

export const Chip: React.FC<ChipProps> = (props) => {
  const { text, borderColor, backgroundColor, children, textColor } = props;

  return (
    <ChipStyle borderColor={borderColor} backgroundColor={backgroundColor} textColor={textColor}>
      {text && <span>{text}</span>}
      {children}
    </ChipStyle>
  );
};
